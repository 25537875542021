/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { EndTest, EndTestInstitutionStudent } from '../Slices/TestPageSlice';
import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when) {
  const { testData } = useSelector((state) => state.TestMaster)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // in if condition we are checking next location and current location are equals or not
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation],
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
    let obj = {
      id: null,
      answerId: null,
      testDataId: testData?.testMasterStudentMapId
    }
    if (localStorage.getItem('institutionStudent')) {
      dispatch(EndTestInstitutionStudent(obj)).unwrap().then((res) => {
        if (res.status) {
          navigate(`/institutionHome/insStudTest`)
        }
      })
    } else {
      dispatch(EndTest(obj)).unwrap().then((res) => {
        if (res.status) {
          navigate(`/home`)
        }
      })
    }
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
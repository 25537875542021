import { configureStore } from "@reduxjs/toolkit";
import TestPageSlice from "../Slices/TestPageSlice";
import signUpSlice from "../Slices/signUpSlice";
import HomePageSlice from "../Slices/HomePageSlice";
import TestSeriesSlice from "../Slices/TestSeriesSlice";
import TestList from "../Slices/TestListSlice";
import PurchasedListSlice from "../Slices/PurchasedListSlice";
import AttendedTest from "../Slices/attendedSlice";
import ProfileSlice from "../Slices/ProfileSlice"
 const store = configureStore({
 reducer:{
  TestMaster:TestPageSlice,
  signUpSlice:signUpSlice,
  HomePage:HomePageSlice,
  TestSeries:TestSeriesSlice,
  TestList:TestList,
  PurchasedList:PurchasedListSlice,
  AttendedTest:AttendedTest,
  Profile:ProfileSlice
 }
})

export default store
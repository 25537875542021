import { useState } from 'react';
import { Formik, useFormik } from 'formik';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Drawer,Image,Input,Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons'
import centumLogo from '../assets/images/Centum_App_logo.svg';
import { useNavigate } from 'react-router-dom';
import '../styles/signUp.scss';
import '../styles/globalMediaQuery.scss';
import { signUp ,registerClose,otpOpen, login, loginOpen} from '../Slices/signUpSlice';
import * as yup from 'yup';
import Loading from '../Component/Loading';


function SignUp(){
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const {registerVisible}=useSelector((state)=>state.signUpSlice);
  const antIcon = (<LoadingOutlined style={{fontSize: 24,}}spin/>);
  const {isLoading}=useSelector((state)=>state.signUpSlice)

  // const [visible,setVisible]=useState();
  const [disabledButton,setDisabled]=useState(false);
  const [signupDetails,setSignupDetails]=useState();

  function onSubmit(values){
    values["mobileNumber"] = +values["mobileNumber"]
    dispatch(signUp(values)).unwrap().then((res)=>{
      if(res.success==true){
        localStorage.setItem('mobileNumber',res.insertedData.mobileNumber)
        navigate('/enterOtp')
      }
    })
    formik.resetForm()
  }

  const loginPage = ()=>{
    navigate('/login')
  }


  const initialValues= { name: '',mobileNumber:'',countryCallingCode:91,email: '',}

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema:yup.object({
      name: yup.string().required("Name is required")
      .min(2, "Please enter at least 2 characters")
      .max(100, "The input exceeds the maximum allowed length of 100 characters"),
      // name: yup.string().required("Name is required").matches(/^[a-zA-Z\s]*$/,'Name must be an alphabet characters'),
      // mobileNumber: yup.string().required("MobileNumber is Required").min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')      ,
      mobileNumber: yup.string().required("MobileNumber is Required").matches(/^\d{10}$/,'Mobile number must be 10 digits'),
      email: yup.string().email('Invalid Email Id').required("Email is Required"),
    })
  })


  return(
    <>
      <div>
          <div className='signup-container user-onboarding-container'>
            <div className='signupImage user-onboarding-image'></div>
            <div className='signup-wrapper user-onboarding-wrapper'>
              <div className='signup-content'>
                <div><Image preview={false} height={70} src={centumLogo}></Image></div>

                <div>
                  <h2 className='header'>Get started with CentumApp!</h2>
                  <p className='sub-header'>Continue with your Mobile Number</p>
                </div>

              </div>
              <div className='signup-form'>
                <div className='form-wrapper'>
                <div className='form-control' >
                  <label>Name</label>
                  <Input id='name' pattern="[A-Za-z]*" name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
                  {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
                </div>
                <div className='form-control' >
                  <label>Mobile number</label>
                  <Input type='number' id='mobileNumber' name='mobileNumber' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNumber}></Input>
                 {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='error'>{formik.errors.mobileNumber}</div> : null}
                </div>
                <div className='form-control institution-form-control' >
                      <label>Email</label>
                      <Input id='email' placeholder='Enter a Student Email' name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}></Input>
                      {formik.touched.email && formik.errors.email ? <div className='error'>{formik.errors.email}</div> : null}
                    </div>
                <div className='form-control'>
                  <Button className='signup-button' type='primary' disabled={!formik.dirty || !formik.isValid}  onClick={()=>onSubmit(formik.values)}>SUBMIT</Button>

                </div>
                <div>
                  <p className='signIn-option'>Already Have an Account? <a  onClick={()=> loginPage()}>Login</a></p>
                </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      {isLoading ? <div className='loading-container'>

<Loading></Loading>

</div> : null
}
      </>
  )
}

export default SignUp;
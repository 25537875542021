import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";


// Sign up

export const signUp = createAsyncThunk(
  'SignUp/signUp', async (payload) => {
    let insertedData = [];
    let success = '';
    let InsertMessage = '';
    let insertData = await axios.post('users/student/create', payload)
      .then((res) => {
        insertedData = res.data.data;
        InsertMessage = res.data.message;
        success = res.data.success
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    return {
      insertedData: insertedData,
      success: success,
      InsertMessage: InsertMessage
    };
  }
)

// otp verification

export const otpVerify = createAsyncThunk(
  'SignUp/otpverify', async (payload) => {
    let success = '';
    let otpMessage = '';
    let uuidToken = '';
    let isVerified = ''
    let otpData = await axios.put('users/student/otpVerification/' + payload)
      .then((res) => {
        otpMessage = res.data.message;
        success = res.data.success;
        uuidToken = res.data.data.uuidToken;
        isVerified = res.data.data.isVerified;
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    return {
      success: success,
      otpMessage: otpMessage,
      uuidToken: uuidToken,
      isVerified: isVerified
    };
  }
)

// set password

export const setPassword = createAsyncThunk(
  'SignUp/setPassword', async (payload) => {
    let success = '';
    let passwordMessage = '';
    let data = {
      password: payload.password,
      confirmPassword: payload.confirmPassword
    }

    let passwordData = await axios.put('users/student/setPassword/' + payload.uuidToken, data)
      .then((res) => {
        passwordMessage = res.data.message;
        success = res.data.success;
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    return {
      success: success,
      passwordMessage: passwordMessage,
    };
  }
)

// Login

export const login = createAsyncThunk(
  'SignUp/login', async (payload) => {
    try {
      let data;
      await axios.post('users/student/login', payload)
        .then((res) => {
          data = res.data;
          localStorage.setItem('userId', data.userid)
          localStorage.setItem('roleId', data.roleId)
          localStorage.setItem('name', data.name)
          localStorage.setItem('token', data.token)
          localStorage.setItem('institutionStudent', false)
          return data;
        })
      return data;
    } catch (error) {
      message.error(error.response.data.message)
      return error
    }
  })

// reset password

export const resetOtp = createAsyncThunk(
  'SignUp/resetOtp', async (payload) => {
    let data = [];
    let loginData = await axios.post('users/student/resetOtp', payload)
      .then((res) => {
        data = res.data.resetPassword;
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    return data;
  })

/* institution student forgot password */
export const forgotPassInstitution = createAsyncThunk(
  'SignUp/forgotPassInstitution', async (payload, thunk) => {
    try {
      const forgotPassInsForgot = await axios.post(`users/institution/student/forgotPasswordInstitution`, payload).then((res) => {
        if (res.data?.status) {
          return res.data
        }
      })
      return forgotPassInsForgot
    } catch (error) {
      return error
    }
  })
// institution student emial verification
export const institutionTokenVerification = createAsyncThunk(
  'SignUp/institutionTokenVerification', async (payload, thunk) => {
    try {
      const insStudTokenVerify = await axios.put(`users/institution/student/verifyToken/${payload.token}`).then((res) => {
        if (res.data?.success) {
          return res.data
        }
      })
      return insStudTokenVerify
    } catch (error) {
      return error
    }
  })

/* set institution student password */
export const setPasswordInsStud = createAsyncThunk(
  'SignUp/setPasswordInsStud', async (payload) => {
    let success = '';
    let passwordMessage = '';
    let data = {
      password: payload.password,
      confirmPassword: payload.confirmPassword
    }

    await axios.post(`/users/institution/student/createPassword/${payload.uuidToken}`, data)
      .then((res) => {
        passwordMessage = res.data.message;
        success = res.data.success;
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    return {
      success: success,
      passwordMessage: passwordMessage,
    };
  }
)

// institution student login

export const institutionStudentLogin = createAsyncThunk(
  'SignUp/institutionStudentLogin', async (payload) => {
    try {
      let data
      await axios.post('users/institution/student/login', payload)
        .then((res) => {
          localStorage.setItem('userId', res.data.userId)
          localStorage.setItem('name', res.data.name)
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('batchCode', res.data.batchCode)
          localStorage.setItem('institutionStudent', res.data.institutionStudent)
          data = res.data
          return res.data
        })
      return data
    } catch (error) {
      message.error(error.response.data.message)
      return error
    }
  })


const initialState = {
  isLoading: false,
  loginVisible: false,
  passwordVisible: false,
  otpVisible: false,
  mobileNumberVisible: false,
  registerVisible: false,
  forgetPassword: false,
  mobileNumber: '',

}
const SignUp = createSlice({
  name: 'SignUp',
  initialState,
  reducers: {
    loginOpen: (state) => {
      state.loginVisible = true
      // state.passwordVisible=false,
      // state.otpVisible=false,
      // state.mobileNumberVisible=false,
      // state.registerVisible=false
    },
    registerOpen: (state) => {
      state.registerVisible = true
      // state.loginVisible=true
      // state.passwordVisible=false,
      // state.otpVisible=false,
      // state.mobileNumberVisible=false,

    },
    registerClose: (state, payload) => {
      state.registerVisible = false
      let number = String(payload.payload).slice(-4);
      state.mobileNumber = Number(number)
    },
    passwordOpen: (state) => {
      state.passwordVisible = true
    },
    otpOpen: (state) => {
      state.otpVisible = true
    },
    mobieNumberOpen: (state) => {
      state.mobileNumberVisible = true
    },
    passwordClose: (state) => {
      state.passwordVisible = false
    },
    otpclose: (state) => {
      state.otpVisible = false
    },
    mobieNumberClose: (state) => {
      state.mobileNumberVisible = false
    },
    forgetPasswordOpen: (state) => {
      state.forgetPassword = true

    },
    forgetPasswordClose: (state) => {
      state.forgetPassword = false

    }
  },
  extraReducers: {
    [signUp.pending]: (state) => {
      state.isLoading = true
    },
    [signUp.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload.success == true) {
        state.mobileNumber = String(actions.payload.insertedData.mobileNumber.slice(-4));
        message.success(actions.payload.InsertMessage)
      }
    },
    [signUp.rejected]: (state) => {
      state.isLoading = false
    },
    [otpVerify.pending]: (state) => {
      state.isLoading = true
    },
    [otpVerify.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload.success == true) {
        message.success(actions.payload.otpMessage)
      }
    },
    [otpVerify.rejected]: (state) => {
      state.isLoading = false
    },
    [setPassword.pending]: (state) => {
      state.isLoading = true
    },
    [setPassword.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload.success == true) {
        message.success(actions.payload.passwordMessage)
      }
    },
    [setPassword.rejected]: (state) => {
      state.isLoading = false
    },
    [login.pending]: (state) => {
      state.isLoading = true
    },
    [login.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload?.status == true) {
        message.success(actions.payload?.message)
      }
    },
    [login.rejected]: (state) => {
      state.isLoading = false
    },
    [resetOtp.pending]: (state) => {
      state.isLoading = true
    },
    [resetOtp.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload.success == true) {
        state.mobileNumber = String(actions.payload.data.mobileNumber).slice(-4);
        message.success(actions.payload.message)
      }
    },
    [resetOtp.rejected]: (state) => {
      state.isLoading = false
    },
    /* institution student emial token verification */
    [institutionTokenVerification.pending]: (state) => {
      state.isLoading = true
    },
    [institutionTokenVerification.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload.success == true) {
        message.success(actions.payload.message)
      }
    },
    [institutionTokenVerification.rejected]: (state) => {
      state.isLoading = false
    },
    /* institution student forgot password */
    [forgotPassInstitution.pending]: (state) => {
      state.isLoading = true
    },
    [forgotPassInstitution.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload.status == true) {
        message.success(actions.payload.message)
      }
    },
    [forgotPassInstitution.rejected]: (state) => {
      state.isLoading = false
    },
    /* set instittuion student password */
    [setPasswordInsStud.pending]: (state) => {
      state.isLoading = true
    },
    [setPasswordInsStud.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload.success == true) {
        message.success(actions.payload.passwordMessage)
      }
    },
    [setPasswordInsStud.rejected]: (state) => {
      state.isLoading = false
    },
    /* set instittuion student login */
    [institutionStudentLogin.pending]: (state) => {
      state.isLoading = true
    },
    [institutionStudentLogin.fulfilled]: (state, actions) => {
      state.isLoading = false
      if (actions.payload?.status == true) {
        message.success(actions.payload.message)
      }
    },
    [institutionStudentLogin.rejected]: (state) => {
      state.isLoading = false
    }
  }
})

export const { loginOpen, passwordOpen, otpOpen, mobieNumberOpen, passwordClose, otpclose, mobieNumberClose, registerOpen, registerClose, forgetPasswordOpen, forgetPasswordClose } = SignUp.actions
export default SignUp.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { addBreadCrumbData } from "./HomePageSlice";

//Get Test Series by id
export const GetAllTestById = createAsyncThunk('Home/GetAllTestById', async (payload, thunk) => {
  try {
    const getData = await axios.get(`/test/testBundleGetAllTest/${payload}`).then((res) => {
      if (res.data?.success) {
        return res.data?.data

      }
    })
    return getData
  } catch (error) {
  }
})
//Get Test Series by id  test/testBundleGetAllTest/82
export const GetTestSeriesById = createAsyncThunk('Home/GetTestSeriesById', async (payload, thunk) => {
  try {
    const getData = await axios.get(`test/getTestBundle/${payload}`).then((res) => {
      if (res.data?.success) {
        return res.data?.data

      }
    })
    return getData
  } catch (error) {
  }
})

//Get Test Series by id  test/testBundleGetAllTest/82
export const GetTestById = createAsyncThunk('Home/GetTestById', async (payload, thunk) => {
  try {
    const getData = await axios.get(`test/testGet/${payload}`).then(async (res) => {
      if (res.data?.success) {
        res.data.breadCrumbData['isExam'] = true;
        thunk.dispatch(addBreadCrumbData(res.data.breadCrumbData))
        return res.data?.data


      }
    })
    return getData
  } catch (error) {
  }
})

//Get All Difficultily level
export const GetAllDifficultyLevel = createAsyncThunk('Home/GetAllDifficultyLevel', async (payload, thunk) => {
  try {
    const getData = await axios.get(`/test/getDifficultyLevel`).then((res) => {
      if (res.data?.success) {
        return res.data?.data

      }
    })
    return getData
  } catch (error) {
  }
})

export const GetTestByIdInsStud = createAsyncThunk('Home/GetTestByIdInsStud', async (payload, thunk) => {
  try {
    const getData = await axios.get(`test/testGetInsStud/${payload.testId}/assignId/${payload.assignId}`).then(async (res) => {
      if (res.data?.success) {
        return res.data?.data
      }
    })
    return getData
  } catch (error) {
    return error
  }
})

//Get Test Series by id  test/testBundleGetAllTest/82
export const GetPreviousYearTestById = createAsyncThunk('Home/GetPreviousYearTestById', async (payload, thunk) => {
  try {
    const getData = await axios.get(`test/previousYearTestById/${payload}`).then(async (res) => {
      if (res.data?.success) {
        return res.data?.data
      }
    })
    return getData
  } catch (error) {
  }
})

const TestSeriesPage = createSlice({
  name: "HomePage",
  initialState: {
    isLoading: false,
    getTestSeriesById: {},
    getAllTestById: [],
    getTestById: {},
    previousYearTest: {},
    getTestByIdInsStudent: {},
    difficultyLevel: [],
  },
  reducers: {},
  extraReducers: {

    [GetAllTestById.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetAllTestById.fulfilled]: (state, action) => {
      state.isLoading = false

      state.getAllTestById = action.payload

    },
    [GetAllTestById.rejected]: (state, action) => {
      state.isLoading = false

    },

    [GetTestSeriesById.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetTestSeriesById.fulfilled]: (state, action) => {
      state.isLoading = false

      state.getTestSeriesById = action.payload[0]

    },
    [GetTestSeriesById.rejected]: (state, action) => {
      state.isLoading = false


    },
    [GetTestById.pending]: (state, action) => {
      state.isLoading = true


    },
    [GetTestById.fulfilled]: (state, action) => {
      state.isLoading = false

      state.getTestById = action.payload

    },
    [GetTestById.rejected]: (state, action) => {
      state.isLoading = false


    },
    [GetAllDifficultyLevel.pending]: (state, action) => {
      state.isLoading = true


    },
    [GetAllDifficultyLevel.fulfilled]: (state, action) => {
      state.isLoading = false

      state.difficultyLevel = action.payload

    },
    [GetAllDifficultyLevel.rejected]: (state, action) => {
      state.isLoading = false


    },

    /* GetTestByIdInsStud */
    [GetTestByIdInsStud.pending]: (state, action) => {
      state.isLoading = true
    },
    [GetTestByIdInsStud.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getTestByIdInsStudent = action.payload
    },
    [GetTestByIdInsStud.rejected]: (state, action) => {
      state.isLoading = false
    },
    [GetPreviousYearTestById.pending]: (state, action) => {
      state.isLoading = true
    },
    [GetPreviousYearTestById.fulfilled]: (state, action) => {
      state.isLoading = false
      state.previousYearTest = action.payload
    },
    [GetPreviousYearTestById.rejected]: (state, action) => {
      state.isLoading = false
    },


  }
})
export default TestSeriesPage.reducer
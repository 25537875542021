import { Divider } from 'antd'
import React from 'react'

const AvailableTestComponent = ({ data, testPressed }) => {
    return (
        <div className='test-list' onClick={() => testPressed(data)}>
            <div className='test-details'>
                <p>
                    {
                        data?.typeOfTestId == 1 ?
                            <span className='fullTest'>Full Syllabus</span>
                            : data?.typeOfTestId == 2 ?
                                <span className='subTest'>Subject Test</span>
                                : data?.typeOfTestId == 3 ? <span className='chapTest'>Chapter Test</span>
                                    : <span className='chapTest'>Question Bank</span>
                    }
                    <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider>
                    <span className='examName'>{data?.examName}</span> </p>
                <p className='testName'>{data.testName}</p>
                <div className='question-details'>
                    <p className='popular-test-component-marks-solutions'>{data.numberOfQuestions} Questions</p>
                    <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                    <p className='popular-test-component-marks-solutions'>{data.numberOfQuestions * data.eachQuestionMark} Marks</p>
                    <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                    <p className='popular-test-component-date-solutions'>{data.timeLimit} Mins</p>
                </div>
            </div>
            <div className='test-availabel-details'>
                <p className='countName'>{data.availableTestCount}</p>
                <p className='availableName'> {data.availableTestCount > 1 ? "Tests Available" : " Test Available"} </p>
            </div>
        </div>
    )
}

export default AvailableTestComponent
import { Breadcrumb, Image } from 'antd';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import arrowIcon from '../assets/images/Expandright.svg'


const BreadCrumbDetails = () => {
  const navigate = useNavigate()
 const location = useLocation()
 const { pathname } = location;
 const pathnames = pathname.split('/').filter((item) => item)
 const { isLoading, breadCrumbData } = useSelector((state) => state.HomePage)

 return (
  <>
   {/* <Breadcrumb separator={<Image src={arrowIcon} preview={false}></Image>}>
    {
     pathnames.length ?
     pathnames.map((data, index) => {

      const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
      const isLast = index === pathnames.length - 1;
      return isLast ? (<Breadcrumb.Item> <span className='breadCrumb-data'>{data}</span> </Breadcrumb.Item>)
       : (<Breadcrumb.Item><Link to={routeTo}> <span className='breadCrumb-data'> {data} </span></Link></Breadcrumb.Item>)
     })
     :null
    }
   </Breadcrumb> */}


<Breadcrumb separator={<Image src={arrowIcon} preview={false}></Image>}>

<Breadcrumb.Item  onClick={()=>navigate('/home' )} > <span className='content' >{breadCrumbData.home}</span> </Breadcrumb.Item>
{
  breadCrumbData.isExam ?
    <Breadcrumb.Item  onClick={()=>navigate('/home/exams' )} > <span className='content' >Exams</span> </Breadcrumb.Item>
  :
  null
}

{ breadCrumbData?.examGroupId ?
<Breadcrumb.Item onClick={()=>navigate('/home/exams/'+breadCrumbData?.slugId )}   > <span className='content' >  {breadCrumbData.examGroupName} </span></Breadcrumb.Item>
:null
}
{ breadCrumbData?.examId ?
<Breadcrumb.Item onClick={()=>navigate('/home/testList/'+breadCrumbData?.examId )} ><span className='content' > {breadCrumbData.examName} </span></Breadcrumb.Item>
:null
}
{ breadCrumbData?.testId ?
<Breadcrumb.Item > <span className='content' > {breadCrumbData.testName}</span> </Breadcrumb.Item>
:null
}
{ breadCrumbData?.isAttempted ?
<Breadcrumb.Item  onClick={()=>navigate('/home/attemptedTest')} > <span className='content' >Attempted Tests</span> </Breadcrumb.Item>
:null
}
{ breadCrumbData?.isAvailabe ?
<Breadcrumb.Item > <span className='content' >Available Tests</span> </Breadcrumb.Item>
:null
}
{ breadCrumbData?.isSolution ?
<Breadcrumb.Item > <span className='content' >Result</span> </Breadcrumb.Item>
:null
}
{ breadCrumbData?.isPopular ?
<Breadcrumb.Item  onClick={()=>navigate('/home/popularTest')}> <span className='content' >Popular Tests</span> </Breadcrumb.Item>
:null
}
{ breadCrumbData?.isOrder ?
<Breadcrumb.Item onClick={()=>navigate('/home/orderPage')} > <span className='content' >Ordered List</span> </Breadcrumb.Item>
:null
}
{ breadCrumbData?.isOrderHistory ?
<Breadcrumb.Item > <span className='content' >Item</span> </Breadcrumb.Item>
:null
}
{ breadCrumbData?.isPreviousYearExam ?
<Breadcrumb.Item > <span className='content' >Previous Year Exam</span> </Breadcrumb.Item>
:null
}




</Breadcrumb>

  </>
 )
}

export default BreadCrumbDetails
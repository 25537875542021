import { Breadcrumb, Button, Divider, Drawer, Image, message, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import subtract from '../assets/images/subtract.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { takeTestPreviousYearExamInstitution } from '../Slices/TestPageSlice'
import minsImage from '../assets/images/time.svg';
import { GetPreviousYearTestById } from '../Slices/TestSeriesSlice'
import { LoadingOutlined } from '@ant-design/icons'
import Loading from '../Component/Loading'
import marksImage from '../assets/images/marks.svg'
import { startTakeTest } from '../Slices/TestListSlice'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
  />
);


const PreviousYearTestInstructionIns = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [testLevel, setTestLevel] = useState(0)

  useEffect(() => {
    dispatch(GetPreviousYearTestById(params?.id))
  }, [])

  const { isLoading, previousYearTest } = useSelector((state) => state.TestSeries)
  const { isLoadingTestPage } = useSelector((state) => state.TestMaster)

  const startTest = () => {
    dispatch(startTakeTest())
    let currentTestId = params?.id
    let obj = {
      currentTestId: params?.id,
      assignId: params.assignId
    }
    dispatch(takeTestPreviousYearExamInstitution(obj)).unwrap().then((res) => {
      if (!res?.data?.status) {
        message.error(res?.message)
      }
      else if (res?.data?.status && res?.data?.questionData.length) {
        navigate("/testPagePreviosYearExam/" + currentTestId)
      }
      else if (!res?.data?.questionData.length) {
        message.error("There is no question in the test" + res?.data?.questionData.length)
      }
      else {
        message.error(" Something went wrong")
      }
    })
  }


  return (
    <>
      <div className='test-instruction-container'>
        <div className='test-instruction-header'>
          <div className='test-instruction-header-container'>
            <div className='instruction-details-container'>
              <div className='instruction-details'>
                <p className="exam-title">
                  {/* <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider> */}
                  <span>{previousYearTest.examName} </span></p>
                <p className='instruction-name'>{previousYearTest?.testName}</p>
              </div>
              <div className='instruction-total'>
                <span className='total'><Image preview={false} src={subtract}></Image> <span className='total-question'>{previousYearTest?.numberOfQuestions} Questions </span> <Image preview={false} src={marksImage}></Image><span className='total-marks'>{previousYearTest?.totalMark} marks </span><Image preview={false} src={minsImage}></Image><span className='total-mins'>{previousYearTest?.timeLimit} mins </span> </span>
              </div>
            </div>
            <div className='instruction-purchase'>
              <Button type="primary" className='primary-submit-button' onClick={() => startTest()}>Start</Button>
            </div>
          </div>
        </div>
        <div className='test-instruction-Details'>
          <h2 className='header-text'>Instructions</h2>
          <div className='test-instruction-content'>
            <div dangerouslySetInnerHTML={{ __html: previousYearTest?.instructions }} />
          </div>
        </div>
      </div>
      {
        isLoading || isLoadingTestPage ? <div className='loading-container'>
          <Loading></Loading>
        </div> : null
      }
    </>
  )
}

export default PreviousYearTestInstructionIns
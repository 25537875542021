import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Input } from 'antd';
import { forgetPasswordClose, resetOtp } from '../Slices/signUpSlice'
import centumLogo from '../assets/images/Centum_App_logo.svg';
import '../styles/otpPage.scss';
import '../styles/globalMediaQuery.scss';
import * as yup from 'yup';
import Loading from '../Component/Loading';

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.signUpSlice)


  function onSubmit(values) {
    values["mobileNumber"] = +values["mobileNumber"]
    dispatch(forgetPasswordClose())
    values = {
      mobileNumber: values.mobileNumber,
      countryCallingCode: 91
    }
    dispatch(resetOtp(values)).unwrap().then((res) => {
      if (res.success == true) {
        navigate('/enterOtp')
      }
    })
    formik.resetForm()
  }

  const initialValues = { mobileNumber: '' }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: yup.object({
      mobileNumber: yup.string().required("MobileNumber is Required").matches(
        /^\d{10}$/,
        'Mobile number must be 10 digits')
    })
  })

  return (
    <>
      <div>
        <div className='forgot-container user-onboarding-container'>
          <div className='forgotImage user-onboarding-image'></div>
          <div className='forgot-wrapper user-onboarding-wrapper'>
            <div className='forgot-content'>
              <div ><Image height={70} preview={false} src={centumLogo}></Image></div>
            </div>
            <div className='forgot-form'>
              <div className='form-wrapper'>
                <div className='form-control' >
                  <label>Mobile Number</label>
                  <Input type='number' id='mobileNumber' placeholder='Enter a Mobile Number' name='mobileNumber' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNumber}></Input>
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='error'>{formik.errors.mobileNumber}</div> : null}
                </div>
                <div className='form-control'>
                  <Button className='forgot-button' type='primary' disabled={!formik.dirty || !formik.isValid} onClick={() => onSubmit(formik.values)}>Send OTP</Button>
                </div>
                <div>
                  <p className='navigate'> Back To<a onClick={() => navigate("/login")}> Login </a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <div className='loading-container'>
        <Loading></Loading>
      </div> : null
      }
    </>
  )
}
export default ForgotPassword;
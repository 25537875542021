import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { addBreadCrumbData } from "./HomePageSlice";


// get exam by id

export const getExamById = createAsyncThunk(
  "TestList/getExamById", async (payload) => {
    try {
      var exam = []
      var success = ''
      let examData = await axios.get('test/getExam/' + payload)
        .then((res) => {
          exam = res.data.data
          success = res.data.success
        })
    }
    catch (error) {
    }
    return {
      exam: exam,
      success: success
    }
  }
)




export const getTypeOfTest = createAsyncThunk(
  "TestList/getTypeOfTest", async (payload) => {
    try {
      var typeofTest = [];
      var success = ''
      let typeofTestData = await axios.get('test/getTypeOfTest/' + payload)
        .then((res) => {
          typeofTest = res.data.data
          success = res.data.success
        })
    }
    catch (error) {
    }
    return {
      typeofTest: typeofTest,
      success: success
    };
  }
)


export const getSubjectExamById = createAsyncThunk(
  "TestList/getSubjectExamById", async (payload, thunk) => {
    try {
      var subjects = []
      var success = ''
      let examData = await axios.get('test/getSubjectByExamId/' + payload)
        .then((res) => {
          if (res.data.status) {
            res.data.breadCrumbData['isExam'] = true;
            thunk.dispatch(addBreadCrumbData(res.data.breadCrumbData))
            subjects = res.data.data
            success = res.data.status
          }

        })
    }
    catch (error) {
    }
    return {
      subjects: subjects,
      success: success
    }
  }
)
export const getTestById = createAsyncThunk(
  "TestList/getTestById", async (payload) => {
    try {
      var test = []
      var success = ''
      let testData = await axios.post(`test/getAllTest/exam/${payload.examId}/type/${payload.typeOfTestId}`)
        .then((res) => {
          test = res.data.data
          success = res.data.success
        })
    }
    catch (error) {
    }
    return {
      test: test,
      success: success
    };
  }
)

// get test by subject

export const getTestBySubject = createAsyncThunk(
  "TestList/getTestBySubject", async (payload) => {
    try {
      var test = []
      var success = ''
      let apiUrl;
      if (payload.subjectId == -1) {
        apiUrl = `test/getAllTest/exam/${payload.examId}/type/${payload.typeOfTestId}`
      }
      else {
        apiUrl = `test/getAllTest/exam/${payload.examId}/type/${payload.typeOfTestId}?subjectId=${payload.subjectId}`
      }
      let testData = await axios.post(apiUrl)
        .then((res) => {
          test = res.data.data
          success = res.data.success
        })
    }
    catch (error) {
    }
    return {
      test: test,
      success: success
    };
  }
)

// add to cart

export const addToCart = createAsyncThunk(
  "TestList/addToCart", async (payload, thunk) => {
    try {
      var cart = [];
      var success = '';
      var addMessage = ''
      let cartData = await axios.post('cart/cart', payload)
        .then(async (res) => {
          cart = res.data.data
          success = res.data.success
          addMessage = res.data.message
          // await thunk.dispatch(getAllCart())
        })
    }
    catch (error) {
    }
    return {
      cart: cart,
      success: success,
      addMessage: addMessage
    }
  })

// get cart items

export const getAllCart = createAsyncThunk(
  "TestList/getAllCart", async (payload) => {
    try {
      var cart = [];
      var success = ''
      let cartData = await axios.get('cart/getAllCart')
        .then((res) => {
          cart = res.data.data
          success = res.data.success
        })
    }
    catch (error) {
    }
    return {
      cart: cart,
      success: success
    };
  }
)

// remove from cart

export const removeFromCart = createAsyncThunk(
  "TestList/removeFromCart", async (payload) => {
    try {
      var success = ''
      var removeMessage = ''
      let removeCartData = await axios.delete('cart/cart/' + payload)
        .then((res) => {
          success = res.data.success
          removeMessage = res.data.message
          return {
            success: success,
            removeMessage: removeMessage,
          };
        })
      return removeCartData
    }
    catch (error) {
    }
  }
)

export const updateCart = createAsyncThunk(
  "TestList/updateCart", async (payload, thunk) => {
    try {
      let updateData = await axios.put('cart/cart/' + payload.id, payload)
        .then(async (res) => {
          await thunk.dispatch(getAllCart())
        })
    }
    catch (error) {

    }
  }
)
export const raiseADispute = createAsyncThunk(
  "test/raiseADispute", async (payload, thunk) => {
    try {
      let updateData = await axios.post('test/raiseADispute', payload)
        .then(async (res) => {
          if (res.data.status) {
            return res.data
          }
        })
      return updateData
    }
    catch (error) {
    }
  }
)

export const raiseADisputeInstitutionStud = createAsyncThunk(
  "test/raiseADisputeInstitutionStud", async (payload, thunk) => {
    try {
      let updateData = await axios.post('test/raiseADisputeInstitutionStud', payload)
        .then(async (res) => {
          if (res.data.status) {
            return res.data
          }
        })
      return updateData
    }
    catch (error) {
    }
  }
)


/* get previous year exam */
export const getPreviousYearExam = createAsyncThunk(
  "TestList/getPreviousYearExam", async (payload) => {
    try {
      var previousYearExam = [];
      var success = ''
      await axios.get('test/getPreviousYearExam')
        .then((res) => {
          previousYearExam = res.data.data
          success = res.data.success
        })
    }
    catch (error) {
    }
    return {
      previousYearExam: previousYearExam,
      success: success
    };
  }
)

/* get previous year question by exam categories */
export const getPreviousYearTestByCategory = createAsyncThunk(
  "TestList/getPreviousYearTestByCategory", async (payload) => {
    try {
      var previousYearTest = [];
      var success = ''
      await axios.get(`test/getPreviousTestByExamCategory?category=${payload.name}`)
        .then((res) => {
          previousYearTest = res.data.data
          success = res.data.success
        })
    }
    catch (error) {
    }
    return {
      previousYearTest: previousYearTest,
      success: success
    };
  }
)

export const raiseADisputePreviousYearExam = createAsyncThunk(
  "test/raiseADisputePreviousYearExam", async (payload, thunk) => {
    try {
      let updateData = await axios.post('test/raiseADisputePreviousYearExam', payload)
        .then(async (res) => {
          if (res.data.status) {
            return res.data
          }
        })
      return updateData
    }
    catch (error) {
    }
  }
)

export const clientAddToCart = createAsyncThunk(
  'cart/mobileAddToCart', async (payload, thunkAPI) => {
    try {
      const addToCartData = await axios.post(`cart/mobileCart`, payload)
      return addToCartData['data']
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getPreviousExamBatchTestRankDetails = createAsyncThunk(
  'test/getPreviousExamBatchTestRankDetails', async (payload, thunkAPI) => {
    try {
      const testDetail = await axios.get(`test/batch/test/${payload.testId}/assign/${payload.assignId}/batchRankPreviousExam`)
      return testDetail['data'] || []
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getNormalExamTestRankDetails = createAsyncThunk(
  'test/getNormalExamTestRankDetails', async (payload, thunkAPI) => {
    try {
      const testDetail = await axios.get(`test/batch/test/${payload.testId}/assign/${payload.assignId}/batchRankNormalExam`)
      return testDetail['data'] || []
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
// /test/:testId/RankNormalExam
export const getNormalStudentExamRank = createAsyncThunk(
  'test/getNormalStudentExamRank', async (payload, thunkAPI) => {
    try {
      const testDetail = await axios.get(`test/test/${payload.testId}/RankNormalExam`)
      return testDetail['data'] || []
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getInduvialStudPreviousYearExamTestRankDetails = createAsyncThunk(
  'test/getInduvialStudPreviousYearExamTestRankDetails', async (payload, thunkAPI) => {
    try {
      const testDetail = await axios.get(`test/${payload}/induvialStudTestLeaderBoard`)
      return testDetail['data'] || []
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getCategoryDetailsList = createAsyncThunk(
  'test/getCategoryDetailsList', async (payload, thunkAPI) => {
    try {
      const categoryList = await axios.get(`test/getCategoryByExam/${payload}`)
      return categoryList['data'] || []
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

let initialState = {
  isTestListLoading: false,
  exam: {},
  subjects: [],
  typeofTest: [],
  testList: [],
  myCart: [],
  getPreviousYearExamCategoriy: [],
  previousYearTestByCategoryData: [],
  overAllPrice: 0,
  cartCount: 0,
  takeTest: false,
  getInstitutionBatchTestResult: [],
  studentResultRank: [],
  finishedStudentCount: [],
  firstThreeRank: [],
  otherRankAll: [],
  studentCount: [],
  testDetails: [],
  absentStudent: [],
  totalStudentCount: [],
  categoryListData: []
}

const TestList = createSlice({
  name: 'TestList',
  initialState,
  reducers: {
    overAllPriceIncrease: (state, payload) => {
      state.overAllPrice = state.overAllPrice + payload.payload
    },
    overAllPriceDecrease: (state, payload) => {
      state.overAllPrice = state.overAllPrice - payload.payload
    },
    cartEmpty: (state) => {
      state.myCart = []
      state.cartCount = 0;
    },
    startTakeTest: (state) => {
      state.takeTest = true;
    },
    stopTakeTest: (state) => {
      state.takeTest = false;
    }
  },
  extraReducers: {
    [getSubjectExamById.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getSubjectExamById.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      if (actions.payload.success == true) {
        state.subjects = actions.payload.subjects;
        let all = {
          id: -1,
          name: 'All',
        }
        state.subjects.unshift(all)
      }
    },
    [getSubjectExamById.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getTestById.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getTestById.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      if (actions.payload.success == true) {
        state.testList = actions.payload.test
      }
    },
    [getTestById.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getTypeOfTest.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getTypeOfTest.fulfilled]: (state, actions) => {
      state.isTestListLoading = false;
      if (actions.payload.success == true) {
        state.typeofTest = actions.payload.typeofTest
      }
    },
    [getTypeOfTest.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [addToCart.pending]: (state) => {
      state.isTestListLoading = true
    },
    [addToCart.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      if (actions.payload.success) {
        state.myCart = actions.payload.cart;
        state.cartCount = state.cartCount + 1
      }
    },
    [addToCart.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getTestBySubject.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getTestBySubject.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      if (actions.payload.success == true) {
        state.testList = actions.payload.test
      }
    },
    [getTestBySubject.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getAllCart.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getAllCart.fulfilled]: (state, actions) => {
      let price = 0
      state.isTestListLoading = false
      if (actions.payload.success == true) {
        state.myCart = actions.payload.cart
        actions.payload.cart.map((item) => {
          price = price + item.testPrice
        })
        state.overAllPrice = price
      }
    },
    [getAllCart.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [removeFromCart.pending]: (state) => {
      state.isTestListLoading = true
    },
    [removeFromCart.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      if (actions.payload.success) {
        state.cartCount = state.cartCount - 1
      }
    },
    [removeFromCart.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getExamById.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getExamById.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      if (actions.payload.success == true) {
        state.exam = actions.payload.exam
      }
    },
    [getExamById.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [updateCart.pending]: (state) => {
      state.isTestListLoading = true
    },
    [updateCart.fulfilled]: (state) => {
      state.isTestListLoading = false
    },
    [updateCart.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [raiseADispute.pending]: (state) => {
      state.isTestListLoading = true
    },
    [raiseADispute.fulfilled]: (state) => {
      state.isTestListLoading = false
    },
    [raiseADispute.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [raiseADisputeInstitutionStud.pending]: (state) => {
      state.isTestListLoading = true
    },
    [raiseADisputeInstitutionStud.fulfilled]: (state) => {
      state.isTestListLoading = false
    },
    [raiseADisputeInstitutionStud.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getPreviousYearExam.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getPreviousYearExam.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      state.getPreviousYearExamCategoriy = actions.payload.previousYearExam
    },
    [getPreviousYearExam.rejected]: (state) => {
      state.isTestListLoading = false
    },

    [getPreviousYearTestByCategory.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getPreviousYearTestByCategory.fulfilled]: (state, actions) => {
      state.isTestListLoading = false
      state.previousYearTestByCategoryData = actions.payload.previousYearTest
    },
    [getPreviousYearTestByCategory.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [raiseADisputePreviousYearExam.pending]: (state) => {
      state.isTestListLoading = true
    },
    [raiseADisputePreviousYearExam.fulfilled]: (state) => {
      state.isTestListLoading = false
    },
    [raiseADisputePreviousYearExam.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getPreviousExamBatchTestRankDetails.pending]: (state, payload) => {
      state.isTestListLoading = true
      state.getInstitutionBatchTestResult = payload?.payload?.data.instituionalStudentCount[0]['institutionStudentCount'] || 0;
      state.studentResultRank = payload?.payload?.data.rankDetails || [];
      state.myStudDetails = payload?.payload?.data.myStudDetails[0] || {};
      state.totalStudentCount = payload?.payload?.data.testDetailCount || {};
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })
      state.firstThreeRank = state.studentResultRank.slice(0, 3) || [];
      state.otherRankAll = state.studentResultRank.slice(3) || [];
      state.testDetails = payload?.payload?.data.testDetail[0] || {};
      state.absentStudent = payload?.payload?.data.absentStudent || 0
    },
    [getPreviousExamBatchTestRankDetails.fulfilled]: (state, payload) => {
      state.isTestListLoading = false;
      state.getInstitutionBatchTestResult = payload?.payload?.data.instituionalStudentCount[0]['institutionStudentCount']
      state.studentResultRank = payload?.payload?.data.rankDetails
      state.myStudDetails = payload?.payload?.data.myStudDetails[0]
      state.totalStudentCount = payload?.payload?.data.testDetailCount
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })
      state.firstThreeRank = state.studentResultRank.slice(0, 3)
      state.otherRankAll = state.studentResultRank.slice(3)
      state.testDetails = payload?.payload?.data.testDetail[0]
      state.absentStudent = payload?.payload?.data.absentStudent

    },
    [getPreviousExamBatchTestRankDetails.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getNormalExamTestRankDetails.pending]: (state, payload) => {
      state.isTestListLoading = true
      state.getInstitutionBatchTestResult = payload?.payload?.data.instituionalStudentCount[0]['institutionStudentCount'] || 0;
      state.studentResultRank = payload?.payload?.data.rankDetails || [];
      state.myStudDetails = payload?.payload?.data.myStudDetails[0] || {};
      state.totalStudentCount = payload?.payload?.data.testDetailCount || {};
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })
      state.firstThreeRank = state.studentResultRank.slice(0, 3) || [];
      state.otherRankAll = state.studentResultRank.slice(3) || [];
      state.testDetails = payload?.payload?.data.testDetail[0] || {};
      state.absentStudent = payload?.payload?.data.absentStudent || 0
    },
    [getNormalExamTestRankDetails.fulfilled]: (state, payload) => {
      state.isTestListLoading = false;
      state.getInstitutionBatchTestResult = payload?.payload?.data.instituionalStudentCount[0]['institutionStudentCount']
      state.studentResultRank = payload?.payload?.data.rankDetails
      state.myStudDetails = payload?.payload?.data.myStudDetails[0]
      state.totalStudentCount = payload?.payload?.data.testDetailCount
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })
      state.firstThreeRank = state.studentResultRank.slice(0, 3)
      state.otherRankAll = state.studentResultRank.slice(3)
      state.testDetails = payload?.payload?.data.testDetail[0]
      state.absentStudent = payload?.payload?.data.absentStudent

    },
    [getNormalExamTestRankDetails.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getNormalStudentExamRank.pending]: (state, payload) => {
      state.isTestListLoading = true
      state.totalStudentCount = []
      state.studentResultRank = []
      state.myStudDetails = []
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })
      state.firstThreeRank = []
      state.otherRankAll = []
      state.testDetails = []
      state.absentStudent = []
      state.finishedStudentCount = []
      state.topScore = []
    },
    [getNormalStudentExamRank.fulfilled]: (state, payload) => {
      state.isTestListLoading = false
      state.totalStudentCount = payload?.payload?.data?.testDetailCount.finishedStudentCount
      state.studentResultRank = payload?.payload?.data.rankDetails
      state.myStudDetails = payload?.payload?.data.myStudDetails[0]
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })
      state.firstThreeRank = state.studentResultRank.slice(0, 3)
      state.otherRankAll = state.studentResultRank.slice(3)
      state.testDetails = payload?.payload?.data.testDetails[0]
      state.absentStudent = payload?.payload?.data.absentStudent
      state.finishedStudentCount = payload?.payload?.data?.testDetailCount?.finishedStudentCount
      state.topScore = payload?.payload?.data.myStudDetails[0].totalMark
    },
    [getNormalStudentExamRank.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getInduvialStudPreviousYearExamTestRankDetails.pending]: (state, payload) => {
      state.isTestListLoading = true
      state.totalStudentCount = payload?.payload?.data.totalStudentsCount?.[0]['totalStudentsAttended'] || []
      state.finishedStudentCount = payload?.payload?.data.totalStudentsCount?.[0]['totalStudentsAttended'] || []
      state.studentResultRank = payload?.payload?.data?.overAllRank || []
      state.topScore = payload?.payload?.data.testDetails[0].numberOfQuestions || []
      state.myStudDetails = payload?.payload?.data.myStudDetails?.[0] || []
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })

      state.firstThreeRank = state.studentResultRank?.slice(0, 3) || []
      state.otherRankAll = state.studentResultRank?.slice(3) || []
      state.testDetails = payload?.payload?.data?.testDetails?.[0] || []
    },
    [getInduvialStudPreviousYearExamTestRankDetails.fulfilled]: (state, payload) => {
      state.isTestListLoading = false
      state.totalStudentCount = payload?.payload?.data.totalStudentsCount?.[0]['totalStudentsAttended']
      state.finishedStudentCount = payload?.payload?.data.totalStudentsCount?.[0]['totalStudentsAttended']
      state.studentResultRank = payload?.payload?.data?.overAllRank
      state.topScore = payload?.payload?.data.testDetails[0].numberOfQuestions
      state.myStudDetails = payload?.payload?.data.myStudDetails?.[0]
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })

      state.firstThreeRank = state.studentResultRank?.slice(0, 3)
      state.otherRankAll = state.studentResultRank?.slice(3)
      state.testDetails = payload?.payload?.data?.testDetails?.[0]
    },
    [getInduvialStudPreviousYearExamTestRankDetails.rejected]: (state) => {
      state.isTestListLoading = false
    },
    [getCategoryDetailsList.pending]: (state) => {
      state.isTestListLoading = true
    },
    [getCategoryDetailsList.fulfilled]: (state, payload) => {
      state.categoryListData = payload.payload.data
      state.isTestListLoading = false
    },
    [getCategoryDetailsList.rejected]: (state) => {
      state.isTestListLoading = false
    },

  }
})
export const { overAllPriceDecrease, overAllPriceIncrease, cartEmpty, startTakeTest, stopTakeTest } = TestList.actions
export default TestList.reducer;
import AddImage from '../assets/images/add2.svg';
import MinusImage from '../assets/images/minus.svg';
import { Image, Divider, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart } from '../Slices/TestListSlice'
import { getAllCart, updateCart,overAllPriceIncrease,overAllPriceDecrease } from '../Slices/TestListSlice';
import { useNavigate } from 'react-router-dom';
import '../styles/cart.scss';






function CartComponent({ item ,from}) {

  const {overAllPrice}=useSelector((state)=>state.TestList)

  const [numberOfTest, setNumberOfTest] = useState(item.noOfTest);
  const [initialTrigger, setInitialTrigger] = useState(true);
  const [visible, setVisible] = useState(false)
  const [cartRemove, setCartRemove] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [overAllAmount,setOverAllAmount]=useState(overAllPrice);

  useEffect(() => {
    if (!initialTrigger) {
      dispatch(updateCart({ noOfTest: numberOfTest, id: item.id })).unwrap().then((res)=>{
      })
    }
    setInitialTrigger(false)
  }, [numberOfTest])

  function removeTest(remove) {
    setVisible(true)
    setCartRemove(remove.id)
  }

  function handleOk() {
    setVisible(false)
    dispatch(removeFromCart(cartRemove)).unwrap().then((res) => {
      if (res.success == true) {
        dispatch(getAllCart())
        message.success(res.removeMessage)
      }
    })
  }

  function handleCancel() {
    setVisible(false)
  }

  function increaseCount(){
    setNumberOfTest(numberOfTest + 1)
    dispatch(overAllPriceIncrease(item.testPrice))
  }
  function decreaseCount(){
    setNumberOfTest(numberOfTest - 1)
    dispatch(overAllPriceDecrease(item.testPrice))
  }

  return (
    <div className='over-all-cart-item'>
      <div className='cart-item'>
        <div className="cart-item-content">
          {/* <p className="sno">{i+1} .</p> */}
          <div className="content">
            <p className={item.typeOfTestId == 1 ? 'full-test-title' : (item.typeOfTestId == 2 ? 'subject-title' : 'chapter-title')}><span>{item.typeOfTest}</span><span className='examName'>{item.examName}</span></p>
            <p className="test-title">{item.testName}</p>
          </div>
        </div>
        <div className="count">
          {
            from == "cart" ?
            <>
            <Image preview={false} src={MinusImage} onClick={() => (numberOfTest > 1) ? decreaseCount(): null}></Image>
            <p>{numberOfTest}</p>
            <Image preview={false} src={AddImage} onClick={() => increaseCount()}></Image>
            </>
            :
            <p>{numberOfTest}</p>

          }

        </div>
        <div className="cart-item-price">
          <div className="price"><p>₹ {item.testPrice}</p></div>
        </div>
      </div>
      <Divider style={{background:'#E1E7EA'}}></Divider>
      <div className="single-cart-footer">
        {
           from == "cart" ?
            <a className="remove-cta" onClick={() => removeTest(item)}>Remove</a>

           :
           <a ></a>

        }
        <p className="sub-total">Subtotal ({numberOfTest}) <span>₹ {numberOfTest*item.testPrice}</span></p>
      </div>
      {/* reomve from cart modal */}
      <Modal title="Remove from cart" visible={visible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure want to remove cart ?</p>
      </Modal>
    </div>
  )
}
export default CartComponent;
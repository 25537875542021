import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import '../styles/institutionStudentTest.scss';
import '../styles/globalMediaQuery.scss';
import { Image, Button } from 'antd';
import { GetUserDetails, getInsStudAssignTest, getInsStudAssignTestToday, getInsStudCompletedTest } from '../Slices/HomePageSlice'
import { useDispatch, useSelector } from 'react-redux';
import ClockIcon from '../assets/images/clock_icon.svg'
import LanguageIcon from '../assets/images/languages.svg'
import UserIcon from '../assets/images/user_icon.svg'
import { useNavigate } from 'react-router-dom';
import TestEmptyImage from '../assets/images/institution_student_test_empty.svg'
import moment from 'moment'

const InstitutionStudentTest = () => {

  const navigate = useNavigate()
  const { getUserDetailsInsStud, userNameInsStud, getInsStudAssignTestTodayList, getInsStudAssignTestList, getInsStudCompletedTestList } = useSelector((state) => state.HomePage)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GetUserDetails()).unwrap().then((res) => {
    })
    dispatch(getInsStudAssignTest({ userId: localStorage.getItem('userId'), batchCode: localStorage.getItem('batchCode') })).unwrap().then((res) => {
    })
    dispatch(getInsStudAssignTestToday({ userId: localStorage.getItem('userId'), batchCode: localStorage.getItem('batchCode') })).unwrap().then((res) => {
    })
    dispatch(getInsStudCompletedTest({ userId: localStorage.getItem('userId'), batchCode: localStorage.getItem('batchCode') })).unwrap().then((res) => {
    })
  }, [])

  const takeTest = (testId, testAssgnId, data) => {
    if (data.isSingleTestPaper == 1) {
      navigate(`/institutionHome/previousYearTestInstruction/${testId}/assignId/${testAssgnId}`)
    } else {
      navigate(`/institutionHome/testInstructionInsStud/${testId}/assignId/${testAssgnId}`)
    }
  }

  const solutionRedirect = (data) => {
    if (data.isSingleTestPaper == 1) {
      navigate(`/institutionHome/solutionsPreviousYearExam/${data.testMasInsStudMapId}`)
    } else {
      navigate(`/institutionHome/solutionsInstitution/${data.testMasInsStudMapId}`)
    }
  }

  return (
    <>
      <div className="institution-stud-test-container">
        <div className="inst-dashboard-container">
          {/* header component */}
          <div className='user-header-container'>
            <div className='user-profile-header'>
              <div className='user-first-name'>
                <p className='text-name'>{userNameInsStud?.substring(0, 2)}</p>
              </div>
              <div className='inst-user-details'>
                <p className='inst-user-name'>{getUserDetailsInsStud?.email} </p>
                <p className='inst-user-number'>{getUserDetailsInsStud?.institutionCode}</p>
              </div>
            </div>
          </div>
          {/* today test */}
          <div className='today-test-container'>
            <p className='test-heading'>Live Tests</p>
            <div className='test-card-container'>
              {
                getInsStudAssignTestTodayList.length ?
                  getInsStudAssignTestTodayList.map((item, index) => {
                    return <div className='test-card' key={index}>
                      <div className='test-details-all'>
                        <p className='test-exam-group-name'>{item.groupName}</p>
                        <p className='test-name'>{item.testName}</p>
                        <div className='test-details'>
                          <p className='num-question'>{item.numberOfQuestions} Questions</p>
                          <div className='break'></div>
                          <p className='num-marks'>{item.totalMark} Marks</p>
                          <div className='break'></div>
                          <p className='num-min'>{item.timeLimit} Mins</p>
                        </div>
                      </div>
                      <div className='time-language'>
                        <div className='time-duration'>
                          <div className='image'>
                            <Image preview={false} src={LanguageIcon}></Image>
                          </div>
                          <p className='text'>English,Tamil</p>
                        </div>
                        <div className='time-duration'>
                          <div className='image'>
                            <Image preview={false} src={ClockIcon}></Image>
                          </div>
                          <p className='text'>Started Date {moment(item.isStartedDate).format('DD/MM/YYYY')} {item.startTime}</p>
                        </div>
                      </div>
                      <div className='take-test'>
                        <Button onClick={() => takeTest(item?.testId, item?.testAssgnId, item)}>Take Test</Button>
                      </div>

                    </div>
                  }) : <div className='empty-test-section'>
                    <div className='empty-content'>
                      <p className='heading'>Live test and Assigned tests will show here. </p>
                      <p className='text'>Get Ready to Unleash Your Potential!</p>
                    </div>
                    <div className='empty-image'>
                      <Image preview={false} src={TestEmptyImage}></Image>
                    </div>
                  </div>
              }
            </div>
          </div>
          {/* assign test */}
          <div className='assign-test-container'>
            <p className='test-heading'>Assigned Tests</p>
            <div className='test-card-container' >
              {
                getInsStudAssignTestList.length ?

                  getInsStudAssignTestList.map((item, index) => {
                    return <div className='test-card' key={index}>
                      <p className='test-exam-group-name'>{item.groupName}</p>
                      <p className='test-name'>{item.testName}</p>
                      <div className='test-details'>
                        <p className='num-question'>{item.numberOfQuestions} Questions</p>
                        <div className='break'></div>
                        <p className='num-marks'>{item.totalMark} Marks</p>
                        <div className='break'></div>
                        <p className='num-min'>{item.timeLimit} Mins</p>
                      </div>
                      <div className='time-duration'>
                        <div className='image'>
                          <Image preview={false} src={ClockIcon}></Image>
                        </div>
                        <p className='time'>Started Date {moment(item.isStartedDate).format('DD/MM/YYYY')} {item.startTime}</p>
                      </div>
                    </div>
                  })
                  : <div className='empty-test-section'>
                    <div className='empty-content'>
                      <p className='heading'>Live test and Assigned tests will show here. </p>
                      <p className='text'>Get Ready to Unleash Your Potential!</p>
                    </div>
                    <div className='empty-image'>
                      <Image preview={false} src={TestEmptyImage}></Image>
                    </div>
                  </div>
              }
            </div>

          </div>
          {/* completed test */}
          <div className='complete-test-container'>
            <p className='test-heading'>Completed Tests</p>
            <div className='test-card-container' >
              {
                getInsStudCompletedTestList.length ?

                  getInsStudCompletedTestList.map((item, index) => {
                    return <div className='test-card' key={index}>
                      <p className='test-exam-group-name'>{item.groupName}</p>
                      <p className='test-name'>{item.testName}</p>
                      <div className='test-details'>
                        <p className='num-question'>{item.numberOfQuestions} Questions</p>
                        <div className='break'></div>
                        <p className='num-marks'>{item.totalMark} Marks</p>
                        <div className='break'></div>
                        <p className='num-min'>{item.timeLimit} Mins</p>
                      </div>
                      <div className='language'>
                        <div className='image'>
                          <Image preview={false} src={LanguageIcon}></Image>
                        </div>
                        <p className='text'>English,Tamil</p>
                      </div>
                      <div className='time-duration'>
                        <div className='image'>
                          <Image preview={false} src={ClockIcon}></Image>
                        </div>
                        <p className='text'>Started Date {moment(item.isStartedDate).format('DD/MM/YYYY')} {item.startTime}</p>
                      </div>
                      <div className='batch'>
                        <div className='image'>
                          <Image preview={false} src={UserIcon}></Image>
                        </div>
                        <p className='text'>{item.batchName}</p>
                      </div>
                      <div className='break-line'></div>
                      <a onClick={() => solutionRedirect(item)} className='view-result'>View Result</a>
                    </div>
                  })
                  : <div className='empty-test-section'>
                    <div className='empty-content'>
                      <p className='heading'>Live test and Assigned tests will show here. </p>
                      <p className='text'>Get Ready to Unleash Your Potential!</p>
                    </div>
                    <div className='empty-image'>
                      <Image preview={false} src={TestEmptyImage}></Image>
                    </div>
                  </div>
              }
            </div>

          </div>
        </div>
      </div >
    </>
  )
}
export default InstitutionStudentTest
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../utlis/Auth'

const RequiredLoginAuth = ({ children }) => {
 // const auth = useAuth()
 const navigate = useNavigate()
 let token  = localStorage.getItem('token') ? true : false
 useEffect(()=>{
  if (token) {
    navigate('/home')
  }
 },[])

 return !token ? children : null
}

export default RequiredLoginAuth
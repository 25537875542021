import { Button, Col, Divider, Image, Input, Modal, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import TestSeries from '../Component/TestSeries'
import userLogo from '../assets/images/userLogo.svg'
import SingleTest from '../Component/SingleTest'
import NewExam from '../Component/NewExam'
import upscLogo from '../assets/images/upsc-logo-new.svg'
import upscPrelimary from '../assets/images/upsc-Prelimnary.svg'
import { useNavigate } from 'react-router-dom'
import { GetAllExam, GetAllExamGroup, GetAllTest, GetAllTestSeries, GetUserDetails } from '../Slices/HomePageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { GetTestSeriesById } from '../Slices/TestSeriesSlice'
import { useAuth } from '../utlis/Auth'
import { LoadingOutlined } from '@ant-design/icons'
import Loading from '../Component/Loading'
import PopularTestComponent from '../Component/popularTestComponent'
import NewExamGroup from '../Component/NewExamGroup'



const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);


const Home = () => {
  const auth = useAuth()
  const languages = ["tamil", "english"]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoading, getAllTestSeries, getAllExam, getAllTest, getUserDetails, getAllExamGroup } = useSelector((state) => state.HomePage)
  const [profileName, setProfileName] = useState(localStorage.getItem("name").trim().charAt(0).toUpperCase())

  const seriesPressed = (index) => {
    dispatch(GetTestSeriesById(index)).unwrap().then((res) => {
      navigate(`testSeries/${index}`)
      // navigate(`testSeries/${index}`,{state:{name:"newExam",routeLink:`testSeries/${index}`}})

    })


  }
  //  useEffect(()=>{
  // if(!auth.token){
  //  navigate('/login')
  // }
  //  },[auth.token])
  useEffect(() => {
    let obj = {
      "offset": 0
    }

    dispatch(GetAllTestSeries(obj)).unwrap().then((res) => {
    })
    let examObj = {
      filterData: ''
    }
    dispatch(GetAllExam(examObj)).unwrap().then((res) => {
    })
    dispatch(GetAllTest(obj)).unwrap().then((res) => {
    })
    dispatch(GetUserDetails()).unwrap().then((res) => {
    })
    dispatch(GetAllExamGroup(examObj)).unwrap().then((res) => {
    })
  }, []);

  const examGroupViewAll = () => {
    navigate('exams')
  }

  const popularViewAll = () => {
    navigate('popularTest')
  }

  //  if(isLoading){
  //   return(
  //    <div className='loading'>
  //    <Spin tip="loading....." indicator={antIcon} >

  //    </Spin>
  //  </div>
  //   )
  //  }
  //  else{
  return (
    <>
      <div className='home-container'>
        <div className='random-header'>
          <div className='user-container'>
            <div className='userProfile'>
              <div className='firstName'>
                <span >{profileName}</span>
              </div>
            </div>
            <div className='user-details'>
              <p className='user-name'>{getUserDetails?.name} </p>
              <p className='tag'>Hope your test will be great today!</p>

            </div>
          </div>
          <div className='subscription-container'>

            <div className='subscription-details'>

              <p className='subscription-tag'>Basic plan start from</p>
              <p className='subscription-amount'> ₹ 1500/ mon</p>
            </div>
            <Button type='primary' className='primary-submit-button'>
              Get subscription
            </Button>
          </div>


        </div>
        <div className='container-wrapper'>
          <div className='take-challenge'>
            <div className='header-content'>
              <h2 className='header-text'>
                Mock Tests
              </h2>
              {/* <p className='view-all'> VIEW ALL</p> */}
            </div>

            <div className='card-scroll'>
              <div className="single-card">
                {/* {
              getAllTest.length ?
                getAllTest.map((e, index) => {
                  if (index < 1) {
                    return (
                      // <SingleTest data={e} name="TNPSC Group 4 GK: Important Gupta Empire Quiz" totalQuestion={10} totalMins={12} languages={languages.join(', ')} />
                      <PopularTestComponent item={e} languages={languages.join(', ')} />

                    )
                  }


                }) : null
            } */}
                {
                  getAllTest?.length ?
                    <Row>
                      {
                        getAllTest.map((e, index) => {
                          if (index < 1) {
                            return (
                              <Col xs={24} lg={12} xl={12} xxl={8}>
                                <PopularTestComponent item={e} languages={languages.join(', ')} />
                              </Col>

                            )
                          }


                        })
                      }

                    </Row>
                    : null
                }
              </div>
            </div>

          </div>
          <div className='new-exams'>
            <div className='header-content'>
              <h2 className='header-text'>Exams</h2>
              <p onClick={() => examGroupViewAll()} className='view-all'>View All</p>

            </div>
            <div className='card-scroll'>
              {
                getAllExamGroup.length ?

                  <Row>
                    {
                      getAllExamGroup.map((data, index) => {
                        if (index < 8) {
                          return (

                            <Col xs={12} sm={12} md={8} lg={6}>
                              <NewExamGroup examData={data} />
                            </Col>
                          )
                        }

                      })
                    }
                  </Row>



                  : null
              }
            </div>
          </div>
          <div className='home-test'>
            <div className='header-content'>
              <h2 className='header-text'>Popular Tests</h2>
              <p onClick={() => popularViewAll()} className='view-all'>View All</p>

            </div>
            <div className='card-scroll'>
              {
                getAllTest?.length ?
                  <Row>
                    {
                      getAllTest.map((e, index) => {
                        if (index < 3) {
                          return (
                            <Col xs={24} lg={12} xl={12} xxl={8}>
                              <PopularTestComponent item={e} languages={languages.join(', ')} />
                            </Col>

                          )
                        }


                      })
                    }

                  </Row>
                  : null
              }
            </div>
          </div>

        </div>


      </div>
      {
        isLoading ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }


    </>
  )

}

export default Home
import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Row ,Col, Input, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import BreadCrumbDetails from '../Component/BreadCrumb'
import Loading from '../Component/Loading'
import NewExam from '../Component/NewExam'
import searchIcon from '../assets/images/Search_light.svg'
import { GetAllExam, GetExamGroupById, GetExamGroupBySlugId } from '../Slices/HomePageSlice'
import newEmptyData from '../assets/images/newEmptyData.svg'

const { Search } = Input;

const Exam = () => {
    const [filterData, setFilterData] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const { isLoading, getAllTestSeries, getAllExam, getAllTest, getUserDetails,getExamGroupBySlugId,getExamGroupById } = useSelector((state) => state.HomePage)

  useEffect(() => {
    let obj = {
      "offset": 0
    }
    let examObj = {
        filterData:''
            }
    let  exam_obj = {
      filterData:'',
      slugId:params.slugId
    }
    
    dispatch(GetExamGroupBySlugId(exam_obj)).unwrap().then((res) => {
    })
    dispatch(GetExamGroupById(params.slugId)).unwrap().then((res) => {
    })
    // dispatch(GetAllExam(examObj)).unwrap().then((res) => {
    // })
  }, []);
  
  const onSearch = (data ) =>{
    setFilterData(data)
    // let examObj = {
    //     filterData:data
    //         }
    let  exam_obj = {
      filterData:data,
      slugId:params.slugId
    }
debounced(exam_obj)
  }

  const debounced = useDebouncedCallback((obj) =>{
    // dispatch(GetAllExam(obj))
   dispatch(GetExamGroupBySlugId(obj))

  },400)


  return (
    <>
    <div className='get-all-exam-container'>
        <div className='get-all-exam-wrapper'>
            <div className='breadCrumbs-container'>
                <BreadCrumbDetails></BreadCrumbDetails>
            </div>
            <div className='header-container'>
                <div className='header-card'>
                  <div className='card-details'>
                    <Image className='groupExam' src={getExamGroupById?.groupLogo} preview={false}></Image>
                    <p className='header-text'>{getExamGroupById?.groupName}</p>
                  </div>
                    <div className='search'>
                        {/* <Input placeholder='Search Exams' /> */}
                        {/* <Search placeholder="Search Exams" onChange={onSearch} onSearch={onSearch} style={{ width: 200 }} /> */}
                        <Input size="large" placeholder="Search"  onChange={(e)=>onSearch(e.target.value)} suffix={<img src={searchIcon} alt="Search icon" />} />
                    </div>
                </div>
            </div>
            <div className='body-container'>
                <div className='body-wrapper'>
                {
              getExamGroupBySlugId?.length ?
                    <Row>
              <>
                {
                  getExamGroupBySlugId.map((data, index) => {

                    return (
                      
                      <Col key={index} xs={12} sm={12} md={8} lg={6} >
                    <NewExam examData={data} />
                    </Col>
                    )
                })
                }
                </>
                    </Row>
                    :    
                    <div className='empty-container'>
                    <div className='empty-content'>
                      <Image preview={false} src={newEmptyData}  ></Image>
                      {/* <p className='empty-text'>You haven't any Order test.</p> */}
                    </div>
                
                    </div>
                }
                    
                </div>

            </div>
        </div>

    </div>
          {
            isLoading ? <div className='loading-container'>
    
              <Loading></Loading>
    
            </div> : null
          }
    </>
  )
}

export default Exam
import { useEffect,useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {Image,Col,Row,Divider,Select, Button,Modal, message} from 'antd';
import { useNavigate } from 'react-router-dom';
import Expand from '../assets/images/Expandright.svg';
import Divide from '../assets/images/divider.svg';
import '../styles/cart.scss';
import {removeFromCart} from '../Slices/TestListSlice'
import { getAllCart,cartEmpty } from '../Slices/TestListSlice';
import Loading from '../Component/Loading';
import axios from "axios";
import CartComponent from '../Component/cartComponent';
import emptyCartImage from '../assets/images/cartEmpty.svg'
import couponOff from '../assets/images/couponImage.svg'
import { TaobaoOutlined } from '@ant-design/icons';
import newEmptyData from '../assets/images/newEmptyData.svg'
import couponImage from '../assets/images/coupon.svg'
import arrow from '../assets/images/Expandright.svg'
import { couponApplyCart, deleteCoupon, GetAllCoupons } from '../Slices/PurchasedListSlice';
import moment from 'moment';



const { Option} = Select;

function Checkout(){

  const [totalTest,setTotalTest]=  useState(0);
  const [totalAmout,setTotalAmout]=  useState(0);
  const [visible, setVisible] = useState(false);
  const handleCancel = () => {
    setVisible(false);
  };

  //razorpay 

  const  loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

  const displayRazorpay = async() => {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    // creating a new order
    // const result = await axios.post("/test/buyNow");
    const result = await axios.post("/test/buyNow",{orderId:proceedToPayData.data.orderId});

    if (!result) {
        alert("Server error. Are you online?");
        return;
    }

    // Getting the order details back
    const { amount, id, currency ,key} = result.data.result;

    const options = {
        key: key, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: " Corp.",
        description: "Test Transaction",
        // image: { logo },
        order_id: id,
        handler: async (response) => {

            const data = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
          };
          const result = await axios.put("/test/updatePaymentDetails/success", data).then(async(res)=>{
            dispatch(cartEmpty())
            message.success(res.data.message);
            navigate('/home/availableTest')
          });


          // }
         
        },
        
       prefill: {
            name: "Soumya Dey",
            email: "SoumyaDey@example.com",
            contact: "9999999999",
        },
        notes: {
            address: "Soumya Dey Corporate Office",
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options)
    paymentObject.on("payment.failed", async (response) => {   

      const data = {
        razorpayPaymentId: response.error.metadata.payment_id,
        razorpayOrderId: response.error.metadata.order_id,
        errorDetails: response.error.description
    };


      const result = await axios.put("/test/updatePaymentDetails/failure", data);
      getAllCartFun()
       alert(response.error.description);    
  })
    paymentObject.open() 
}
  // end razorpay
  const dispatch=useDispatch();
  const navigate=useNavigate();

  const [numberOfTest,setNumberOfTest]=useState(0);
  const [initialTrigger,setInitialTrigger]=useState(true)
  useEffect(()=>{
    var overAllPrice=0
    getAllCartFun()
    // dispatch(proceedToPay())
  },[])


  const getAllCartFun = () =>{
    dispatch(getAllCart()).unwrap().then((res)=>{
     })
  }
  const applyCoupon = (id)=>{
    setVisible(false)
    dispatch(couponApplyCart(id))
  }
  const [count,setCount]=useState(0);

  const {myCart,isTestListLoading,overAllPrice,subTotal}=useSelector((state)=>state.TestList)
 const { getAllCoupons, isPurchasedTestLoading,couponApplyCartData ,proceedToPayData} = useSelector(((state) => state.PurchasedList))



  useEffect(()=>{
    dispatch(GetAllCoupons()).unwrap().then((res)=>{
    })
  },[])

  const removeCoupons = (id) =>{
    dispatch(deleteCoupon(id))
  }

  useEffect(()=>{
   let  tTest = 0;
    let tAmout = 0;
    for(let i=0;i<myCart.length;i++){
      tTest  = myCart[i].noOfTest + tTest     
      tAmout  = (myCart[i].testPrice * myCart[i].noOfTest) + tAmout     
    }
    setTotalTest(tTest)
    setTotalAmout(tAmout)
  },[myCart])
  return(
    <div className='my-cart'>
       <div className='path'>
        <p onClick={()=>navigate('/home')}>Home</p>
        <Image preview={false} src={Expand}></Image>
        <p>Checkout</p>
      </div>
      <div className="cart-header">
        <p>My Order</p>
      </div>
      <div className='cart-banner'><p>{myCart.length ? myCart.length : 0} Items In Your Basket</p></div>
      <div className='my-cart-content'>
        {myCart.length==0 ?
      <div className='empty-container'>
      <div className='empty-content'>
       <Image preview={false} src={newEmptyData}  ></Image>
       {/* <p className='empty-text'>You haven't any Available test.</p> */}
      </div>

     </div>
        :
        <Row>
        <Col xs={24} sm={24} md={17} className='cart-list'>
          { myCart.length ? 
          myCart.map((item,i)=>
         <CartComponent item={item} subTotal={myCart.length} from="checkout"></CartComponent>
          ): null}
        </Col>
        <Col  xs={24} sm={24} md={7} >
          <div className='cart-price'>
            <div className='total'>
            <p className='subTotal'>Subtotal ({totalTest ? totalTest : 0}): </p>
            <p className='totalAmount'>{totalAmout ? `₹ ${totalAmout}`:0}</p>
            </div>
            {
                !couponApplyCartData.orderId?
                <div className='coupon-container' onClick={()=>setVisible(true)} >
                <Image src={couponImage} preview={false}></Image>
                <p className='use-coupon'>Use Coupon</p>
                <Image src={arrow} preview={false}></Image>
  
              </div>
                  :
                  <div className='coupon-details' >
                      <div>
                      <p className='name'>{couponApplyCartData.couponData.name}</p>
                      <p className='text'>Save another ₹{couponApplyCartData.discountAmount} on this order</p>
                      </div>
                      <a className='link' onClick={()=>removeCoupons(proceedToPayData.data.orderId)}>Remove</a>
      
                  </div>
            }


            <div className='bill-wrapper'>
                <>
                <p className='bill-header-text'>Bill Details</p>
                <p className='itemData'> <span className='text'>Item Total</span> <span className='amount'>{totalAmout ? `₹ ${totalAmout}`:0}</span> </p>
                {
                    couponApplyCartData.orderId ? 
                    <p className='itemData'> <span className='text'>Item Discount</span> <span className='amount'>{couponApplyCartData.discountAmount ? `₹ ${couponApplyCartData.discountAmount}`:0}</span> </p>
                    :
                    null
                }
                <Divider style={{background:'#7A8B94'}} type='horizontal'></Divider>


                {
                    couponApplyCartData.orderId ? 
                    <p className='totalPay'> <span className='text'>Item Discount</span> <span className='amount'>{couponApplyCartData.totalAmount ? `₹ ${couponApplyCartData.totalAmount - couponApplyCartData.discountAmount}`:0}</span> </p>
                    :
                    <p  className='totalPay'><span className='text'>To Pay</span> <span className='amount'>{totalAmout ? `₹ ${totalAmout}`:0}</span></p>

                }

                
                </>
            </div>
            <div>
            <Button onClick={()=> displayRazorpay()}>Proceed to Pay</Button>
            </div>
          </div>
        </Col>
      </Row>}
      </div>
      <Modal className='ava-modal' title="Available Coupon" visible={visible} footer={null} onCancel={handleCancel}>
       <div className='available-coupon-wrapper'>
        {
          getAllCoupons.length ? 
          getAllCoupons.map((data,index)=>{
            return(
              <>
              <div  className='available-card'>
                <div className='details'>
                  <div className='content'>
                    <div >
                    <Image src={couponOff} preview={false}></Image>
                    </div>
                    <div className='content-wrapper'>
                      <p className='couponName'>{data.name}</p>
                      <span className='discount'>{data.discountPercentage}% off</span>
                    </div>
                  </div>
                  <div className='links'>
                 <a className='apply-coupon' onClick={()=>applyCoupon(data.id)}>APPLY COUPON</a> 
                  </div>
                </div>
                <p className='expires'>Expires on- {moment(data.validDateTime).format('DD MMM yyyy')}</p>
              </div>

              </>
            )
          }) 
          : null
        }


       </div>
      </Modal>
      {isTestListLoading || isPurchasedTestLoading ? <Loading></Loading>:null}
    </div>
  )
}

export default Checkout;
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Image } from 'antd'
import React from 'react'
import upscLogo from '../assets/images/upsc-logo-new.svg'

const NewExamGroup = ({ examData, examLogo, examText }) => {

  const navigate = useNavigate()

  const location=useLocation()


    // take test 

    function takeTest(){
      navigate(`/home/exams/${examData.slugId}`)
      // navigate('/home/testList/'+examData.id)
    }

 return (
  <div className='new-exam-container' key={examData?.id} onClick={()=>takeTest()}>
  <div className='exam-image'>
   <Image preview={false} src={examData?.groupLogo  }></Image>
  </div>
  <div className='exam-text'>

   <p className='exam-name'>{examData?.groupName}</p>
  </div>

  </div>
 )
}

export default NewExamGroup

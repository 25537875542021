import { Button, Divider, Image, message, Modal } from 'antd';
import CategoryViewIcon from '../assets/images/category_view_icon.svg';
import CategoryRightIcon from '../assets/images/category_right_icon.svg';
import '../styles/takeTest.scss'
import { useNavigate } from 'react-router-dom';

function CategoryList({ listData }) {

    const navigate = useNavigate()


    const showQuestionBankList = (listData) => {
        navigate(`/home/questionBankTest/${listData.id}`)
    };

    return (
        <div className='category-list'>
            <div className='category-card' onClick={() => showQuestionBankList(listData)}>
                <div className='category-name-section'>
                    <div className='category-icon'>
                        <Image preview={false} src={CategoryViewIcon}></Image>
                    </div>
                    <div className='category-name-text'>
                        <p className='category-name'>{listData.categoryName}</p>
                        <p className='number-test'>{listData.noOfTestCount} Tests</p>
                    </div>
                </div>
                <div className='right-arrow-icon'>
                    <Image preview={false} src={CategoryRightIcon}></Image>
                </div>
            </div>
        </div>
    )
}

export default CategoryList;
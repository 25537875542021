import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { act } from "react-dom/test-utils";

export const getAttendedTest = createAsyncThunk(
  'AttendedTest/getAttendedTest', async (payload) => {
    try {
      let data = {
        offset: 0
      }
      let attendedTestData = axios.post('test/getTakenTest', data)
        .then((res) => {
          return res.data.data
        })
      return attendedTestData;
    }
    catch (error) {
    }
  }
)

export const getTestSolution = createAsyncThunk(
  'AttendedTest/getTestSolution', async (payload) => {
    try {
      let solutionData = axios.get('test/getAttemptedTest/' + payload)
        .then((res) => {
          if (res.data.status == true) {
            return res.data
          }
        })
      return solutionData;
    }
    catch (error) {
    }
  }
)

/* institution student get test solution insStu Normal test*/
export const getTestSolutionInstitution = createAsyncThunk(
  'AttendedTest/getTestSolutionInstitution', async (payload) => {
    try {
      let solutionData = axios.get('test/getAttemptedTestInsStudent/' + payload)
        .then((res) => {
          if (res.data.status == true) {
            return res.data
          }
        })
      return solutionData;
    }
    catch (error) {
    }
  }
)

// normal student download
export const downloadQuestionAnswerInduvial = createAsyncThunk(
  'AttendTest/downloadQuestionAnswerInduvial', async (payload) => {
    try {
      let solutionData = axios.get('test/downloadQuestionAnswerInduvial/' + payload)
        .then((res) => {
          if (res.data.status == true) {
            return res.data
          }
        })
      return solutionData;
    }
    catch (error) {
      console.log(error,"error");
    }
  }
)

/* institution student download */
export const downloadQuestionAnswer = createAsyncThunk(
  'AttendTest/getAttTestByTestMasterInsStudMapId', async (payload) => {
    try {
      let solutionData = axios.get('test/downloadQuestionAnswer/' + payload)
        .then((res) => {
          if (res.data.status == true) {
            return res.data
          }
        })
      return solutionData;
    }
    catch (error) {
    }
  }
)

/* institution student download */
export const downloadQuestionAnswerSingleTestPaper = createAsyncThunk(
  'AttendTest/getAttTestByTestMasterInsStudMapId', async (payload) => {
    try {
      let solutionData = axios.get('test/downloadQuestionAnswerSingleTestPaper/' + payload)
        .then((res) => {
          if (res.data.status == true) {
            return res.data
          }
        })
      return solutionData;
    }
    catch (error) {
    }
  }
)



export const getAttemptedTestPreviousYearExam = createAsyncThunk(
  'AttendedTest/getAttemptedTestPreviousYearExam', async (payload) => {
    try {
      let solutionData = axios.get('test/getAttemptedTestPreviousYearExam/' + payload)
        .then((res) => {
          if (res.data.status == true) {
            return res.data
          }
        })
      return solutionData;
    }
    catch (error) {
    }
  }
)

export const getTestSolutionPreviousYearExam = createAsyncThunk(
  'AttendedTest/getTestSolutionPreviousYearExam', async (payload) => {
    try {
      let solutionData = axios.get('test/getAttemptedTestPreviousYearExamNormal/' + payload)
        .then((res) => {
          if (res.data.status == true) {
            return res.data
          }
        })
      return solutionData;
      // let solutionData = axios.get('test/getAttemptedTestPreviousYearExam/' + payload)
      //   .then((res) => {
      //     if (res.data.status == true) {
      //       return res.data
      //     }
      //   })
      // return solutionData;
    }
    catch (error) {
    }
  }
)

const initialState = {
  attendedTest: [],
  attendedTypeOfTest: [],
  testDetails: {},
  attendedTestDetails: {},
  isAttendedTestLoading: false,
  getAttendedTestListQuestionBank: []
}

const AttendedTest = createSlice({
  name: 'AttendedTest',
  initialState,
  extraReducers: {
    [getAttendedTest.pending]: (state) => {
      state.isAttendedTestLoading = true;
    },
    [getAttendedTest.fulfilled]: (state, actions) => {
      state.isAttendedTestLoading = false;
      let all = {
        id: -1,
        testType: 'All',
        numberOfTest: actions.payload.getAttendedTestList.length + actions.payload.getAttendedTestListQuestionBank.length
      }
      state.getAllAttemptedList = [
        ...(actions.payload?.getAttendedTestList || []),
        ...(actions.payload?.getAttendedTestListQuestionBank || [])
      ];
      
      state.getAttendedTestListQuestionBank = actions.payload?.getAttendedTestListQuestionBank
      state.attendedTest = actions.payload?.getAttendedTestList;
      state.attendedTypeOfTest = actions.payload?.getTypeOfTestAttempted
      state.attendedTypeOfTest.unshift(all)
    },
    [getAttendedTest.rejected]: (state) => {
      state.isAttendedTestLoading = false;
    },
    [getTestSolution.pending]: (state) => {
      state.isAttendedTestLoading = true;
    },
    [getTestSolution.fulfilled]: (state, actions) => {
      state.isAttendedTestLoading = false;
      if (actions.payload.status) {
        state.testDetails = actions.payload?.data.getTakenTest;
        state.attendedTestDetails = actions.payload?.data.getTestDetailsByTest
      }
    },
    [getTestSolution.rejected]: (state) => {
      state.isAttendedTestLoading = false;
    },
    /* institution solution  */
    [getTestSolutionInstitution.pending]: (state) => {
      state.isAttendedTestLoading = true;
    },
    [getTestSolutionInstitution.fulfilled]: (state, actions) => {
      state.isAttendedTestLoading = false;
      if (actions.payload.status) {
        state.testDetails = actions.payload?.data.getTakenTest;
        state.attendedTestDetails = actions.payload?.data.getTestDetailsByTest
      }
    },
    [getTestSolutionInstitution.rejected]: (state) => {
      state.isAttendedTestLoading = false;
    },
    [downloadQuestionAnswer.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [downloadQuestionAnswer.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.attendQuestionList = actions.payload.questionData
      state.attendTestDetails = actions.payload.testDetails
    },
    [downloadQuestionAnswer.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [downloadQuestionAnswerSingleTestPaper.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [downloadQuestionAnswerSingleTestPaper.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.attendQuestionList = actions.payload.questionData
      state.attendTestDetails = actions.payload.testDetails
    },
    [downloadQuestionAnswerSingleTestPaper.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [getTestSolutionPreviousYearExam.pending]: (state) => {
      state.isAttendedTestLoading = true;
    },
    [getTestSolutionPreviousYearExam.fulfilled]: (state, actions) => {
      state.isAttendedTestLoading = false;
      if (actions.payload.status) {
        state.testDetails = actions.payload?.data.getTakenTest;
        state.attendedTestDetails = actions.payload?.data.getTestDetailsByTest
      }
    },
    [getTestSolutionPreviousYearExam.rejected]: (state) => {
      state.isAttendedTestLoading = false;
    },
    [getAttemptedTestPreviousYearExam.pending]: (state) => {
      state.isAttendedTestLoading = true;
    },
    [getAttemptedTestPreviousYearExam.fulfilled]: (state, actions) => {
      state.isAttendedTestLoading = false;
      if (actions.payload.status) {
        state.testDetails = actions.payload?.data.getTakenTest;
        state.attendedTestDetails = actions.payload?.data.getTestDetailsByTest
      }
    },
    [getAttemptedTestPreviousYearExam.rejected]: (state) => {
      state.isAttendedTestLoading = false;
    },
   [downloadQuestionAnswerInduvial.pending]: (state) => {
      state.isAttendedTestLoading = true;
    },
    [downloadQuestionAnswerInduvial.fulfilled]: (state, actions) => {
      state.isAttendedTestLoading = false;
    },
    [downloadQuestionAnswerInduvial.rejected]: (state) => {
      state.isAttendedTestLoading = false;
    },
  }
})

export default AttendedTest.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

//Get All Test Series
export const GetAllTestSeries = createAsyncThunk('Home/GetAllTestSeries', async (payload, thunk) => {
  try {

    const getData = await axios.post("/test/getTestBundleAll", payload).then((res) => {
      if (res.data?.success) {
        return res.data?.data

      }
    })

    return getData

  } catch (error) {
  }
})

//Get All Exam
export const GetAllExam = createAsyncThunk('Home/GetAllExam', async (payload, thunk) => {
  try {
    const getData = await axios.post(`/test/getAllExamList`, payload).then((res) => {
      if (res.data?.success) {
        return res.data?.data

      }
    })

    return getData

  } catch (error) {
  }
})

//Get All Test
export const GetAllTest = createAsyncThunk('Home/GetAllTest', async (payload, thunk) => {
  try {
    const getData = await axios.get("/test/getRandomTest", payload).then((res) => {
      if (res.data?.success) {
        return res.data?.data

      }
    })

    return getData

  } catch (error) {
  }
})

//Get userDetails
export const GetUserDetails = createAsyncThunk('Home/GetUserDetails', async (payload, thunk) => {
  try {
    const getData = await axios.get("/users/getStudentDetails").then((res) => {
      if (res.data?.success) {
        return res.data?.data

      }
    })

    return getData

  } catch (error) {
  }
})

/* get institution student details */
export const GetUserDetailsInstitution = createAsyncThunk('Home/GetUserDetailsInstitution', async (payload, thunk) => {
  try {
    const getData = await axios.get("/users/getStudentDetailsInstitution").then((res) => {
      if (res.data?.success) {
        return res.data?.data
      }
    })
    return getData
  } catch (error) {
  }
})

export const GetAllExamGroup = createAsyncThunk('Home/GetAllExamGroup', async (payload, thunk) => {
  try {
    const getData = await axios.post("/test/getAllExamGroup", payload).then((res) => {
      if (res.data?.status) {
        return res.data?.data

      }
    })

    return getData

  } catch (error) {
  }
})

export const GetExamGroupBySlugId = createAsyncThunk('Home/GetExamGroupBySlugId', async (payload, thunk) => {
  try {
    const getData = await axios.post("/test/getExamGroupBySlugId", payload).then((res) => {
      if (res.data?.status) {
        res.data.breadCrumbData['isExam'] = true;
        thunk.dispatch(addBreadCrumbData(res.data.breadCrumbData))
        return res.data?.data

      }
    })

    return getData

  } catch (error) {
  }
})


export const GetExamGroupById = createAsyncThunk('Home/GetExamGroupById', async (payload, thunk) => {
  try {
    const getData = await axios.get("/test/getExamGroupById/" + payload).then((res) => {
      if (res.data?.status) {
        return res.data?.data

      }
    })

    return getData

  } catch (error) {
  }
})

export const addBreadCrumbData = createAsyncThunk("Masters/getCurrentUser", async (payload) => {
  try {
    payload['home'] = 'Home'
    // payload['exam'] = 'Exams'

    return payload

  } catch (error) {
  }
})



export const getInsStudAssignTest = createAsyncThunk(
  'Home/getInsStudAssignTest', async (payload, thunk) => {
        try {
      const getData = await axios.get(`test/institution/getTestByStudent?batchCode=${payload.batchCode}`).then((res) => {
        if (res.data?.success) {
                    return res.data?.data

        }
      })

      return getData

    } catch (error) {
    }
  })

export const getInsStudAssignTestToday = createAsyncThunk(
  'Home/getInsStudAssignTestToday', async (payload, thunk) => {
    try {
      const getData = await axios.get(`test/institution/getTestTodayByStudent?batchCode=${payload.batchCode}`).then((res) => {
        if (res.data?.success) {
                    return res.data?.data

        }
      })

      return getData

    } catch (error) {
    }
  })


export const getInsStudCompletedTest = createAsyncThunk(
  'Home/getInsStudCompletedTest', async (payload, thunk) => {
    try {
      const getData = await axios.get(`test/institution/getCompletedTestByStudent?batchCode=${payload.batchCode}`).then((res) => {
        if (res.data?.success) {
          return res.data?.data

        }
      })

      return getData

    } catch (error) {
    }
  })

export const updateInstituionProfile = createAsyncThunk(
  'Home/updateInstituionProfile', async (payload) => {
    try {
      const updateProfile = await axios.put(`test/updateInstituionProfile/${payload.id}`, payload.data)
        .then((res) => {
          if (res.data.status) {

            return res.data.data
          }
        })
      return updateProfile
    } catch (error) {
    }
  }
)
const HomePage = createSlice({
  name: "HomePage",
  initialState: {
    isLoading: false,
    getAllTestSeries: [],
    userName: "",
    getAllExam: [],
    getAllTest: [],
    getAllExamGroup: [],
    getUserDetails: {},
    getExamGroupBySlugId: [],
    getExamGroupById: {},
    breadCrumbData: {},
    getInsStudAssignTestList: [],
    getInsStudAssignTestTodayList: [],
    getInsStudCompletedTestList: [],
    getUserDetailsInsStud: {},
    userNameInsStud: ""
  },
  reducers: {
    changeUserName: (state, action) => {
      state.userName = action.payload

    },
    resetHome: (state) => {
      return {
        ...state,
        isLoading: false,
        isLoadingProfile:false,
        getAllTestSeries: [],
        userName: "",
        getAllExam: [],
        getAllTest: [],
        getAllExamGroup: [],
        getUserDetails: {},
        getExamGroupBySlugId: [],
        getExamGroupById: {},
        breadCrumbData: {},
        getInsStudAssignTestList: [],
        getInsStudAssignTestTodayList: [],
        getInsStudCompletedTestList: [],
        getUserDetailsInsStud: {},
        userNameInsStud: ""
      };
    }
  },
  extraReducers: {
    [GetAllTestSeries.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetAllTestSeries.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getAllTestSeries = action.payload

    },
    [GetAllTestSeries.rejected]: (state, action) => {
      state.isLoading = false

    },
    [GetAllExam.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetAllExam.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getAllExam = action.payload

    },
    [GetAllExam.rejected]: (state, action) => {
      state.isLoading = false

    },
    [GetAllTest.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetAllTest.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getAllTest = action.payload

    },
    [GetAllTest.rejected]: (state, action) => {
      state.isLoading = false

    },
    [GetUserDetails.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetUserDetails.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getUserDetails = action.payload
      state.userName = action.payload.name

    },
    [GetUserDetails.rejected]: (state, action) => {
      state.isLoading = false
    },


    /* institution student details */
    [GetUserDetailsInstitution.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetUserDetailsInstitution.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getUserDetailsInsStud = action.payload
      state.userNameInsStud = action.payload?.name ? action.payload.name : ''

    },
    [GetUserDetailsInstitution.rejected]: (state, action) => {
      state.isLoading = false
    },
    [GetAllExamGroup.pending]: (state, action) => {
      state.isLoading = true

    },
    [GetAllExamGroup.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getAllExamGroup = action.payload

    },
    [GetAllExamGroup.rejected]: (state, action) => {
      state.isLoading = false
    },
    [GetExamGroupBySlugId.pending]: (state, action) => {
      state.isLoading = true
      state.getExamGroupBySlugId = []

    },
    [GetExamGroupBySlugId.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getExamGroupBySlugId = action.payload

    },
    [GetExamGroupBySlugId.rejected]: (state, action) => {
      state.isLoading = false
    },
    [GetExamGroupById.pending]: (state, action) => {
      state.isLoading = true
      state.getExamGroupById = {}

    },
    [GetExamGroupById.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getExamGroupById = action.payload

    },
    [GetExamGroupById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [addBreadCrumbData.pending]: (state, action) => {
      state.isLoading = true

    },
    [addBreadCrumbData.fulfilled]: (state, action) => {
      state.isLoading = false
      state.breadCrumbData = action.payload

    },
    [addBreadCrumbData.rejected]: (state, action) => {
      state.isLoading = false
    },

    /* institution student available test */
    [getInsStudAssignTest.pending]: (state, action) => {
      state.isLoading = true

    },
    [getInsStudAssignTest.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getInsStudAssignTestList = action.payload?.data
    },
    [getInsStudAssignTest.rejected]: (state, action) => {
      state.isLoading = false
    },


    /* institution student available test today */
    [getInsStudAssignTestToday.pending]: (state, action) => {
      state.isLoading = true

    },
    [getInsStudAssignTestToday.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getInsStudAssignTestTodayList = action.payload
    },
    [getInsStudAssignTestToday.rejected]: (state, action) => {
      state.isLoading = false
    },

    /* institution student completed test */
    [getInsStudCompletedTest.pending]: (state, action) => {
      state.isLoading = true

    },
    [getInsStudCompletedTest.fulfilled]: (state, action) => {
      state.isLoading = false
      state.getInsStudCompletedTestList = action?.payload?.data
    },
    [getInsStudCompletedTest.rejected]: (state, action) => {
      state.isLoading = false
    },
    //  updateInstituionProfile
    [updateInstituionProfile.pending]: (state) => {
        state.isLoadingProfile = true
      },
    [updateInstituionProfile.fulfilled]: (state, payload) => {
      state.isLoadingProfile = false
    },
    [updateInstituionProfile.rejected]: (state) => {
      state.isLoadingProfile = false
    }
  }
})

const initial = HomePage.getInitialState();



export const { changeUserName, resetHome } = HomePage.actions

export default HomePage.reducer
import React ,{useEffect,useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import '../styles/purchasedList.scss';
import { Image,Tabs } from 'antd';
import Purchased from '../assets/images/Purchased.svg';
import PurchasedSide from '../assets/images/purchasedSide.svg';
import PurchasedListComponent from '../Component/purchasedListComponent';
import {getMyPurchasedList} from '../Slices/PurchasedListSlice';
import {getTypeOfTest} from '../Slices/TestListSlice'
import Loading from '../Component/Loading';


const PurchaseTest = () => {

  const { TabPane } = Tabs;
  const dispatch=useDispatch();
  const {myPurchasedList,isPurchasedTestLoading,purchasedCount}=useSelector(((state)=>state.PurchasedList))
  const {typeofTest} =useSelector((state)=>state.TestList)
  const [list,setList]=useState([])
  const [type,setType]=useState([])
  const [count,setCount]=useState(0);
  const [key,setKey]=useState(0)
  // const [subjectLength,setSubjectLength]=useState(0)
  // const [chapterLength,setChapterLength]=useState(0)
  // const [allTestLength,setAllTestLength]=useState(0)
  useEffect(()=>{
    dispatch(getMyPurchasedList()).unwrap().then((res)=>{
      // setList(myPurchasedList)
      // res.purchasedList.map((item)=>{
      //   if(item.typeOfTestId==1){
      //     setSubjectLength(subjectLength+1)
      //   }
      //   if(item.typeOfTestId==2){
      //     setChapterLength(chapterLength+1)
      //   }
      //   if(item.typeOfTestId==3){
      //     setAllTestLength(allTestLength+1)
      //   }
      // })
    })
    // dispatch(getTypeOfTest())
  },[])

  useEffect(()=>{
    setList(myPurchasedList)
    setType(purchasedCount)
  },[myPurchasedList])

  const onChange = (key) => {
    var arr=[]
    setKey(key)
    myPurchasedList.map((item)=>{
      if(item.typeOfTestId==key || key==-1){
        arr.push(item)
      }
      else{
      }
    })
    setList(arr)
  };


var a=[]
  return (
    <div>
      {myPurchasedList.length==0 ? <div>
        <div className="purchased-list-empty">
          <div className="purchased-list-empty-header">My Purchased Test</div>
          <div className='purchased-list-empty-image'>
            <Image src={Purchased} preview={false}></Image>
            <p> You haven't any purchased test.</p>
          </div>
        </div>
      </div>
      :
      <div>
        <div className="purchased-list">
          <div className="purchased-list-header">
            <p>My Purchased Test</p>
            <Image src={PurchasedSide} preview={false}></Image>
          </div>
          <div style={{marginTop:20}} className="purchased-list-content">
          <Tabs defaultActiveKey="0" onChange={onChange}>
          {
            type.map((e)=>
            <TabPane tab={`${e.testType}(${e.numberOfTest})`} key={e.id}>
              <div className="purchased-list-empty"></div>
              { list.length ?
                list.map((item)=>
                <PurchasedListComponent   testName={item.testName} questions={item.numberOfQuestions} marks={item.totalMark} timeLimit={item.timeLimit} language="Tamil,English" alreadyTaken={false}></PurchasedListComponent>):
                <div className="purchased-list-empty">
                <div className='purchased-list-empty-image-list'>
                  <Image src={Purchased} preview={false}></Image>
                </div>
                <p style={{textAlign:'center'}}>You haven't any purchased test.</p>
              </div>
              }
            </TabPane> 
            )
          }
          {/* <TabPane tab={`All (${myPurchasedList.length})`} key="0">
            {list.length==0 ? 
            <div className='purchased-list-empty-tabs'>
              <Image src={Purchased} preview={false}></Image>
            </div> 
            : 
            <div>
               {
                list.map((item)=>
                <PurchasedListComponent   testName={item.testName} questions={item.numberOfQuestions} marks={item.totalMark} timeLimit={item.timeLimit} language="Tamil,English" alreadyTaken={false}></PurchasedListComponent>
                )
              }
            </div>}
          </TabPane> */}
          {/* <TabPane tab={`Full test (${allTestLength})`} key="1">
            {list.length==0 ? 
            <div className='purchased-list-empty-tabs'>
              <Image src={Purchased} preview={false}></Image>
            </div> 
            : 
            <div>
               {
                list.map((item)=>
                <PurchasedListComponent   testName={item.testName} questions={item.numberOfQuestions} marks={item.totalMark} timeLimit={item.timeLimit} language="Tamil,English" alreadyTaken={false}></PurchasedListComponent>
                )
              }
            </div>}
          </TabPane> */}
          {/* <TabPane tab={`Subject test (${subjectLength})`} key="2">
            {list.length==0 ? 
            <div className='purchased-list-empty-tabs'>
              <Image src={Purchased} preview={false}></Image>
            </div> 
            : 
            <div>
               {
                list.map((item)=>
                <PurchasedListComponent   testName={item.testName} questions={item.numberOfQuestions} marks={item.totalMark} timeLimit={item.timeLimit} language="Tamil,English" alreadyTaken={false}></PurchasedListComponent>
                )
              }
            </div>}
          </TabPane> */}
          {/* <TabPane tab={`Chapter test (${chapterLength})`} key="3">
            {list.length==0 ? 
            <div className='purchased-list-empty-tabs'>
              <Image src={Purchased} preview={false}></Image>
            </div> 
            : 
            <div>
               {
                list.map((item)=>
                <PurchasedListComponent   testName={item.testName} questions={item.numberOfQuestions} marks={item.totalMark} timeLimit={item.timeLimit} language="Tamil,English" alreadyTaken={false}></PurchasedListComponent>
                )
              }
            </div>}
          </TabPane> */}
          </Tabs>
          </div>
        </div>
      </div>
}
      {isPurchasedTestLoading==true ? <Loading></Loading> : null}
    </div>
  )
}

export default PurchaseTest
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../utlis/Auth'

const GuardTestPage = ({ children }) => {
  // const auth = useAuth()
  const { takeTest } = useSelector((state) => state.TestList)
  const navigate = useNavigate()

  useEffect(() => {
    if (!takeTest) {
      if (localStorage.getItem('institutionStudent') == true || localStorage.getItem('institutionStudent') == 'true') {
        return navigate('/institutionHome')
      } else {
        return navigate('/home')
      }
    }
  }, [])
  if (takeTest) {
    return children
  }
}

export default GuardTestPage
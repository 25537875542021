import { LoadingOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Row, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbDetails from '../Component/BreadCrumb'
import Loading from '../Component/Loading'
import TestSeriesHeader from '../Component/TestSeriesHeader'
import TestSeriesList from '../Component/TestSeriesList'
import { GetAllTestById, GetTestSeriesById } from '../Slices/TestSeriesSlice'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
 );

const TestSeriesPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const {isLoading,getAllTestById ,getTestSeriesById } = useSelector((state) => state.TestSeries)

  const takeTestPressed = (id) =>{
    navigate(`/home/testInstruction/${id}`)
  }


  useEffect(() => {
    dispatch(GetAllTestById(params?.id))
    dispatch(GetTestSeriesById(params?.id))
  }, [])
  // if(isLoading){
  //   return(
  //    <div className='loading'>
  //    <Spin tip="loading....." indicator={antIcon} >
  
  //    </Spin>
  //  </div>
  //   )
  //  }
  //  else{
  return (
    <>
    <div className='test-series-container'>
      <div className='breadcrumb-details'>
      <BreadCrumbDetails></BreadCrumbDetails>
        {/* <Breadcrumb separator=">" >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item href={location.state.routerLink}>{location.state.name}</Breadcrumb.Item>
         
        </Breadcrumb> */}
      </div>
      <div className='test-series-header'>
        <TestSeriesHeader data={getTestSeriesById} />
      </div>
      <div className='test-series-list'>
        <h2 className='list-header'>Test Series</h2>
        <div className='list-container'>
          <Row>
            {
              getAllTestById.length ? getAllTestById.map((e, index) => {
                return (

                  <Col span={12} ><TestSeriesList data={e} takeTestPress={takeTestPressed} /></Col>
                )

              })
                : null
            }


          </Row>

        </div>
      </div>
    </div>
    {
        isLoading ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }

    </>
  )
          // }
}

export default TestSeriesPage


{/* <Col span={12}><TestSeriesList status="FREE" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={1} mark={75} /></Col>
<Col span={12}><TestSeriesList status="FREE" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={3} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col>
<Col span={12}><TestSeriesList status="PAID" testName="Ilakku Group II/II A Test  1" totalQuestion={50} totalMins={60} type={2} mark={75} /></Col> */}
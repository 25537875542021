import { useNavigate } from 'react-router-dom';
import { Button, Divider, Image,Spin } from 'antd';
import { useSelector } from 'react-redux';
import collegeStudent from '../assets/images/collegeStudent.svg';
import LandingPageTestSeries from '../Component/landingPageTestSeries';
import '../styles/landingPage.scss';
import UpscImage from '../assets/images/upsc.svg';
import SbiImage from '../assets/images/sbi.svg';
import CTET from '../assets/images/ctet.svg';
import NDA from '../assets/images/upscNda.svg';
import Prelims from '../assets/images/upsePrelim.svg';
import SSC from '../assets/images/ssc.svg';
import Civil from '../assets/images/civilService.svg';
import Net from '../assets/images/net.svg';
import PlayStore from '../assets/images/playStore.svg'
import Learn from '../assets/images/bulb.svg';
import LiveTest from '../assets/images/liveTest.svg';
import Login from './login';
import SignUp from './signUp';
import EnterOtp from './otpPage';
import Password from './password';
import ForgotPassword from './forgotPassword';
import Navbar from '../Component/navBar';
import { LoadingOutlined } from '@ant-design/icons';
import Loading from '../Component/Loading';
import Footer from '../Component/Footer';

import { Carousel } from 'antd';

function LandingPage() {
  const navigate=useNavigate()
  const {isLoading}=useSelector((state)=>state.signUpSlice)
  const antIcon = (<LoadingOutlined style={{fontSize: 24,}}spin/>);

  const contentStyle = {
    height: '460px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  // if(isLoading){
  //   return(
  //     <div className='loading'>
  //       <Spin tip='Loading...' indicator={antIcon} />
  //     </div>
  //   )
  // }
  return (
    <>
    <Navbar></Navbar>
      <div style={{ background: '#E5E5E5' }}>
        {/* <div className='dynamic-carousel-container'>
          <div className='dynamic-carousel-wrapper'>
          <Carousel autoplay>
            <div>
              <div style={contentStyle}>1</div>
            </div>
            <div>
              <div style={contentStyle}>2</div>
            </div>
            <div>
              <div style={contentStyle}>3</div>
            </div>
            <div>
              <div style={contentStyle}>4</div>
            </div>
          </Carousel>

          </div>

        </div> */}
        <div className='landing-page' style={{ backgroundColor: '#F3F3F5' }}>
          <div className='image-content' style={{ width: '45%' }}>
            <p>One Destination for</p>
            <p>Complete Exam Preparation</p>
            <Button style={{ backgroundColor: '#07BD81', borderRadius: 4, color: 'white' }}>Start Learning</Button>
            <p>Start your preparation for selections. For Free!</p>
          </div>
          <div style={{ width: '75%', paddingTop: 40 }}>
            <Image src={collegeStudent} preview={false}></Image>
          </div>
        </div>
        <div className='popular-exam'>
          <div style={{ paddingTop: 34 }}>
            <p>Popular Exam</p>
            <p>Get exam-ready with concepts, questions and study notes as per the latest pattern</p>
          </div>
          <div className='cards'>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div className='exam-cards'>
                <span><Image src={UpscImage} preview={false}></Image></span>
                <span>UPSC</span>
              </div>
              <div className='exam-cards'>
                <span><Image src={SbiImage} preview={false}></Image></span>
                <span>SBI Clerk Mains</span>
              </div>
              <div className='exam-cards'>
                <span><Image src={CTET} preview={false}></Image></span>
                <span>CTET</span>
              </div>
              <div className='exam-cards'>
                <span><Image src={NDA} preview={false}></Image></span>
                <span>UPSC NDA</span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div className='exam-cards'>
                <span><Image height={41} src={Net} preview={false}></Image></span>
                <span>UPSC NDA</span>
              </div>
              <div className='exam-cards'>
                <span><Image src={Prelims} preview={false}></Image></span>
                <span>UPPSC PCSPrelims</span>
              </div>
              <div className='exam-cards'>
                <span><Image src={SSC} preview={false}></Image></span>
                <span>SSC CHSL Tier (2019)</span>
              </div>
              <div className='exam-cards'>
                <span><Image src={Civil} preview={false}></Image></span>
                <span>UPSC Civil Services</span>
              </div>
            </div>
          </div>
          <div className='card-footer'>
            <a>Explore all items</a>
          </div>
        </div>
        <h2 style={{ textAlign: 'center', fontWeight: 600, fontSize: 28, marginTop: 57, marginBottom: 51 }}>Popular Test Series</h2>
        <div className='overall-populat-test-series' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <LandingPageTestSeries header="All Banking & Insurance Previous Year Papers..." test1="207 TESTS | " test2="12 Free Tests" language="English, Tamil" p1="25 SBI Exams" p2="37 IBPS & RRB Exams" p3="17 Insurance Exams" p4="+128 more tests"></LandingPageTestSeries>
          <LandingPageTestSeries header="Railway Group D 2019 Test Series" test1="164 TESTS | " test2="15 Free Tests" language="English, Tamil" p1="41 Fukk Test - Prelims" p2="35 previous Papers" p3="53 Chapter Test" p4="+10 more tests"></LandingPageTestSeries>
          <LandingPageTestSeries header="UPSSSC PET 2022 Mock Test (New)" test1="183 TESTS | " test2="17 Free Tests" language="English, Tamil" p1="150 Chapter Test" p2="21 Full Test" p3="2 previous Year Papers" p4="+10 more tests"></LandingPageTestSeries>
          <LandingPageTestSeries header="UPSSSC PET 2022 Mock Test (New)" test1="183 TESTS | " test2="17 Free Tests" language="English, Tamil" p1="150 Chapter Test" p2="21 Full Test" p3="2 previous Year Papers" p4="+10 more tests"></LandingPageTestSeries>
        </div>
        <div className='test-series-footer'>
          <a>Explore all items</a>
        </div>

        <div className='banner'>
          <div className='banner-content'>
            <p>Start your learning journey now!</p>
            <div className='learn'>
              <p>Learn - Practice - Improve - Succeed</p>
            </div>
            <div style={{ display: 'flex' }}>
              <Button>Get Start</Button>
              <div className='play-store'>
                <Image src={PlayStore}></Image>
                <span>Download Now</span>
              </div>
            </div>
          </div>
        </div>
        <div className='banner-2'>
          <div className='why-centum'>
            <div className='why-centum-div' style={{ display: 'flex', paddingLeft: 36, paddingTop: 75 }}>
              <p style={{}}>Why</p>
              <p style={{}}>CentumApp</p>
            </div>
            <div className='why-centum-content'>
              <p>With India’s Hughest Selection </p>
              <p>Rate amongst online learning Platform, you</p>
              <p>can surely reply on us to excel</p>
              <Button style={{ backgroundColor: '#07BD81', borderRadius: 4, color: 'white' }}>Start Learning</Button>
            </div>

          </div>
          <div className='overall-cards' >
            <div className='small-cards'>
              <div className='cards' style={{ marginTop: 69 }}>
                <div>
                  <div className='card-image'><Image src={Learn} preview={false}></Image></div>
                </div>
                <div>
                  <p className='header'>Learn from the best</p>
                  <p className='content'>Learn from the masters of the subject, in the most engaging yet simplified ways</p>
                </div>
              </div>
              <div className='cards' style={{ marginLeft: 13, marginTop: 69 }}>
                <div>
                  <div className='card-image' ><Image src={LiveTest} preview={false}></Image></div>
                </div>
                <div>
                  <p className='header'>Live Test for Real Exam Experience</p>
                  <p className='content'>Feel the thrill of a real exam. improve your time & pressure management skiils.</p>
                </div>
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div className='small-cards'>
              <div className='cards'>
                <div>
                  <div className='card-image'><Image src={Learn} preview={false}></Image></div>
                </div>
                <div>
                  <p className='header'>Learn from the best</p>
                  <p className='content'>Learn from the masters of the subject, in the most engaging yet simplified ways</p>
                </div>
              </div>
              <div className='cards' style={{ marginLeft: 13 }}>
                <div>
                  <div className='card-image'><Image src={LiveTest} preview={false}></Image></div>
                </div>
                <div>
                  <p className='header'>Live Test for Real Exam Experience</p>
                  <p className='content'>Feel the thrill of a real exam. improve your time & pressure management skiils.</p>
                </div>
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div>
        <Footer></Footer>

        </div>

      </div>
      <div style={{display:'none'}}>
      <Login />
      <SignUp/>
      <Password/>
      <EnterOtp/>
      <ForgotPassword/>
      </div> 
      {
        isLoading ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }
          </>
  )
}

export default LandingPage;


import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Image } from 'antd'
import React from 'react'
import upscLogo from '../assets/images/upsc-logo-new.svg'

const NewExam = ({ examData, examLogo, examText }) => {

  const navigate = useNavigate()

  const location=useLocation()


    // take test 

    function takeTest(){
    //   navigate(`/home/examGroup/${examData.slugId}`)
      navigate('/home/testList/'+examData.id)
    }

 return (
  <div className='new-exam-container' key={examData?.id} onClick={()=>takeTest()}>
  <div className='exam-image'>
   <Image preview={false} src={examData?.examImage  }></Image>
  </div>
  <div className='exam-text'>

   <p className='exam-name'>{examData?.name}</p>
  </div>

  </div>
 )
}

export default NewExam
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Image, Row, Tabs } from 'antd';
import { getAttendedTest } from '../Slices/attendedSlice';
import AttemptedTestComponent from '../Component/attemptedTest';
import PurchasedSide from '../assets/images/purchasedSide.svg';
import Purchased from '../assets/images/Purchased.svg';
import Loading from '../Component/Loading'
import newEmptyData from '../assets/images/newEmptyData.svg'
import BreadCrumbDetails from '../Component/BreadCrumb';
import { addBreadCrumbData } from '../Slices/HomePageSlice';


const AttemptedTest = () => {
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const [list, setList] = useState([])
  const [typeValue, setTypeValue] = useState(1)


  const { getAllAttemptedList, attendedTypeOfTest, isAttendedTestLoading } = useSelector((state) => state.AttendedTest)
  const { isLoadingTestPage } = useSelector((state) => state.TestMaster)

  useEffect(()=>{
    dispatch(getAttendedTest()).unwrap().then((res) => {
    })
  },[])
  useEffect(() => {
        setList(getAllAttemptedList)
  }, [getAllAttemptedList])

  const onChange = (key) => {
    var arr = []
    setTypeValue(key)
    getAllAttemptedList.forEach((item) => {
      if (key == -1) {
        arr.push(item)
      } else if (item.typeOfTestId == key) {
        arr.push(item)
      }
    })
    setList(arr)
  };

  useEffect(() => {
    dispatch(addBreadCrumbData({ isAttempted: true }))

    dispatch(getAttendedTest()).unwrap().then((res) => {
    })
  }, [])

  return (
    <div className="overall-attempted-test">
      <div className='breadCrumbs-container'>
        <BreadCrumbDetails></BreadCrumbDetails>
      </div>
      <div className="attempted-test-header">
        <p>Attempted Tests</p>
        <Image src={PurchasedSide} preview={false}></Image>
      </div>
      {attendedTypeOfTest.length > 1 ?
        <Tabs defaultActiveKey="0" onChange={onChange}>
          {attendedTypeOfTest.map((e) =>
            <TabPane tab={`${e.testType} (${e.numberOfTest})`} key={`${e.id}`}>
              <div className="attented-test-wrapper">
                {list?.length ?
                  <Row>
                    {
                      list.map((item) => {
                        return (
                          <Col xs={24} xl={12} xxl={12}>
                            <AttemptedTestComponent item={item} type={typeValue} language="Tamil,English" />
                          </Col>
                        )
                      })
                    }
                  </Row>
                  :
                  null
                }
              </div>
            </TabPane>
          )}
        </Tabs>
        :
        <div className='empty-container'>
          <div className='empty-content'>
            <Image preview={false} src={newEmptyData}  ></Image>
            <p className='empty-text'>You haven't any Attempted test.</p>
          </div>

        </div>}

      {isAttendedTestLoading || isLoadingTestPage ? <Loading></Loading> : null}
    </div>
  )
}


export default AttemptedTest

import React, { useEffect, useState } from 'react'
import { Formik, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Drawer, Image, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { setPassword, passwordClose, institutionTokenVerification, setPasswordInsStud } from '../Slices/signUpSlice';
import * as yup from "yup";
import centumLogo from '../assets/images/Centum_App_logo.svg';
import '../styles/password.scss'
import '../styles/globalMediaQuery.scss';
import Loading from '../Component/Loading';

function Password() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const pathArr = location['pathname'].split('/');
  const antIcon = (<LoadingOutlined style={{ fontSize: 24, }} spin />);
  const { isLoading, passwordVisible } = useSelector((state) => state.signUpSlice)
  const [visible, setVisible] = useState();
  // const [passwordDetails,setPassword]=useState();
  // const {passwordVisible} = useSelector((state)=> state.signUpSlice)

  useEffect(() => {
    if (pathArr[1] == "institution" && pathArr[2] == "verifyingEmail") {
      dispatch(institutionTokenVerification({ token: params['token'] })).unwrap().then((res) => {
      })
    }
  }, []);

  function onClose() {
    setVisible(false);
    // formik.values.password='';
    // formik.errors.password='';
    // formik.touched.password=false
    // formik.values.confirmPassword='';
    // formik.errors.confirmPassword='';
    // formik.touched.confirmPassword=false;
    formik.resetForm()
  }

  function onSubmit(values) {
    dispatch(passwordClose())
    // let uuidToken=localStorage.getItem('uuidToken')
    values = {
      password: values.password,
      confirmPassword: values.confirmPassword,
      uuidToken: params.token
    }
    if (pathArr[1] == "institution" && pathArr[2] == "verifyingEmail") {
      dispatch(setPasswordInsStud(values)).unwrap().then((res) => {
        if (res.success == true) {
          localStorage.removeItem('uuidToken')
          navigate('/login')
        }
      })
    } else {
      dispatch(setPassword(values)).unwrap().then((res) => {
        if (res.success == true) {
          localStorage.removeItem('uuidToken')
          navigate('/login')
        }
      })
    }

    setVisible(false);
    // formik.values.password='';
    // formik.errors.password='';
    // formik.touched.password=false
    // formik.values.confirmPassword='';
    // formik.errors.confirmPassword='';
    // formik.touched.confirmPassword=false
    formik.resetForm()
  }

  const validatiomSchema = yup.object({
    password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters').oneOf([yup.ref("password"), null], "Password doesn't match")
  })

  const initialValues = { password: '', confirmPassword: '' }



  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validatiomSchema,
  })

  //   if(isLoading){
  //   return(
  //     <div className='loading'>
  //       <Spin tip='Loading...' indicator={antIcon} />
  //     </div>
  //   )
  // }

  return (
    <>
      <div>
        <div className='password-container user-onboarding-container'>
          <div className='passwordImage user-onboarding-image'></div>
          <div className='password-wrapper user-onboarding-wrapper'>
            <div className='password-content'>
              <div ><Image height={70} preview={false} src={centumLogo}></Image></div>
              <div>
                <h2 className='header'>Set Password</h2>
                <p className='sub-header'>set your unique password to your login</p>
              </div>
            </div>
            <div className='password-form'>
              <div className='form-wrapper'>
                <div className='form-control' >
                  <label>Password</label>
                  <Input.Password maxLength={15} id='password' name='password' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}></Input.Password>
                  {formik.touched.password && formik.errors.password ? <div className='error'>{formik.errors.password}</div> : null}
                </div>
                <div className='form-control' >
                  <label>Confirm Password</label>
                  <Input.Password maxLength={15} id='confirmPassword' name='confirmPassword' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmPassword}></Input.Password>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div className='error'>{formik.errors.confirmPassword}</div> : null}
                </div>
                <div className='form-control'>
                  <Button className='password-button' type='primary' disabled={!formik.dirty || !formik.isValid} onClick={() => onSubmit(formik.values)}>Set Password</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <div className='loading-container'>

        <Loading></Loading>

      </div> : null
      }

    </>
  )
}

export default Password;

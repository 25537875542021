import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Expand from '../assets/images/Expandright.svg';
import { Image, Button, Divider, Row, Col ,message } from 'antd';
import '../styles/solutions.scss';
import Language from '../assets/images/languages.svg';
import Question from '../assets/images/subtract.svg'
import Marks from '../assets/images/marks.svg';
import Time from '../assets/images/time.svg';
import attendedImage from '../assets/images/attended.svg';
import skippedImage from '../assets/images/skipped.svg';
import wrongImage from '../assets/images/wrong.svg';
import correctImage from '../assets/images/correct.svg';
import markImage from '../assets/images/mark.svg';
import rankImage from '../assets/images/rank.svg'
import { getAttemptedTestByTestMasterStudentMapId } from '../Slices/PurchasedListSlice';
import { getTestSolution,downloadQuestionAnswerInduvial } from '../Slices/attendedSlice';
import moment from 'moment';
import Loading from '../Component/Loading'
import { stopTakeTest } from '../Slices/TestListSlice';
import BreadCrumbDetails from '../Component/BreadCrumb';
import { addBreadCrumbData } from '../Slices/HomePageSlice';


function Solutions() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const params = useParams();
  const { testDetails, attendedTestDetails, isAttendedTestLoading } = useSelector((state) => state.AttendedTest)
  const { isPurchasedTestLoading } = useSelector((state) => state.PurchasedList)
  const [pdfData,setpdfData] = useState()
  const onClickViewSolution = () => {
    navigate('/viewSolution/' + params.id)

    // dispatch(getAttemptedTestByTestMasterStudentMapId(params.id)).unwrap().then((res)=>{
    //   navigate('/viewSolution/'+params.id)
    // })
  }
  useEffect(() => {
    dispatch(getTestSolution(params.id))
    dispatch(stopTakeTest())
    dispatch(addBreadCrumbData({ isAttempted: true, isSolution: true }))

  }, [])

  const onClickRankDetails =() => {
    navigate(`/home/rankDetailsPage/testId/${testDetails.testId}/type/1`)
  }

  const download = async () => {
    try {
      const response= await dispatch(downloadQuestionAnswerInduvial(params.id))
 if(response && response.payload){
  const buffer = response.payload.downloadPdf;

  function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const base64 = arrayBufferToBase64(buffer.data);

  const blob = new Blob([buffer], { type: 'application/pdf' });
  const pdfUrl = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = 'data:application/pdf;base64,' + base64;
  a.download = 'questions-answers.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  setTimeout(() => {
    isDownloaded();
  }, 5000);
 }else{
  console.error('Invalid response format:', response);
 }
    } catch (error) {
      console.error('Error in onClickDownloadSolution:', error);
    }
 
}

const isDownloaded = () => {
  setpdfData([])
}
  var startDate = moment(attendedTestDetails.isStartedDate)
  var endDate = moment(attendedTestDetails.isCompletedDate)
  var timeTaken = endDate.diff(startDate, 'minutes');
  return (
    <div className="overall-solutions">
      <div className='breadCrumbs-container'>
        <BreadCrumbDetails></BreadCrumbDetails>
        <div className='goLeader-btn-section'>
          <div className='goLeader-btn'>
            <Button onClick={()=> onClickRankDetails()}>Go To LeaderBoard</Button>
          </div>
        </div>
      </div>
      {/* <div className="path">
        <p onClick={()=>navigate('/home/attemptedTest')}>Attempted Test </p>
        <Image preview={false} src={Expand}></Image>
        <p>{testDetails.testName}</p>
      </div> */}
      <div className='solutions-component'>
        <div className='solutions-content'>
          <p className="exam-title"><span className={testDetails.typeOfTestId == 1 ? 'full-test-title' : (testDetails.typeOfTestId == 2 ? 'subject-title' : 'chapter-title')}>{testDetails.typeOfTestName}</span>
            <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider>

            <span> {testDetails.examName} </span></p>
          <p className='solutions-component-test'>{testDetails.testName}</p>
          <div className='solutions-component-questions'>
            {/* <Divider type='vertical' style={{background:'#7A8B94',marginTop:3}}></Divider> */}
            <p className='solutions-component-marks'><Image preview={false} src={Question}></Image> <span>{testDetails.numberOfQuestions} Questions</span></p>
            <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider>
            <p className='solutions-component-marks'><Image preview={false} src={Marks}></Image><span>{testDetails.totalMark} marks</span></p>
            <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider>
            <p className='solutions-component-marks'><Image preview={false} src={Time}></Image><span>{testDetails.timeLimit} Mins</span></p>
          </div>
          {/* <div style={{display:'flex',alignItems:'center'}}>
              <Image preview={false} src={Language}></Image>
              <p style={{paddingLeft:6,fontWeight: 500,fontSize:11,margin:0}}>language</p>
            </div> */}
        </div>
        <div className='solution-btn-section'>
          <div className='view-solutions'>
            <Button onClick={() => download()}>Download Questions & Answers</Button>
            <Button onClick={() => onClickViewSolution()}>See Answer</Button>
          </div>
        </div>
      </div>
      <div className="overall-quiz-details">
        <div className="quiz-header"><p className='header'>Test Summary</p></div>
        <div className="quiz-content">
          <Row>
            {/* <Col span={8}> */}
            <Col xs={16} sm={12} md={12} lg={6}>
              <div className="quiz-details-card">
                <div className="card-image"><Image preview={false} src={attendedImage}></Image></div>
                <div className="card-details">
                  <p className="card-header">Answered</p>
                  <p className="card-marks">{attendedTestDetails.attendedCount}<span>/{testDetails.numberOfQuestions}</span></p>
                </div>
              </div>
            </Col>
            {/* <Col span={8}> */}
            <Col xs={16} sm={12} md={12} lg={6}>
              <div className="quiz-details-card">
                <div className="card-image"><Image preview={false} src={skippedImage}></Image></div>
                <div className="card-details">
                  <p className="card-header">Unanswered</p>
                  <p className="card-marks">{attendedTestDetails.skippedCount}<span>/{testDetails.numberOfQuestions}</span></p>
                </div>
              </div>
            </Col>
            {/* <Col span={8}> */}
            <Col xs={16} sm={12} md={12} lg={6}>
              <div className="quiz-details-card">
                <div className="card-image"><Image preview={false} src={correctImage}></Image></div>
                <div className="card-details">
                  <p className="card-header">Correct</p>
                  <p className="card-marks">{attendedTestDetails.correctCount}<span>/{testDetails.numberOfQuestions}</span></p>
                </div>
              </div>
            </Col>
            {/* <Col span={8}> */}
            <Col xs={16} sm={12} md={12} lg={6}>
              <div className="quiz-details-card">
                <div className="card-image"><Image preview={false} src={wrongImage}></Image></div>
                <div className="card-details">
                  <p className="card-header">Incorrect</p>
                  <p className="card-marks">{attendedTestDetails.wrongCount}<span>/{testDetails.numberOfQuestions}</span></p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="rank-mark">
          <div className="time-details">
            <ul className='heading'>
              <li>Started on</li>
              <li>Completed on</li>
              <li>Test time limit</li>
              <li>Test taken time</li>
            </ul>
            <ul className='details'>
              <li>{moment(attendedTestDetails.isStartedDate).format('DD MMM YYYY')}  at {moment(attendedTestDetails.isStartedDate).format('LT')}</li>
              <li>{moment(attendedTestDetails.isCompletedDate).format('DD MMM YYYY')}  at {moment(attendedTestDetails.isCompletedDate).format('LT')}</li>
              <li>{testDetails.timeLimit} Mins</li>
              <li>{testDetails.timeLimit > timeTaken ? timeTaken + 'mins' : 'Exceed Maximum Time Limit'} </li>
            </ul>
          </div>
          {/* <div className="rank">
          <div className="rank-image"><Image preview={false} src={rankImage}></Image></div>
          <div className="rank-content">
            <p className="rank-heading">Rank</p>
            <p className="overall-rank"><span>11</span>/42</p>
          </div>
        </div> */}
          <div className="rank">
            <div className="rank-image"><Image preview={false} src={markImage}></Image></div>
            <div className="rank-content">
              <p className="rank-heading">Marks</p>
              <p className="overall-mark"><span>{testDetails.markObtained ? testDetails.markObtained : 0}</span>/{testDetails.totalMark}</p>
            </div>
          </div>
          <div className="mark"></div>
        </div>
      </div>
      {isAttendedTestLoading == true ? <Loading></Loading> : null}
      {isPurchasedTestLoading == true ? <Loading></Loading> : null}
    </div>
  )
}

export default Solutions;
import { Badge, Button, Col, Image, Radio, Row } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import logo from '../assets/images/Centum_App_logo.svg'
import Loading from '../Component/Loading'
import ViewQuestionSelection from '../Component/viewQuestionSelection'
import { getAttemptedTestByPreviousYearExam } from '../Slices/PurchasedListSlice'
import openArrow from '../assets/images/openArrow.svg'

const ViewSolutionPreviousYearExam = () => {
  const navigate = useNavigate()
  const params = useParams();
  const [currentIndexValue, setCurrentIndexValue] = useState(0)
  const [statusData, setStatusData] = useState(3)
  const [totalCount, setTotalCount] = useState({
    totalCorrectAnswer: 0,
    totalIncorrect: 0,
    totalNotAnswered: 0
  })
  const [showQuestionPanel, setShowQuestionPanel] = useState(false);
  const dispatch = useDispatch()
  const { attendQuestionList, attendTestDetails, isPurchasedTestLoading } = useSelector(((state) => state.PurchasedList))
  const [currentData, setCurrentData] = useState(attendQuestionList[currentIndexValue])

  useEffect(() => {
    dispatch(getAttemptedTestByPreviousYearExam(params.id)).unwrap().then((res) => {
      setCurrentData(res.questionData[0])
    })
  }, [])
  useEffect(() => {
    statusCount()
  }, [attendQuestionList])
  const onClickPrevious = () => {
    if (0 < currentIndexValue) {
      setCurrentIndexValue(currentIndexValue - 1)
      setCurrentData(attendQuestionList[currentIndexValue - 1])
    }
  }
  const onClickNext = async () => {
    if (attendQuestionList.length - 1 > currentIndexValue) {
      setCurrentIndexValue(currentIndexValue + 1)
      setCurrentData(attendQuestionList[currentIndexValue + 1])
    }
  }
  const statusFunction = () => {
    setStatusData(3)
    if (currentData?.option.filter(item => !item.value).length == currentData?.option.length) {
      setStatusData(3)
    }
    else if (currentData?.option.filter(item => item.value && item.id == currentData.correctAnswer).length) {
      setStatusData(1)
    }
    else if (currentData?.option.filter(item => item.value && item.id != currentData.correctAnswer).length) {
      setStatusData(2)
    }
  }

  const onIndexClick = (index) => {
    setCurrentData(attendQuestionList[index])
    setCurrentIndexValue(index)
  }

  const statusCount = () => {
    let totalCorrectAnswer = 0;
    let totalIncorrect = 0;
    let totalNotAnswered = 0;
    attendQuestionList.map((data, index) => {
      if (data.option.filter(item => !item.value).length == data.option.length) {
        totalNotAnswered++
      }
      else if (data.option.filter(item => item.value && item.id == data.correctAnswer).length) {
        totalCorrectAnswer++
      }
      else if (data.option.filter(item => item.value && item.id != data.correctAnswer).length) {
        totalIncorrect++
      }
    })
    let obj = {
      totalCorrectAnswer: totalCorrectAnswer,
      totalIncorrect: totalIncorrect,
      totalNotAnswered: totalNotAnswered
    }
    setTotalCount(obj)
  }

  const QuestionPanel = () => {
    setShowQuestionPanel(!showQuestionPanel)
  }


  useEffect(() => {
    statusFunction()
  }, [currentData])

  const back = () => {
    navigate(`/home/solutionsPreviousYearExam/${params.id}`)
  }

  return (
    <>
      {
        attendQuestionList.length ?
          <div className='viewSolution-container'>
            <div className='question-data-container'>
              <div className='question-data-body'>
                <Image preview={false} src={logo} onClick={() => navigate("/home")}></Image>
                <div className='question-details-header' >
                  <span className='question-number'> Question  {currentIndexValue + 1}
                    {
                      statusData == 2 ? <span className='incorrect'>INCORRECT</span> : statusData == 1 ?
                        <span className='correct'> CORRECT</span> : statusData === 3 ? <span className='unanswered'> Unanswered</span> : null
                    }
                  </span>
                </div>
                <div className='question-component-container'>
                  <ViewQuestionSelection currentData={currentData} statusData={statusData} currentIndex={currentIndexValue + 1} />
                </div>
                <div className='question-button'>
                  <div className='question-button-wrapper'>
                    <div className='back-button-container'>
                      <Button type='primary' className='outline-test-button' onClick={() => back()} >Back </Button>
                    </div>
                    <div className='question-change-button-container'>

                      <Button type='primary' className='outline-test-button' onClick={() => onClickPrevious()} >Previous</Button>
                      <Button type='primary' className='filled-test-button' onClick={() => onClickNext()} > Next</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={!showQuestionPanel ? 'question-paper-data' : 'question-paper-data show-panel'}>
              <div className='question-button-content'>
                <div className={!showQuestionPanel ? "mobile-btn-toggle" : "close-btn-toggle"} onClick={() => QuestionPanel()}>
                  <Image className={showQuestionPanel ? "closeArrow" : ''} preview={false} src={openArrow}></Image>
                </div>
                <h2 className='test-name'>{attendTestDetails.testName}</h2>
                <div className='question-pad-details'>
                  <p className='question-header'>Questions</p>
                  <p className='total-question'>{attendTestDetails.numberOfQuestions} questions</p>
                  <div className='marker-details'>
                    <span className='marker-data'> <Badge status="success" /> <span className='count-data'>{totalCount.totalCorrectAnswer} correct</span> </span>
                    <span className='marker-data'>  <Badge status="warning" /> <span className='count-data'> {totalCount.totalIncorrect}   incorrect</span></span>
                    <span className='marker-data'>  <Badge status="default" /> <span className='count-data'>{totalCount.totalNotAnswered}  unanswered</span> </span>
                  </div>
                  <div className='question-button-status'>
                    <Row>
                      {
                        attendQuestionList.length ?
                          attendQuestionList.map((data, index) => {
                            return (
                              data.option.filter(item => !item.value).length == data.option.length ?
                                <Col xs={6} md={8} lg={4}><Button type='primary' className='default-button' onClick={() => onIndexClick(index)} >{index + 1}</Button></Col>

                                : data.option.filter(item => item.id == data.correctAnswer && item.value).length ?
                                  <Col xs={6} md={8} lg={4}> <Button type='primary' className='correct-button' onClick={() => onIndexClick(index)} > {index + 1} </Button></Col>

                                  : data.option.filter(item => item.value && item.id != data.correctAnswer).length ?
                                    <Col xs={6} md={8} lg={4} > <Button type='primary' className='incorrect-button' onClick={() => onIndexClick(index)}  >{index + 1}</Button></Col>
                                    : null
                            )
                          })
                          :
                          null
                      }
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          null
      }

      {
        isPurchasedTestLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> : null
      }
    </>
  )
}

export default ViewSolutionPreviousYearExam
import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "antd";
import BreadCrumbDetails from "../Component/BreadCrumb";
import newEmptyData from "../assets/images/newEmptyData.svg";
import { useNavigate } from "react-router-dom";
import { getPreviousYearExam } from '../Slices/TestListSlice';
import { useDispatch, useSelector } from 'react-redux';

const PreviousYearExam = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { getPreviousYearExamCategoriy } = useSelector((state) => state.TestList)

  useEffect(() => {
    dispatch(getPreviousYearExam({})).unwrap().then((res) => {
    })
  }, [])

  const routeToPreviousTest = (categoryName) => {
    navigate(`/home/previousYearExamTest?categoryName=${categoryName}`);
  };

  return (
    <>
      <div className="breadCrumbs-container">
        <BreadCrumbDetails></BreadCrumbDetails>
      </div>
      <div className="attempted-test-header">
        <p>Previous Year Exam</p>
      </div>

      <div>
        <div className="previousyear-exam-container">
          <h2 className="exam-text">Categories</h2>
          <div className="exam-name-wrapper">
            <div className="body-container">
              <div className="body-wrapper">
                {getPreviousYearExamCategoriy?.length ? (
                  <Row>
                    <>
                      {getPreviousYearExamCategoriy.map((data, index) => {
                        return (
                          <Col
                            className="exam-card"
                            key={index}
                            onClick={() => routeToPreviousTest(data.categoryName)}
                          >
                            <p>{data.categoryName}</p>
                          </Col>
                        );
                      })}
                    </>
                  </Row>
                ) : (
                  <div className="empty-container">
                    <div className="empty-content">
                      <Image preview={false} src={newEmptyData}></Image>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousYearExam;

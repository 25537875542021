import React from 'react'
import {  Button, Image } from 'antd';
import PageNotFound from '../assets/images/404.svg';
import { useNavigate } from 'react-router-dom';
export const NoMatchRoute = () => {
  const navigate = useNavigate()
  return (
    <div className='pageNotFound-wrapper'>
    <div className="pageNotFound-container">
        
        <Image src={PageNotFound} preview={false}></Image>
        <div className='goToHome'>
        <Button onClick={()=>navigate('/home')}>Go to Home Page</Button>
        </div>
    </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../utlis/Auth'
import { useSelector} from 'react-redux'

const RequiredAuth = ({ children }) => {

  const {  testData } = useSelector((state) => state.TestMaster)
  
  const navigate = useNavigate()
  let token = localStorage.getItem('token') ? true : false;
  let student = localStorage.getItem('institutionStudent')

  useEffect(() => {
    if (!token) {
      navigate('/')
    } else {
      if ((!testData?.testMasterStudentMapId)) {
        if (student == true || student == 'true') {
          navigate('/institutionHome')
        } 
        // else {
        //   navigate('/home')
        // }
      }
    }
  }, [])
  return token ? children : null
}

export default RequiredAuth
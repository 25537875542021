import { Image } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import headerImage from '../assets/images/orderHeader.svg'
import { getOrderList } from '../Slices/PurchasedListSlice';
import Loading from '../Component/Loading'
import newEmptyData from '../assets/images/newEmptyData.svg'
import BreadCrumbDetails from '../Component/BreadCrumb'
import { addBreadCrumbData } from '../Slices/HomePageSlice'



const OrderPage = () => {
 const dispatch = useDispatch()
 const navigate = useNavigate();
 const { orderList ,isPurchasedTestLoading} = useSelector(((state) => state.PurchasedList))

 const singleOrderList = (paymentId) =>{
  navigate(''+paymentId)

 }
 useEffect(()=>{
  dispatch(getOrderList())
  dispatch(addBreadCrumbData({isOrder:true }))

 },[])
 return (
  <div className='order-page-container'>
    <div className='breadCrumbs-container'>
      <BreadCrumbDetails></BreadCrumbDetails>
    </div>
   <div className='header-container'>
    <div className='header-content'>
     <h2 className='header-text'>Ordered Tests</h2>
     <Image preview={false} src={headerImage}></Image>

    </div>
   </div>
   <div className='body-container'>
   {
    orderList.length ?
    orderList.map((data,index)=>{
     return(
      <div className='body-content' key={index+1} onClick={()=>singleOrderList(data.paymentId)}>
     <p className='orderText'>Order ID - {data.purchaseId}</p>
     <p className='orderMessage'>Rs. {data.amountPaid / 100 }, {data.paymentStatus == 'COMPLETED' ? <span className='success'>Your order is successful</span> : <span className='failed'>Your order is unsuccess</span>}</p>
     <p className='orderTime'>{ moment(data.paymentDate).format('DD MMM YYYY, hh:mm A')}</p>
    </div>
     )

    })
    :
    <div className='empty-container'>
    <div className='empty-content'>
     <Image preview={false} src={newEmptyData}  ></Image>
     {/* <p className='empty-text'>You haven't any Order test.</p> */}
    </div>

   </div>
   }


   </div>
   {isPurchasedTestLoading==true ? <Loading></Loading>: null}
  </div>
 )
}

export default OrderPage
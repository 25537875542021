import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Row ,Col, Input, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import BreadCrumbDetails from '../Component/BreadCrumb'
import Loading from '../Component/Loading'
import NewExam from '../Component/NewExam'
import NewExamGroup from '../Component/NewExamGroup'
import { addBreadCrumbData, GetAllExam, GetAllExamGroup, GetExamGroupById } from '../Slices/HomePageSlice'
import searchIcon from '../assets/images/Search_light.svg'
import newEmptyData from '../assets/images/newEmptyData.svg'

const { Search } = Input;


const ExamGroup = () => {
    const [filterData, setFilterData] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isLoading,getAllExamGroup } = useSelector((state) => state.HomePage)

  useEffect(() => {
    let obj = {
      "offset": 0
    }
    let examObj = {
        filterData:''
            }
    dispatch(GetAllExamGroup(examObj)).unwrap().then((res) => {
    })
    dispatch(addBreadCrumbData({isExam:true}))
  }, []);
  
  const onSearch = (data ) =>{
    setFilterData(data)
    let examObj = {
        filterData:data
            }
debounced(examObj)
  }

  const debounced = useDebouncedCallback((obj) =>{
    dispatch(GetAllExamGroup(obj))

  },400)


  return (
    <>
        <div className='get-all-exam-container'>
        <div className='get-all-exam-wrapper'>
            <div className='breadCrumbs-container'>
                <BreadCrumbDetails></BreadCrumbDetails>
            </div>
            <div className='header-container'>
                <div className='header-card'>
                    <p className='header-text'>Exams</p>
                    <div className='search'>
                        {/* <Input placeholder='Search Exams' /> */}
                        {/* <Search placeholder="Search Exams" onChange={onSearch} onSearch={onSearch} style={{ width: 200 }} /> */}
                        <Input size="large" placeholder="Search"  onChange={(e)=>onSearch(e.target.value)} suffix={<img src={searchIcon} alt="Search icon" />} />
                    </div>
                </div>
            </div>
            <div className='body-container'>
                <div className='body-wrapper'>
                {
              getAllExamGroup?.length ?
                    <Row>
              <>
                {
                  getAllExamGroup.map((data, index) => {
                    return (
                      <Col key={index} xs={12} sm={12} md={8} lg={6}>
                    <NewExamGroup examData={data} />
                    </Col>
                    )
                })
                }
                </>
               </Row>
              :
              <div className='empty-container'>
              <div className='empty-content'>
                <Image preview={false} src={newEmptyData}  ></Image>
                {/* <p className='empty-text'>You haven't any available test.</p> */}
              </div>
        
              </div>
          }
              
                    
                </div>

            </div>
        </div>

    </div>
    {
    isLoading ? <div className='loading-container'>
        
    <Loading></Loading>
        
    </div> : null
    }
    </>

  )
}

export default ExamGroup
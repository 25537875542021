import { SearchOutlined } from '@ant-design/icons'
import { Button, Image, Input, Radio } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import bannerLogo from '../assets/images/bannerLogo.svg'
import testBanner from '../assets/images/testbanner.svg'
import TestSeries from '../Component/TestSeries'

const AllTest = () => {
  const { isLoading ,getAllTest,getAllExam ,getAllTestSeries} = useSelector((state) => state.HomePage)

  const [filterValue ,setFilterValue] = useState('')
  const onChange = (event) =>{
    setFilterValue(event.target.value)
  }
  const onExamChange = (value)=>{

  }

  const testSeriesPressed =(id) =>{
  }


  return (
    <div className='test-all-container'>
      <h2 className='main-header'>Tests</h2>
      <div className='test-all-banner'>
        <div className='banner-container'>
          <div className='image-details'>
            <Image preview={false} src={bannerLogo}></Image>
            <p className='exam-details'>Get Unlimited Access to Test Series for 580+ Exams and much more.</p>
          </div>
          <div className='subscription-details'>
            <p className='amount'>₹399/month</p>
            <Button type='primary' className='test-series-button' >BUY NOW</Button>
          </div>
          <div className='banner-image'>
            <Image preview={false} src={testBanner}>

            </Image>

          </div>
        </div>
        <p className='test-content'>
          Explore 1000+  tests & analyse where you stand against your competitors with our AIR feature.
          Improve your selection rates & get a better chance at clearing your exam.
        </p>
      </div>
      <div className='test-all-category'>
      <div className='category-header'>
          <p className='header-text'>Test Series by Categories</p>
          <Input
              prefix={<SearchOutlined />}
              placeholder="Search all test series"
              onChange={(event) => onChange(event)}
              value={filterValue}
          />
      </div>
      <div className='category-content'>
      <div className='exam-category'>
      <Radio.Group onChange={(event)=>onExamChange(event.target.value)} defaultValue="">
                <Radio.Button value="">All</Radio.Button>
                {
                  getAllExam.length ? 
                    getAllExam.map((data,index)=>  
                <Radio.Button value={data.id}>{data.name}</Radio.Button>
                    
                    )
                  :null
                }
              </Radio.Group>
      </div>
      <div className='test-all-container'>
      {
                getAllTestSeries.length ?
                  getAllTestSeries.map((data, index) =>

                    <TestSeries data={data}    seriesPress={testSeriesPressed} />

                  )
                  : null
              }
      </div>
        
      </div>
      </div>

    </div>
  )
}

export default AllTest
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Image, Tabs } from 'antd';
import TNPSC from '../assets/images/tnpsc.svg';
import Expand from '../assets/images/Expandright.svg';
import Divider from '../assets/images/divider.svg';
import Language from '../assets/images/languages.svg'
import '../styles/takeTest.scss';
import TakeTestComponent from '../Component/takeTest';
import { getSubjectExamById, getTestById, getTypeOfTest, getTestBySubject, getExamById, getCategoryDetailsList } from '../Slices/TestListSlice';
import Loading from '../Component/Loading';
import EmptyImage from '../assets/images/Purchased.svg'
import newEmptyData from '../assets/images/newEmptyData.svg'
import BreadCrumbDetails from '../Component/BreadCrumb';
import { Tooltip } from 'antd';
import CategoryList from '../Component/categoryList';

const { TabPane } = Tabs;



function TakeTest() {


  const navigate = useNavigate()
  const params = useParams();
  const dispatch = useDispatch();
  const { typeofTest, isTestListLoading, testList, subjects, exam, categoryListData } = useSelector((state) => state.TestList)
  const [chapterActive, setChapterActive] = useState(0);
  const [testType, setTestType] = useState();
  const [examImage, setExamImage] = useState();
  const [testLength, setTestLength] = useState();
  const [activeSubject, setActiveSubject] = useState();

  useEffect(() => {
    dispatch(getExamById(params.id))
    dispatch(getSubjectExamById(params.id))
    dispatch(getCategoryDetailsList(params.id))
  }, [])


  const resetArr = () => {
    let orderWiseTest = []

    if (testList.length) {
      for (const element of testList) {
        let obj = element
        if (element.pricingPlan == 1) {
          orderWiseTest.push(obj)
        }
        else {
          orderWiseTest.unshift(obj)
        }
      }
    }
    return orderWiseTest
  }
  useEffect(() => {
    dispatch(getTypeOfTest(params.id)).unwrap().then((res) => {
      if (res.typeofTest.length != 0) {
        let data = {
          examId: params.id,
          typeOfTestId: res.typeofTest[0].id,
        }
        dispatch(getTestById(data))
      }
    })

  }, [])

  const onChange = (key) => {
    setTestType(key);
    setChapterActive(-1)
    let data = {
      examId: params.id,
      typeOfTestId: key,
    }
    dispatch(getTestById(data)).unwrap().then((res) => {

    })
  };
  function getTestBySubjects(subjectDetails) {
    setChapterActive(subjectDetails.id)
    let testBysubjectData = {
      examId: params.id,
      typeOfTestId: testType,
      subjectId: subjectDetails.id
    }
    dispatch(getTestBySubject(testBysubjectData))
  }

  return (
    <div className="take-test">
      <BreadCrumbDetails></BreadCrumbDetails>
      <div className="take-test-header">
        <Image preview={false} src={exam.examImage}></Image>
        <p className='examName'>{exam.examName}</p>
      </div>
      <Tabs onChange={onChange}>
        {
          typeofTest.map((e) =>
            <TabPane tab={`${e.testType} ${e.id != 4 ? `(${e.numberOfTest})` : `(${categoryListData?.length})`}`} key={e.id}>

              <div className="chapter-wrapper">
                {
                  e.id != 4 ? <div>
                    {Number(e.numberOfTest) && e.id != 1 && subjects.length > 1 ?
                      <ul>
                        {subjects.map((item, i) =>
                          <Tooltip key={i} placement="topLeft" title={item.name}>
                            <li className={chapterActive == item.id ? 'chapter-active' : null} onClick={() => getTestBySubjects(item)}>{item.name}
                            </li></Tooltip>)}
                      </ul>
                      : ''}
                  </div> : ''
                }
                <div>
                  {
                    e.id != 4 ? <>
                      {testList.length ? resetArr().map((item, i) => <TakeTestComponent key={i} listData={item} />) :
                        <div className='empty-container'>
                          <div className='empty-content'>
                            <Image preview={false} src={newEmptyData}  ></Image>
                          </div>
                        </div>
                      }
                    </> : <>
                      {categoryListData?.length ? categoryListData.map((item, i) => <CategoryList key={item.id} listData={item} />) : <></>}
                    </>
                  }
                </div>
              </div>
            </TabPane>
          )
        }
      </Tabs>
      {isTestListLoading == true ? <Loading></Loading> : null}
    </div>
  )
}

export default TakeTest;
import React, { useState, useEffect } from 'react'
import { Button, Col, Drawer, Form, Image, Input, Radio, Row, Upload, message, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import ChangePasswordIcon from '../assets/images/change_password.svg';
import RateIcon from '../assets/images/rate.svg';
import TermsIcon from '../assets/images/terms_condition.svg';
import PrivacyIcon from '../assets/images/privacy_policy_1.svg';
import SignOutIcon from '../assets/images/sign_out_1.svg';
import { GetUserDetails, resetHome,GetUserDetailsInstitution ,updateInstituionProfile} from '../Slices/HomePageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import Loading from '../Component/Loading';
const InstituionProfile = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const [visibleProfile, setProfile] = useState(false)
    const { getUserDetailsInsStud, userNameInsStud ,isLoadingProfile} = useSelector((state) => state.HomePage)
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);

    const [paginationData, setPaginationData] = useState(0);
    const [filterData, setFilterData] = useState("");
    const [limit, setLimit] = useState(50);
    const init = {
        name: '',
        email: '',
        mobileNumber: '',
        batchCode: '',
        logo: []
    }
    const [initialValues, setInitialValues] = useState(init)
    const [file, setFile] = useState([]);
    const [currentTitle, setCurrentTitle] = useState("Update Profile")
    const [profileImage, setProfileImage] = useState(null)

    const validationSchema = yup.object({
        name: yup.string().required("Name is Required"),
        email: yup.string().email('Invalid Email Id').required("Email is Required").nullable(),
        mobileNumber: yup.string().matches(/^[0-9\b]+$/, "Please Don't Enter Any Special Character").required("Mobile Number is Required").min(10, 'Mobile Number Must Be Exactly 10').max(10, 'Mobile Number Must Be Exactly 10').nullable(),
        batchCode: yup.string().required("BatchCode is Required").nullable(),
        logo: yup.array().min(1, "Image is Required").required("Image is Required").nullable()
    })

    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    const showUpdateConfirmation = () => {
        setProfile(false)
        setUpdateModalVisible(true);
    };

    const onUpdate = () => {
        try {
            setUpdateModalVisible(false)
            let data = new FormData();
            data.append("name", formik.values.name);
            data.append("logo", formik.values.logo[0].originFileObj); 
            data.append("mobileNumber", formik.values.mobileNumber);
            data.append("batchCode", formik.values.batchCode);
            data.append("email", formik.values.email);
            let payload = {
                id: getUserDetailsInsStud?.id,
                data,
                obj: {
                    pagination: paginationData,
                    searchValue: filterData,
                    limit: limit,
                },
            };
    
            dispatch(updateInstituionProfile(payload))
                .unwrap()
                .then((res) => {
                    dispatch(GetUserDetailsInstitution());
                    setProfile(false);
                })
                .catch((error) => {
                    console.error("Update failed:", error);
                });
        } catch (error) {
            console.error("Error in onUpdate:", error);
        }
    };
    
    const onSubmit = (values) => {
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })


    const filehandleChange = (file) => {

        if (file.fileList.length) {
            if (file.file.type == 'image/jpeg' || file.file.type == 'image/jpg' || file.file.type == 'image/png') {
                setFile(file.fileList)
                formik.values.logo = file.fileList
            }
            else {
                message.error("please upload jpeg, jpg and png formats only ")
            }
        }
        else {
            setFile(file.fileList)
            formik.values.logo = file.fileList
            formik.validateForm()
        }
    }

    const logout = () => {
        setVisible(true)
    }



    function handleOk() {
        localStorage.clear();
        dispatch(resetHome())
        navigate('/')
    }
    function handleCancel() {
        setVisible(false)
    }

    function openReset() {

    }

    const drawerCloser = () => {
        setVisible(false)
    }


    const editProfile = () => {
        dispatch(GetUserDetailsInstitution()).unwrap().then((res) => {
            if (res) {
                if (formik.values) {
                    setFile([
                        {
                            url: res?.image,
                            logo: res?.image,
                        },
                    ]);
                    formik.values.name = res.name;
                    formik.values.mobileNumber = res.mobileNumber;
                    formik.values.logo = res.image;
                    formik.values.email = res.email;
                    formik.values.batchCode = res.batchCode;
                }
                setProfile(true);
            }
        })
        setProfile(true);
        setCurrentTitle("Edit Profile");
    }




    return (
        <>
            {
             isLoadingProfile ? 
            (
                <div className="loading-container">
                    <Loading></Loading>
                </div>
            ) :
                <div className='profile-container'>
                    <div className='random-header'>
                        <div className='user-container'>
                            <div className='userProfile'>
                                {
                                    getUserDetailsInsStud?.image ? 
                                        <Image className='user-profile-icon' preview={false} src={getUserDetailsInsStud?.image}></Image>
                                    :
                                    <div className='firstName' >
                                        {userNameInsStud?.substring(0, 2)}
                                    </div>

                                }

                            </div>
                            <div className='user-details'>
                                <p className='user-name'>{userNameInsStud} </p>
                                <p className='tag'>Hope test was great today!</p>
                            </div>
                        </div>
                        <div>
                            <Button type='default' className='primary-submit-button' onClick={() => editProfile()} >Edit Profile</Button>
                        </div>



                    </div>

                    <div className="quiz-content">
                        <Row>
                            <Col xs={16} sm={12} md={12} lg={6}>
                                <div className="quiz-details-card" onClick={() => window.open("https://play.google.com/store/apps/details?id=academy.centum")}>
                                    <div className="card-image"><Image preview={false} src={RateIcon}></Image></div>
                                    <div className="card-details">
                                        <p className="card-header">Rate the app</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={16} sm={12} md={12} lg={6}>
                                <div className="quiz-details-card" onClick={() => window.open("https://centum.academy/privacy-policy.html")}>
                                    <div className="card-image"><Image preview={false} src={TermsIcon}></Image></div>
                                    <div className="card-details">
                                        <p className="card-header">Terms and Conditions</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={16} sm={12} md={12} lg={6}>
                                <div className="quiz-details-card" onClick={() => window.open("https://centum.academy/privacy-policy.html")}>
                                    <div className="card-image"><Image preview={false} src={PrivacyIcon}></Image></div>
                                    <div className="card-details">
                                        <p className="card-header">Privacy Policy</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={16} sm={12} md={12} lg={6}>
                                <div className="quiz-details-card" onClick={() => logout()}>
                                    <div className="card-image"><Image preview={false} src={SignOutIcon}></Image></div>
                                    <div className="card-details">
                                        <p className="card-header">Sign out</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='personal-details-wrapper' style={{ height: '100%' }}>
                        <div className='personal-details'>
                            <div style={{ borderBottom: '1px solid #E0E0E0' }}> <p>Personal Details</p> </div>
                            <div>
                                <Row>
                                    <Col className='personal-content' span={6}>Name</Col>
                                    <Col className='personal-answer' style={{ textTransform: 'capitalize' }} span={18}>{userNameInsStud ? userNameInsStud : '-'}</Col>
                                    <Col className='personal-content' span={6}>Email</Col>
                                    <Col className='personal-answer' span={18}>{getUserDetailsInsStud.email ? getUserDetailsInsStud.email : '-'}</Col>
                                    <Col className='personal-content' span={6}>BatchCode</Col>
                                    <Col className='personal-answer' span={18}>{getUserDetailsInsStud.batchCode ? getUserDetailsInsStud.batchCode : '-'}</Col>
                                    <Col className='personal-content' span={6}>Mobile</Col>
                                    <Col className='personal-answer' span={18}>{getUserDetailsInsStud.mobileNumber ? getUserDetailsInsStud.mobileNumber : '-'}</Col>
                                </Row>
                            </div>
                        </div>
                    </div>


                    <Modal title="Logout Confirmation" visible={visible} onOk={handleOk} onCancel={handleCancel}>
                        <p>Are you sure to logout?</p>
                    </Modal>

                    {/* update model */}
                    <Modal title="Update Confirmation" 
                        visible={isUpdateModalVisible} 
                        onOk={onUpdate} 
                        onCancel={closeUpdateModal}>
                        <p>Are you sure you want to update?</p>
                    </Modal>

                    <Drawer visible={visibleProfile} width="518px" onClose={() => setProfile(false)} title={currentTitle} closable={false}
                        className='profile-drawer' footer={(<div className='footer-profile'>
                            <div className='footer-button'>
                                <Button className='cancel-Button primary-cancel-button' type='primary' onClick={drawerCloser} >Cancel</Button>
                                <Button className='primary-submit-button' type="primary" onClick={showUpdateConfirmation} >Update </Button>
                            </div>
                        </div>)}>

                        <div className='profile-form-wrapper'>
                            <Formik validateOnChange={false}
                                validateOnBlur={false}>
                                <Form>
                                    <div style={{ marginTop: '20px' }}>
                                        <div className='exam-upload-file'>
                                            <label htmlFor="profileImage">
                                                Profile Image
                                            </label>
                                            <div className='form-control'>
                                                <Upload
                                                    className='profileImage'
                                                    listType="picture"
                                                    onChange={(file) => filehandleChange(file)}
                                                    beforeUpload={() => false}
                                                    fileList={file}
                                                    maxCount={1}
                                                >
                                                    <Button className='exam-image-upload-button' name="image" onChange={formik.handleChange} onBlur={formik.handleBlur} icon={<UploadOutlined />}>Choose file</Button>
                                                </Upload>
                                                {formik.touched.logo && formik.errors.logo ? <div className='error' >{formik.errors.logo}</div> : null}
                                            </div>
                                        </div>
                                        <div className='form-control'>
                                            <label htmlFor='name'>Name<span style={{ color: 'red' }}>*</span></label>
                                            <Input type='text' name='name' placeholder='Name' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.name}></Input>
                                            {formik.touched.name && formik.errors.name ? <div className='error' style={{ color: 'red' }}>{formik.errors.name}</div> : null}
                                        </div>
                                        <div className='form-control'>
                                            <label htmlFor='email'>Email<span style={{ color: 'red' }}>*</span></label>
                                            <Input type='email' name='email' placeholder='Email' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} disabled></Input>
                                            {formik.touched.email && formik.errors.email ? <div className='error' style={{ color: 'red' }}>{formik.errors.email}</div> : null}
                                        </div>
                                        <div className='form-control'>
                                            <label htmlFor='mobileNumber'>Mobile Number</label>
                                            <Input maxLength={10} type='number' id='mobileNumber' name='mobileNumber' placeholder='Mobile Number' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mobileNumber} disabled></Input>
                                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='error' style={{ color: 'red' }}>{formik.errors.mobileNumber}</div> : null}
                                        </div>

                                        <div className='form-control'>
                                            <label htmlFor='batchCode'>Batch Code</label>
                                            <Input maxLength={10} type='text' id='batchCode' name='batchCode' placeholder='Batch Code' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.batchCode} disabled></Input>
                                            {formik.touched.batchCode && formik.errors.batchCode ? <div className='error' style={{ color: 'red' }}>{formik.errors.batchCode}</div> : null}
                                        </div>

                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </Drawer>

                </div>
            
            }
        </>
    )
}

export default InstituionProfile

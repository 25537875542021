import { createContext, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { resetHome } from "../Slices/HomePageSlice";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
const dispatch = useDispatch()
 const authToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

 const [token, setToken] = useState(authToken)
 // const [token ,setToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDEsIm1vYmlsZU51bWJlciI6IjgwOTk4ODIxNTI0IiwiaWF0IjoxNjU5NzU2NTUxLCJleHAiOjE2NjAzNjEzNTF9.GhUN5LZkFxX5WfyyavLAO4uC5GuSpqJMWRYsl-gDC5o")

 const login = (token) => {
  // setToken(token)
 }
 const logout = () => {
  localStorage.removeItem('token')
  setToken(null)
  dispatch(resetHome())
 }

 return (
  <AuthContext.Provider value={{ token, login, logout }}>
   {children}
  </AuthContext.Provider>
 )

}

export const useAuth = () => {
 return useContext(AuthContext)
}
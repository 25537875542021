import React, { useState, useEffect } from 'react';
import { Image, message } from 'antd';
import { useDispatch, useSelector, } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackIcon from "../assets/images/back_icon.svg";
import ClockIcon from '../assets/images/clock_icon.svg'
import TranslateIcon from "../assets/images/translate.svg";
import CalenderIcon from '../assets/images/calendar_icon.svg'
import RankFirst from '../assets/images/rank_first.svg'
import RankSecond from '../assets/images/rank_second.svg'
import RankThird from '../assets/images/rank_third.svg'
import TotalStudentIcon from '../assets/images/student_icon.svg'
import TopScoreIcon from '../assets/images/top_score_icon.svg'
import AbsentStudentIcon from '../assets/images/absent_student_icon.svg'
import FinishedStudentIcon from '../assets/images/finished_student_icon.svg'
import TableComponent from '../Table/TableComponent';
import PassedStudentIcon from '../assets/images/passed_student_icon.svg'
import FailedStudentIcon from '../assets/images/failed_student_icon.svg';
import MyRankIcon from "../assets/images/My_Rank.png";
import moment from 'moment';
import "../styles/rankDetails.scss";
import { getPreviousExamBatchTestRankDetails ,getNormalExamTestRankDetails} from "../Slices/TestListSlice";
import Loading from './Loading';


const RankdetailsInstituion = () => {

    const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { absentStudent, testDetails, totalStudentCount, firstThreeRank, otherRankAll, myStudDetails, getInstitutionBatchTestResult, isTestListLoading ,studentResultRank} = useSelector((state) => state.TestList);

  useEffect(() => {
    if (parseInt(params?.typeId) === 1) {
        dispatch(getNormalExamTestRankDetails({
            testId: params?.testId,
            assignId: params?.assignId
        }));
    } else if (parseInt(params?.typeId) === 2) {
        dispatch(getPreviousExamBatchTestRankDetails({
            testId: params?.testId,
            assignId: params?.assignId
        }));
    } else {
        message.error("Test Paper Id Not Found");
    }
}, [params?.typeId]);

  const backToTestDetailsPage = async () => {
    if (localStorage.getItem('institutionStudent') == true || localStorage.getItem('institutionStudent') == 'true') {
      navigate('/institutionHome/insStudTest')
    } else {
      navigate(`/home/insStudTest`)
    }
  }

  /* rank table */
  let tableHeight = 320
  const [limit, setLimit] = useState(50)
  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '10%'
    },
    {
      title: 'Name of the Student ',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Test taken time',
      dataIndex: 'takeTime',
      key: 'takeTime',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    }
  ]

    return (
        <>
          <div className='batch-test-result-list'>
            <div className='heading-back-icon'>
              <div className='image'>
                <Image onClick={() => backToTestDetailsPage()} preview={false} src={BackIcon}></Image>
                <p className='test-name'>{testDetails.examName}</p>
              </div>
            </div>
            <div className='batch-test-result-content'>
              <div className='test-content'>
                {/* <p className={getInstitutionBatchTestMark.examGroupName ? 'exam-group-name' : ''}>
                    {getInstitutionBatchTestMark.examGroupName}
                  </p> */}
    
                <p className='test-name'>{testDetails?.examName}</p>
                <div className='divition-tag'></div>
                <div className='test-details'>
                  <div className='mark-language'>
                    <p className='total-student'>{testDetails?.numberOfQuestions} Questions</p>
                    <div className='icon-section'>
                      <div className='image'>
                        <Image preview={false} src={TranslateIcon}></Image>
                      </div>
                      <p className='tamil-english'>English,Tamil</p>
                    </div>
                  </div>
                  <div className='clock-calender'>
                    <div className='number-of-minutes icon-section'>
                      <div className='image'>
                        <Image preview={false} src={ClockIcon}></Image>
                      </div>
                      <p className='minutes'>{testDetails?.timeLimit} Mins</p>
                    </div>
                    <div className='test-date icon-section'>
                      <div className='image'>
                        <Image preview={false} src={CalenderIcon}></Image>
                      </div>
                      <p className='date'>{moment(testDetails?.isStartedDate).format('DD MMM YYYY')}, {testDetails?.startTime}</p>
                    </div>
                  </div>
                  <div className='mark-section'>
                    <p className='marks'>{myStudDetails?.totalMark}</p>
                    <p className='marks-label'>Total marks</p>
                  </div>
                </div>
              </div>
              <div className='mark-content'>
                <div className='section-1'>
                  <div className='mark-card'>
                    <div className='image'>
                      <Image preview={false} src={TotalStudentIcon}></Image>
                    </div>
                    <div className='text-section'>
                      <p className='label'>Total students</p>
                      <p className='value'>{getInstitutionBatchTestResult}</p>
                    </div>
                  </div>
                  <div className='mark-card'>
                    <div className='image'>
                      <Image preview={false} src={TopScoreIcon}></Image>
                    </div>
                    <div className='text-section'>
                      <p className='label'>Top score</p>
                      <p className='value'>{myStudDetails?.totalMark}</p>
                    </div>
                  </div>
                  <div className='mark-card'>
                    <div className='image'>
                      <Image preview={false} src={AbsentStudentIcon}></Image>
                    </div>
                    <div className='text-section'>
                      <p className='label'>Absent students</p>
                      <p className='value'>{absentStudent}</p>
                    </div>
                  </div>
                </div>
                <div className='section-2'>
                  <div className='mark-card'>
                    <div className='image'>
                      <Image preview={false} src={FinishedStudentIcon}></Image>
                    </div>
                    <div className='text-section'>
                      <p className='label'>Finished students</p>
                      <p className='value'>{totalStudentCount?.finishedStudentCount}</p>
                    </div>
    
                  </div>
                  <div className='mark-card'>
                    <div className='image'>
                      <Image preview={false} src={PassedStudentIcon}></Image>
                    </div>
                    <div className='text-section'>
                      <p className='label'>Pass Count</p>
                      <p className='value'>{totalStudentCount?.passMarkCount}</p>
                    </div>
                  </div>
                  <div className='mark-card'>
                    <div className='image'>
                      <Image preview={false} src={FailedStudentIcon}></Image>
                    </div>
                    <div className='text-section'>
                      <p className='label'>Fail Count</p>
                      <p className='value'>{totalStudentCount?.failCount}</p>
                    </div>
                  </div>
    
                </div>
              </div>
            </div>
            <div className='Leader-board-content'>
              <p className='heading'>My Rank</p>
              <div className='my-rank-card'>
                <div className='rank-card my-rank'>
                  <div className='image'>
                    <Image preview={false} src={MyRankIcon}></Image>
                  </div>
                  <div className='name-mark'>
                    <p className='name'>{myStudDetails?.studentName}</p>
                    {
                      studentResultRank
                        .filter(e => e.id === myStudDetails.id)
                        .map((student, index) => (
                          <p className='mark' key={index}>{student.studentRank}
                          <span>/ {totalStudentCount?.finishedStudentCount}</span>
                          
                          </p>
                        ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='Leader-board-content'>
              <p className='heading'>Leaderboard</p>
              {
                firstThreeRank.length <= 3 ?
                  <div className='first-three-rank'>
                    {
                      firstThreeRank.map((e, index) => {
                        return <div className={index == 0 ? 'rank-card first-rank' : (index == 1 ? 'rank-card second-rank' : 'rank-card third-rank')}>
                          <div className='image'>
                            <Image preview={false} src={index == 0 ? RankFirst : (index == 1 ? RankSecond : RankThird)}></Image>
                          </div>
                          <div className='name-mark'>
                            <p className='name'>{e.studentName}</p>
                            <p className='mark'>{e.score}<span>/ {testDetails?.totalMark}</span></p>
                          </div>
                        </div>
                      })
                    }
                  </div>
                  :
                  null
              }
    
              <div className='table-section-rank'>
                <TableComponent dataSource={otherRankAll} columns={columns} tableHeight={tableHeight} limit={limit} />
              </div>
            </div>
          </div>
          {
          isTestListLoading ? 
          <Loading></Loading> : null }
        </>
      )
}

export default RankdetailsInstituion

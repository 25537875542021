import { Divider, Image, Layout, Menu, Modal, Popover,Drawer } from 'antd'
import React, { useState } from 'react'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/images/centumIcon.svg'
import logoWithName from '../assets/images/Centum_App_logo.svg'
import cartLogo from '../assets/images/Cart.svg'
import { Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCart } from '../Slices/TestListSlice';
import { useEffect } from 'react';
import closeIcon from '../assets/images/close-icon.svg';
import hamburger from '../assets/images/hamburger-menu.png';
import logoutImage from '../assets/images/logout1.svg';
import { GetUserDetails, GetUserDetailsInstitution ,resetHome } from '../Slices/HomePageSlice';
import { getNotifications} from '../Slices/ProfileSlice';
import notificationLogo from '../assets/images/notifyIcon.svg';
import notificationIcon from '../assets/images/notification_icon.png'
import moment from 'moment'; 
import '../styles/notification.scss'
import { CloseOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';



const { Header, Content, Sider } = Layout;
const LayoutComponent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false);
  const { myCart } = useSelector((state) => state.TestList)
  const [visible, setVisible] = useState(false)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const notifications = useSelector(state => state.Profile);
  const [selectedKeys, setSelectedKeys] = useState([])
  const { getUserDetails, userName, userNameInsStud } = useSelector((state) => state.HomePage)
  const [profileNameInsStud, setProfileNameInsStud] = useState(() => {
    if (getUserDetails.hasOwnProperty('name')) {
      let name = (userNameInsStud).trim().charAt(0).toUpperCase()
    } else {
      return localStorage.getItem('name').trim().charAt(0).toUpperCase()
    }
  })
  const [profileName, setProfileName] = useState(() => {
    if (getUserDetails.hasOwnProperty('name')) {
      let name = (userName).trim().charAt(0).toUpperCase()
    } else {
      return localStorage.getItem('name').trim().charAt(0).toUpperCase()
    }
  })
  const [responsiveSidenavColapse, setResponsiveSidenavColapse] = useState(true)
  const logout = () => {
    setVisible(true)
  }
  const content = (
    <div className='logout-container' onClick={() => logout()} >
      <span className='svg-image'>
        <Image preview={false} src={logoutImage} height={22}></Image>
      </span>
      <span className="nav-text">Logout</span>
    </div>
  )

  function handleOk() {
    localStorage.clear();
    dispatch(resetHome())
    navigate('/')
  }
  function handleCancel() {
    setVisible(false)
  }

  useEffect(() => {
    if (!localStorage.getItem('institutionStudent')) {
      dispatch(getAllCart())
    }
  }, [myCart.length])

  useEffect(() => {
    setSelectedKeys([location.pathname])
  }, [location])

  useEffect(() => {
    setTimeout(() => {
      (localStorage.getItem('institutionStudent') == 'false') ?
        dispatch(GetUserDetails()).then((res) => {

          localStorage.setItem('name', res.payload.name)
          let name = (res.payload.name).trim().charAt(0).toUpperCase()
          setProfileName(name)
        })
        :
        dispatch(GetUserDetailsInstitution()).then((res) => {
          localStorage.setItem('name', res.payload?.name)
          let name = (res.payload?.name ? res.payload?.name : '').trim().charAt(0).toUpperCase()
          setProfileNameInsStud(name)
        })
    }, 1000);
  }, [])
  useEffect(() => {
    setProfileName(userName.trim().charAt(0).toUpperCase())
  }, [userName])

  useEffect(() => {
    setProfileNameInsStud(userNameInsStud.trim().charAt(0).toUpperCase())
  }, [userNameInsStud])

  useEffect(()=>{
    dispatch(getNotifications()).unwrap().then((res)=>{
    })
  },[])

  const groupedNotifications = notifications.notifications.reduce((acc, notification) => {
    const date = new Date(notification.createdAt).toDateString();
    if (!acc[date]) {
        acc[date] = [];
    }
    acc[date].push(notification);
    return acc;
}, {});

function getDateHeading(dateString) {
    const today = new Date();
    const date = new Date(dateString);

    if (date.toDateString() === today.toDateString()) {
        return 'Today';
    } else if (date.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString()) {
        return 'Yesterday';
    } else {
        return new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
    }
}

  const openDrawer = () => {
    setNotificationVisible(true);
    // dispatch(getNotifications())
   };

 const drawerCloser = () => {
     setNotificationVisible(false);
   };

   const menuItems = [
    {
      key: '/home',
      label: (
        <Link to="/home">
          <span className='svg-image'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12.0393C3 10.2937 3 9.42081 3.35288 8.65357C3.70576 7.88634 4.36847 7.3183 5.6939 6.18222L6.97961 5.08018C9.3753 3.02673 10.5731 2 12 2C13.4268 2 14.6247 3.02673 17.0204 5.08018L18.3061 6.18222C19.6315 7.3183 20.2942 7.88634 20.6471 8.65357C21 9.42081 21 10.2937 21 12.0393V17.4913C21 19.9156 21 21.1278 20.2468 21.881C19.4937 22.6341 18.2815 22.6341 15.8571 22.6341H8.14286C5.71849 22.6341 4.50631 22.6341 3.75315 21.881C3 21.1278 3 19.9156 3 17.4913V12.0393Z" stroke="currentColor" strokeWidth="1.5" />
              <path d="M15.2176 22.6342V15.9199C15.2176 15.3676 14.7699 14.9199 14.2176 14.9199H9.78906C9.23678 14.9199 8.78906 15.3676 8.78906 15.9199V22.6342" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          {!collapsed ? <span className="nav-text">Home</span> : ''}
        </Link>
      ),
    },
    {
      key: '/home/attemptedTest',
      label: (
        <Link to="/home/attemptedTest">
          <span className='svg-image'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </span>
          {!collapsed ? <span className="nav-text">Attempted Tests</span> : ''}
        </Link>
      ),
    },
    {
      key: '/home/availableTest',
      label: (
        <Link to="/home/availableTest">
          <span className='svg-image'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.8746 2H7.1246V2.75V3.37487H3.75H3V4.12487V20.6233C3 21.7968 3.95134 22.7481 5.12487 22.7481H18.8735C20.0471 22.7481 20.9984 21.7968 20.9984 20.6233V4.12487V3.37487H20.2484H16.8738V2.75V2H16.1238H7.8746ZM4.5 4.87487H7.1246V5.49973C7.1246 6.67327 8.07594 7.6246 9.24947 7.6246H14.7489C15.9225 7.6246 16.8738 6.67327 16.8738 5.49973V4.87487H19.4984V20.6233C19.4984 20.9684 19.2186 21.2481 18.8735 21.2481H5.12487C4.77976 21.2481 4.5 20.9684 4.5 20.6233V4.87487ZM8.6246 5.49973V3.5H15.3738V5.49973C15.3738 5.84484 15.094 6.1246 14.7489 6.1246H9.24947C8.90436 6.1246 8.6246 5.84484 8.6246 5.49973ZM6.50292 11.749H15.4396V10.249H6.50292V11.749ZM6.50292 17.2484H11.315V15.7484H6.50292V17.2484Z" fill="currentColor" />
            </svg>
          </span>
          {!collapsed ? <span className="nav-text">My Available Tests</span> : ''}
        </Link>
      ),
    },
    {
      key: '/home/orderPage',
      label: (
        <Link to="/home/orderPage">
          <span className='svg-image'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_6272_112898" fill="white">
                <rect x="3" y="13" width="8" height="8" rx="1" />
              </mask>
              <rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" strokeWidth="3" mask="url(#path-1-inside-1_6272_112898)" />
              <circle cx="17" cy="17" r="3.25" stroke="currentColor" strokeWidth="1.5" />
              <path d="M11.7835 3.875C11.8797 3.70833 12.1203 3.70833 12.2165 3.875L15.6806 9.875C15.7768 10.0417 15.6566 10.25 15.4641 10.25H8.5359C8.34345 10.25 8.22317 10.0417 8.31939 9.875L11.7835 3.875Z" stroke="currentColor" strokeWidth="1.5" />
            </svg>
          </span>
          {!collapsed ? <span className="nav-text">My Orders</span> : ''}
        </Link>
      ),
    },
    {
      key: '/home/profile',
      label: (
        <Link to="/home/profile">
          <span className='svg-image'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M11.9946 14.2583C8.70701 14.2583 6.02802 14.6824 3.98929 15.428C2.97031 15.8016 2.45984 16.5262 2.23003 17.1597C2.007 17.7707 2.00257 18.509 2.00257 19.25C2.00257 19.4569 2.00351 19.6629 2.00443 19.8676C2.00752 20.5684 2.01046 21.2584 2.01046 21.7525L2.01047 21.8749V21.9974C2.01047 22.2836 2.22533 22.4984 2.51149 22.4984C2.79764 22.4984 3.01251 22.2836 3.01251 21.9974V21.8749C3.01251 21.3705 3.00957 20.6813 3.00648 19.9812C3.00556 19.7771 3.00461 19.5724 3.00461 19.3659C3.00461 18.6462 3.007 18.016 3.16639 17.5805C3.31265 17.177 3.67097 16.7162 4.47909 16.4298C6.35642 15.7557 8.8789 15.3383 11.9946 15.3383C15.1102 15.3383 17.6327 15.7557 19.51 16.4298C20.3181 16.7162 20.6764 17.177 20.8227 17.5805C20.982 18.016 20.9844 18.6462 20.9844 19.3659C20.9844 19.5724 20.9835 19.7771 20.9825 19.9812C20.9794 20.6813 20.9765 21.3705 20.9765 21.8749V21.9974C20.9765 22.2836 21.1913 22.4984 21.4775 22.4984C21.7636 22.4984 21.9785 22.2836 21.9785 21.9974V21.8749C21.9785 21.2584 21.9815 20.5684 21.9846 19.8676C21.9855 19.6629 21.9864 19.4569 21.9864 19.25C21.9864 18.509 21.982 17.7707 21.759 17.1597C21.5292 16.5262 21.0187 15.8016 20 15.428C17.9613 14.6824 15.2823 14.2583 11.9946 14.2583Z" fill="currentColor" />
              <path fillRule="evenodd" clipRule="evenodd" d="M11.9947 3.00159C10.1355 3.00159 8.61963 4.51749 8.61963 6.3767C8.61963 8.2359 10.1355 9.75181 11.9947 9.75181C13.854 9.75181 15.3699 8.2359 15.3699 6.3767C15.3699 4.51749 13.854 3.00159 11.9947 3.00159ZM7.11963 6.3767C7.11963 3.93104 9.54901 1.50159 11.9947 1.50159C14.4403 1.50159 16.8698 3.93104 16.8698 6.3767C16.8698 8.82235 14.4403 11.2518 11.9947 11.2518C9.54901 11.2518 7.11963 8.82235 7.11963 6.3767Z" fill="currentColor" />
            </svg>
          </span>
          {!collapsed ? <span className="nav-text">My Profile</span> : ''}
        </Link>
      ),
    },
  ];


  const institutionMenuItems = [
  {
    key: '/institutionHome',
    label: (
      <Link to="/institutionHome">
        <span className='svg-image'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </span>
        <span className="nav-text">Home</span>
      </Link>
    ),
  },
  {
    key: '/institutionHome/instituionProfile',
    label: (
      <Link to="/institutionHome/instituionProfile">
        <span className='svg-image'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.8746 2H7.1246V2.75V3.37487H3.75H3V4.12487V20.6233C3 21.7968 3.95134 22.7481 5.12487 22.7481H18.8735C20.0471 22.7481 20.9984 21.7968 20.9984 20.6233V4.12487V3.37487H20.2484H16.8738V2.75V2H16.1238H7.8746ZM4.5 4.87487H7.1246V5.49973C7.1246 6.67327 8.07594 7.6246 9.24947 7.6246H14.7489C15.9225 7.6246 16.8738 6.67327 16.8738 5.49973V4.87487H19.4984V20.6233C19.4984 20.9684 19.2186 21.2481 18.8735 21.2481H5.12487C4.77976 21.2481 4.5 20.9684 4.5 20.6233V4.87487ZM8.6246 5.49973V3.5H15.3738V5.49973C15.3738 5.84484 15.094 6.1246 14.7489 6.1246H9.24947C8.90436 6.1246 8.6246 5.84484 8.6246 5.49973ZM6.50292 11.749H15.4396V10.249H6.50292V11.749ZM6.50292 17.2484H11.315V15.7484H6.50292V17.2484Z" fill="currentColor" />
          </svg>
        </span>
        <span className="nav-text">Profile</span>
      </Link>
    ),
  },
];

  

  return (
    <div>
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider width={220} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} className={`site-layout-slider ${responsiveSidenavColapse ? "responsive-nabar-collapse" : ""}`}
          style={{
            position: 'fixed',
            display: 'contents'
          }}
        >

          <div className="logo">
            <div className="close-icon" onClick={() => { setResponsiveSidenavColapse(true) }}>
              <Image preview={false} src={closeIcon} />
            </div>
            {collapsed ? <Image className='logo-without-text' preview={false} src={logo} /> : <Image preview={false} src={logoWithName} onClick={() => navigate("/home")} />}
          </div>
          {
            (localStorage.getItem('institutionStudent') == false || localStorage.getItem('institutionStudent') == 'false')
              ?
              <Menu theme="light" tabIndex={1} mode="inline" selectedKeys={selectedKeys} defaultSelectedKeys={[location.pathname]} items={menuItems}/>
              :
              <Menu theme="light" tabIndex={1} mode="inline" selectedKeys={selectedKeys} defaultSelectedKeys={[location.pathname]} items={institutionMenuItems}/>
          }
        </Sider>
        <Layout className="site-layout">
          <Header className="header-toolbar" style={{ padding: 0, background: '#fff' }} >
            <div className='logo-container'>
              <div className="left-header">
                <div className="hamburger" onClick={() => { setResponsiveSidenavColapse(false) }}>
                  <Image preview={false} className="hamburger-icon" src={hamburger} ></Image>
                </div>
                <Image preview={false} className="logo-header" src={logoWithName} ></Image>
              </div>
              <div className="right-header">
                {
                  (localStorage.getItem('institutionStudent') == true || localStorage.getItem('institutionStudent') == 'true') ? null :
                  <>
                  <Badge>
                      <Image src={notificationLogo} className="cart-icon" preview={false} onClick={() => openDrawer()}></Image>
                    </Badge>
                  <Badge>
                     <Image src={cartLogo} className="cart-icon" preview={false} onClick={() => navigate('/home/myCart')}></Image>
                  </Badge>
                  </>
                }
                <Divider type='vertical'></Divider>
                <Popover className='logoutHover' placement="bottomLeft" content={content} trigger="hover">
                  <div className='userProfile'>
                    <div className='firstName'>
                      {
                        (localStorage.getItem('institutionStudent') == false || localStorage.getItem('institutionStudent') == 'false') ? <span >{profileName}</span> : <span>{profileNameInsStud}</span>
                      }

                    </div>

                  </div>
                  {/* <Image preview={false} src={userLogo} className="user-logo" >institutionStudent</Image> */}

                </Popover>

              </div>
            </div>
          </Header>
          <Content
            style={{
              margin: '14px 10px',
            }}
          >

            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <Modal title="Logout Confirmation" visible={visible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure to logout?</p>
      </Modal>
      <>
         <Drawer
          title={"Notifications"}
          onClose={() => drawerCloser()}
          width="518px"
          closable={false}
          closeIcon={<CloseOutlined />}
          placement="right"
          visible={notificationVisible}
        >
                    <div className="notification-container">
            {/* <div className="notification-header">
                <p>Notifications</p>
            </div> */}
            <div className="body-container">
        {Object.entries(groupedNotifications).map(([date, notifications]) => (
                <div key={date}>
                    <p className='date'>{getDateHeading(date)}</p>
                    {notifications.map(notification => (
                        <div className="message-container" key={notification.id}>
                        <div className="message-header">
                            <div className="message-header-wrapper" >
                               <div className="notification-icon"> <Image preview={false} src={notificationIcon}></Image></div> 
                                <div className="message-title"> {notification.title}</div>
                            </div>
                            <div className='time'>{moment(notification.createdAt).format('HH:mm A')}</div>
                        </div>
                        
                            <div className="message-content">{notification.body}</div>
                                            
                        </div>
                    ))}
                </div>
            ))}
            </div>
            </div> 
        
        </Drawer>
        </>
    </div>
  )
}

export default LayoutComponent
import React, { useState, useEffect } from "react";
import { Divider, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { getPreviousYearTestByCategory } from '../Slices/TestListSlice';
import { useDispatch, useSelector } from 'react-redux';

const PreviousYearExamTest = () => {

  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [previousYearExamName, setPreviousYearExamName] = useState('')

  const queryParams = new URLSearchParams(location.search);

  const { previousYearTestByCategoryData } = useSelector((state) => state.TestList)


  useEffect(() => {
    const categoryName = queryParams.get('categoryName');
    setPreviousYearExamName(categoryName)
    dispatch(getPreviousYearTestByCategory({ name: categoryName })).unwrap().then((res) => {
    })
  }, []);

  function addToCartApi() {
    console.log("add to cart api ");
  };
  const takeTest = (id) => {
    navigate(`/home/previousYearTestInstruction/${id}`)
  };

  return (
    <>
      <div className="previous-year-exam-list">
        <div className="attempted-test-header">
          <p>{previousYearExamName}</p>
        </div>
        <div className="exam-list">
          <p className="exam-title">Exams :</p>
          <div className="exam-card-list">
            {previousYearTestByCategoryData.map((e, index) => {
              return (
                e.pricePlan != 1 ?
                  <div className="popular-test-cart">
                    <div className="content">
                      <p className="test-name">{e.examName}</p>
                      <div className="question-details">
                        <p className="popular-test-component-marks-solutions">
                          {e.numberOfQuestions} Questions
                        </p>
                        <Divider
                          style={{ background: "#7A8B94", marginTop: 3 }}
                          type="vertical"
                        ></Divider>
                        <p className="popular-test-component-marks-solutions">
                          {e.totalMark} Marks
                        </p>
                        <Divider
                          style={{ background: "#7A8B94", marginTop: 3 }}
                          type="vertical"
                        ></Divider>
                        <p className="popular-test-component-date-solutions">
                          {e.timeLimit} Mins
                        </p>
                      </div>
                    </div>

                    <div className="add-to-cart">
                      {e?.pricePlan == 1 ? (
                        <div>
                          <p className="test-total-price">₹{e.examPrice}</p>
                          <Button onClick={() => addToCartApi()}>
                            Add to Cart
                          </Button>
                        </div>
                      ) : (
                        <Button onClick={() => takeTest(e.id)}>Take Test</Button>
                      )}
                    </div>
                  </div>
                  : <></>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default PreviousYearExamTest;

import { Button, Image } from 'antd'
import React from 'react'
import languages from '../assets/images/languages.svg'
import subtract from '../assets/images/subtract.svg'
import totalQues from '../assets/images/totalQues.svg'
import totalHours from '../assets/images/total_hours.svg'

const TestSeriesHeader = ({data}) => {
 return (
  <div className='test-series-header-container'>
   <div className='series-details-container'>
    <div className='series-details'>
     <span className='series-header'>TEST SERIES</span>
     <p className='series-name'>{data.name}</p>
    </div>
    <div className='series-total'>
    <span className='test-total-languages-details'> <span className='total-test'><Image preview={false} src={subtract}></Image>119 Total Tests</span><span className='total-ques'><Image preview={false} src={totalQues}></Image>2400 Total Ques</span><span className='total-hours'><Image preview={false} src={totalHours}></Image> 23 hrs Test Duration</span> </span>
     {/* <span className='total'><Image preview={false} src={subtract}></Image> <span className='total-text'>{data?.numberOfTestCount} Total Test </span> </span> */}
     {/* <span className='test-total-languages-details'><span className='total-text'>{data?.numberOfQuestions} Qs.</span><span className='mark-text'>{data?.totalMark}  Marks </span><span className='languages-text'>{data?.timeLimit} mins </span></span> */}
     {/* <p className='languages'><Image preview={false} src={languages}></Image><span className='total-languages'> English, Tamil  tests </span></p> */}
    </div>
   </div>
   <div className='series-purchase'>
   {
    data?.pricingPlan == 1 ?
    <Button type="primary" className='primary-submit-button'>+ BUY NOW</Button>
    :null

   }
   </div>

  </div>
 )
}

export default TestSeriesHeader
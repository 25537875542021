import { Button, Divider, Image, message } from 'antd'
import React, { useEffect, useState } from 'react'
import subtract from '../assets/images/subtract.svg'
// import languages from '../assets/images/languages.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { institutionStudentTakeTest } from '../Slices/TestPageSlice'
import { GetTestByIdInsStud } from '../Slices/TestSeriesSlice'
import { LoadingOutlined } from '@ant-design/icons'
import Loading from '../Component/Loading'
import minsImage from '../assets/images/time.svg';
import marksImage from '../assets/images/marks.svg'
import { startTakeTest } from '../Slices/TestListSlice'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
  />
);


const TestInstructionInsStud = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(GetTestByIdInsStud({ testId: params?.id, assignId: params.assignId })).unwrap().then((res) => {
      if (!res) {
        navigate(`/home/insStudTest`)
        message.error(res.response.data.message)

      }
    })
  }, [])

  const { isLoading, getTestByIdInsStudent } = useSelector((state) => state.TestSeries)
  const { isLoadingTestPage } = useSelector((state) => state.TestMaster)

  const startTest = () => {
    dispatch(startTakeTest())
    let currentTestId = params?.id
    let obj = {
      currentTestId: params?.id,
      assignId: params.assignId
    }
    dispatch(institutionStudentTakeTest(obj)).unwrap().then((res) => {
      if (!res?.data?.status) {
        message.error(res?.message)
      }
      else if (res?.data?.status && res?.data?.questionData.length) {
        navigate(`/testPageInsStudent/${currentTestId}`)
      }
      else if (!res?.data?.questionData.length) {
        message.error("There is no question in the test" + res?.data?.questionData.length)
      }
      else {
        message.error(" Something went wrong")
      }
    })
  }

  return (
    <>
      <div className='test-instruction-container'>
        <div className='test-instruction-header'>
          <div className='test-instruction-header-container'>
            <div className='instruction-details-container'>
              <div className='instruction-details'>
                <p className="exam-title"><span className={getTestByIdInsStudent.typeOfTestId == 1 ? 'full-test-title' : (getTestByIdInsStudent.typeOfTestId == 2 ? 'subject-title' : 'chapter-title')}>{getTestByIdInsStudent.testTypeName}</span>
                  <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider>
                  <span>{getTestByIdInsStudent.examName} </span></p>
                <p className='instruction-name'>{getTestByIdInsStudent?.testName}</p>
              </div>
              <div className='instruction-total'>
                <span className='total'><Image preview={false} src={subtract}></Image> <span className='total-question'>{getTestByIdInsStudent?.numberOfQuestions} Questions </span> <Image preview={false} src={marksImage}></Image><span className='total-marks'>{getTestByIdInsStudent?.totalMark} marks </span><Image preview={false} src={minsImage}></Image><span className='total-mins'>{getTestByIdInsStudent?.timeLimit} mins </span> </span>
              </div>
            </div>
            <div className='instruction-purchase'>
              <Button type="primary" className='primary-submit-button' onClick={() => startTest()}>Start test</Button>
            </div>

          </div>
        </div>
        <div className='test-instruction-Details'>
          <h2 className='header-text'>Instructions</h2>
          <div className='test-instruction-content'>
            <div dangerouslySetInnerHTML={{ __html: getTestByIdInsStudent?.instructions }} />
          </div>
        </div>
      </div>
      {
        isLoading || isLoadingTestPage ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }
    </>
  )
}

export default TestInstructionInsStud
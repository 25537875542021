import { Button,Image,Divider } from 'antd'
import Language from '../assets/images/languages.svg';
import '../styles/purchasedList.scss'

function PurchasedListComponent({testName,questions,marks,timeLimit,language,date,alreadyTaken}) {
  return (
    <div className='purchased-list-component'>
      <div className='purchased-list-content'>
      <p className="exam-title"><span className='full-test'>Full Syllabus</span>TNPSC GROUP 4</p>
        <p className='purchased-list-component-test'>{testName}</p>
        <div className={alreadyTaken==false ? 'purchased-list-component-questions' :'purchased-list-component-questions-solutions'}>
          <p style={alreadyTaken==true ? {display:'none'}:null}>{questions} questions</p>
          {alreadyTaken==true ? null:<Divider type='vertical' style={{background:'#7A8B94'}}></Divider>}
          <p className={alreadyTaken==false ? 'purchased-list-component-marks' :'purchased-list-component-marks-solutions'}>{marks} marks</p>
          <Divider style={{background:'#7A8B94'}} type='vertical'></Divider>
          <p className='purchased-list-component-time' style={alreadyTaken==true ? {display:'none'}:null}>{timeLimit} minutes</p>
          <p className='purchased-list-component-date-solutions' style={alreadyTaken==false ? {display:'none'}:null}>{date}</p>
        </div>  
        <div style={{display:'flex',alignItems:'center',marginBottom:12.5}}>
          <Image preview={false} src={Language}></Image>
          <p style={{paddingLeft:6,fontWeight: 500,fontSize:11,margin:0}}>{language}</p>
        </div>
      </div>
      <div className={alreadyTaken==true ? 'view-solutions':'take-a-test'}>
        {alreadyTaken==true ? <Button>View Solutions</Button>:<Button>Take A Test</Button>}
        </div>
    </div>

  )
}

export default PurchasedListComponent;
import { Button, Image } from 'antd'
import React from 'react'
import languages from '../assets/images/languages.svg'

const TestSeriesList = ({ data ,takeTestPress }) => {
    return (
        <div className= "test-series-list-container" >
            <div className='list-details'>
                {/* {
                    data?.pricingPlan == 1 ?
                    <span className="test-status-paid" >Paid</span>


                        : 
                        <span className='test-status-free' >Free</span>

                } */}

                <p className='test-name'>{data?.name}</p>

                <span className='test-total-languages-details'><span className='total-text'>{data?.numberOfQuestions} Qs.</span><span className='mark-text'>{data?.totalMark}  Marks </span><span className='languages-text'>{data?.timeLimit} mins </span></span>
                {/* <p className='languages'><Image preview={false} src={languages}></Image><span className='total-languages'> English, Tamil  tests </span></p> */}

            </div>
            <div className='list-button'>
                {
                    data?.pricingPlan == 1 ?
                    <Button type="primary" className='primary-red-submit-button'  >Buy Now</Button>

                        : 
                            <Button type="primary" className='primary-green-submit-button' onClick={()=> takeTestPress(data.id) } >Take Test</Button>


                }

            </div>
        </div>
    )
}

export default TestSeriesList


// : type == 3 ? "test-series-list-container border-empty" : "test-series-list-container"
            //     {/*        : type == 3 ?
            // <Button type="primary" className='primary-green-submit-button' >Analysis</Button> */}
        //                     {/* : type == 3 ?
        // <span className="test-status-result">completed</span> */}
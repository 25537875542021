import { Col, Image, Row, Tabs } from 'antd'
import React, { useEffect } from 'react'
import BreadCrumbDetails from '../Component/BreadCrumb'
import PurchasedSide from '../assets/images/purchasedSide.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMyPurchasedList } from '../Slices/PurchasedListSlice';
import AvailableTestComponent from '../Component/availableTestComponent';
import AttemptedTestComponent from '../Component/attemptedTest';
import { addBreadCrumbData, GetAllTest } from '../Slices/HomePageSlice';
import SingleTest from '../Component/SingleTest';
import PopularTestComponent from '../Component/popularTestComponent';


const PopularTest = () => {
  const languages = ["tamil", "english"]

    const { TabPane } = Tabs;
    const { myPurchasedList, isPurchasedTestLoading, purchasedCount } = useSelector(((state) => state.PurchasedList))
    const dispatch = useDispatch()
    const navigate = useNavigate()
  const { isLoading, getAllTestSeries, getAllExam, getAllTest, getUserDetails } = useSelector((state) => state.HomePage)

    const totalTest = () => {
     let total = 0
     for (let i = 0; i < purchasedCount?.length; i++) {
      total = total + +purchasedCount[i].numberOfTest
     }
     return total
    }
    useEffect(() => {
        dispatch(GetAllTest({})).unwrap().then((res) => {
          })
  dispatch(addBreadCrumbData({isPopular:true}))

       }, [])

       const onTabChange = (event) => {
       }
       const onClickTest = (data) => {
      
        navigate(`/home/testInstruction/${data}`)
       }
      

  return (
    <div className='popular-test-container'>
        <div className='popular-test-wrapper'>
            <div className='breadCrumb-container'>
                <BreadCrumbDetails></BreadCrumbDetails>
            </div>
            <div className='header-container'>
            <div className='header-card'>
            <p className='header-text'>Popular Test</p>
            <Image src={PurchasedSide} preview={false}></Image>
            </div>
            </div>
            <div className='body-container'>
                <div className='body-wrapper'>
                {/* <Tabs onChange={(e) => onTabChange(e)}>
         <TabPane tab={`All(${totalTest()})`} key={-1}>
          {myPurchasedList.map((item, index) => <AttemptedTestComponent item={item} language="Tamil,English" />

          )}
         </TabPane> */}
         {
          getAllTest?.length ?

          <Row>
          {
            getAllTest.map((item, index) => {
                return (
                  <Col xs={24}  lg={12} xl={12} xxl={12}>
                      <PopularTestComponent item={item} languages={languages.join(', ')} />
                    
                  </Col>

                )
            })
          }

          </Row>

        //    purchasedCount.map((item, index) => {
        //     return (
        //      <>

        //       <TabPane tab={item.testType + `(${item.numberOfTest})`} key={index + 2}>
        //        {
        //         getAllTest.map((listData, index) => {
        //          return (
        //           item.id == listData.typeOfTestId ?
        //            <AttemptedTestComponent item={item} language="Tamil,English" />
        //            : null
        //          )
        //         })
        //        }
        //       </TabPane>
        //      </>
        //     )


        //    })
           : null
         }

        {/* </Tabs> */}
                </div>
            </div>

        </div>

    </div>
  )
}

export default PopularTest
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const updateProfile = createAsyncThunk('Profile/updateProfile',async (payload)=>{
    try{
    
        let updateData = await axios.put('users/updateProfile',payload).then((res)=>{
            if(res.data.data.status){
                return res.data.data
              }
        })
        return updateData;
    }
    catch(error){
    }
})

export const updatePassword = createAsyncThunk('Profile/updatePassword',async (payload)=>{
    try{
        let updatePassData = await axios.post('users/changeNewPassword',payload).then((res)=>{
            if(res.data.success==true){
                return res.data
              }
        })
        return updatePassData;
    }
    catch(error){

    }
})

export const uploadProfile = createAsyncThunk('Profile/uploadProfile',async (data)=>{
    try{
         let uploadImg = await axios.put('users/uploadProfile',data).then((res)=>{
            if(res.data.data.status==true){
                return res.data.data
              }
         })
         return uploadImg
    }
    catch(error){
    }
})

export const getNotifications = createAsyncThunk('Users/getNotification',async ()=>{
    try{
        let notification = await axios.get('users/getNotification').then((res)=>{
           if(res){
               return res.data.data
             }
        })
        return notification
   }
   catch(error){
    console.log(error,"error");
   }
})

const profileSlice = createSlice({
    name:'profile',
    initialState:{
        isLoading: false,
        notifications:[]
    },
    extraReducers:{
        [updateProfile.pending]:(state,action)=>{
            state.isLoading = true

        },
        [updateProfile.fulfilled]:(state,action)=>{
            state.isLoading = false

        },
        [updateProfile.rejected]:(state,action)=>{
            state.isLoading = false

        },
        [uploadProfile.pending]:(state,action)=>{
            state.isLoading = true

        },
        [uploadProfile.fulfilled]:(state,action)=>{
            state.isLoading = false

        },
        [uploadProfile.rejected]:(state,action)=>{
            state.isLoading = false

        },
        [getNotifications.pending]:(state)=>{
            state.isLoading = true
        },
        [getNotifications.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.notifications = action.payload
        },
        [getNotifications.rejected]:(state)=>{
            state.isLoading = false
        },
    }
})
export default profileSlice.reducer
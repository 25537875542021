import { SearchOutlined } from '@ant-design/icons'
import { Button, Image, Input, Radio, Slider } from 'antd'
import Search from 'antd/lib/transfer/search'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import bannerLogo from '../assets/images/bannerLogo.svg'
import Loading from '../Component/Loading'
import TestSeries from '../Component/TestSeries'
import { GetAllExam, GetAllTestSeries } from '../Slices/HomePageSlice'
import { GetTestSeriesById } from '../Slices/TestSeriesSlice'

const TestBundles = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    let obj = {
      offset: 0
    }
    dispatch(GetAllTestSeries(obj))
    // dispatch(GetAllExam())

  }, [])
  const { isLoading, getAllTestSeries ,getAllExam } = useSelector((state) => state.HomePage)


  const [filterValue, setFilterValue] = useState("");
  const onChange = (value) => {
    setFilterValue(value.target.value)
  }
  const testSeriesPressed = (id) => {
    dispatch(GetTestSeriesById(id)).unwrap().then((res) => {
      navigate(`/home/testSeries/${id}`)
      // navigate(`testSeries/${index}`,{state:{name:"newExam",routeLink:`testSeries/${index}`}})

    })

  }

  const onExamChange = (value)=>{

  } 


  return (
    <>
      <div className='test-bundle-page-container'>
        <h2 className='main-header'>Test Series</h2>
        <div className='recent-test-series'>
          <div className='header-container'>
            <p className='header-text'>Your Recent Test Series</p>
            <p className='viewAll'>View All Attempted Tests</p>
          </div>
          <div className='recent-card-container'>
            <div className='card'>
              <p className='card-header'>Test Series</p>
              <p className='test-series-name'>TNPSC Group II & II A (Tamil Medium)</p>
              <Slider defaultValue={50} />
              <div className='button-container'>
                <span className='test-content'>10/56 Tests</span>
                <Button type='primary' className='test-series-button' >Go to Test Series</Button>
              </div>

            </div>

          </div>

        </div>
        <div className='test-series-banner'>
          <div className='banner-container'>
            <div className='image-details'>
              <Image preview={false} src={bannerLogo}></Image>
              <p className='exam-details'>Get Unlimited Access to Test Series for 580+ Exams and much more.</p>
            </div>
            <div className='subscription-details'>
              <p className='amount'>₹399/month</p>
              <Button type='primary' className='test-series-button' >BUY NOW</Button>
            </div>
          </div>
        </div>
        <div className='test-series-categories'>
          <div className='categories-header'>
            <p className='header-text'>Test Series by Categories</p>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search all test series"
              onChange={(event) => onChange(event)}
              value={filterValue}
            />
          </div>
          <div className='categories-content'>
            <div className='test-series-exam-category'>
              <Radio.Group onChange={(event)=>onExamChange(event.target.value)} defaultValue="">
                <Radio.Button value="">All</Radio.Button>
                {
                  getAllExam.length ? 
                    getAllExam.map((data,index)=>  
                <Radio.Button value={data.id}>{data.name}</Radio.Button>
                    
                    )
                  :null
                }
              </Radio.Group>
            </div>
            <div className='test-series-container'>
              {
                getAllTestSeries.length ?
                  getAllTestSeries.map((data, index) =>

                    <TestSeries data={data}    seriesPress={testSeriesPressed} />

                  )
                  : null
              }
            </div>

          </div>

        </div>

      </div>
      {
        isLoading ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }
    </>
  )
}

export default TestBundles
import { Breadcrumb, Button, Divider, Drawer, Image, message, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import subtract from '../assets/images/subtract.svg'
import languages from '../assets/images/languages.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { beginTest } from '../Slices/TestPageSlice'
import BreadCrumbDetails from '../Component/BreadCrumb'
import { GetAllDifficultyLevel, GetTestById } from '../Slices/TestSeriesSlice'
import { LoadingOutlined } from '@ant-design/icons'
import Loading from '../Component/Loading'
import { Option } from 'antd/lib/mentions'
import easy from '../assets/images/easy.svg'
import medium from '../assets/images/medium.svg'
import hard from '../assets/images/hard.svg';
import minsImage from '../assets/images/time.svg';
import marksImage from '../assets/images/marks.svg'
import { startTakeTest } from '../Slices/TestListSlice'
import random from '../assets/images/random.svg'

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
    />
);


const TestInstruction = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [testLevel, setTestLevel] = useState(0)
    const [currentTitle, setCurrentTitle] = useState("Choose your Difficulty level")
    const [visible, setVisible] = useState(false)

    const drawerCloser = () => {
        setVisible(false)
        setTestLevel(0)
    }

    useEffect(() => {
        dispatch(GetTestById(params?.id))
        dispatch(GetAllDifficultyLevel())

    }, [])
    const { isLoading, getTestById, difficultyLevel } = useSelector((state) => state.TestSeries)
    const { isLoadingTestPage } = useSelector((state) => state.TestMaster)

    const openCloser = () => {
        setVisible(true)
    }


    const startTest = () => {
        dispatch(startTakeTest())

        let currentTestId = params?.id
        let obj = {
            currentTestId: params?.id,
            difficultyLevelId: testLevel
        }
        dispatch(beginTest(obj)).unwrap().then((res) => {
            if (!res?.data?.status) {
                message.error(res?.message)
            }

            else if (res?.data?.status && res?.data?.questionData.length) {
                navigate("/testPage/" + currentTestId)
            }
            else if (!res?.data?.questionData.length) {
                message.error("There is no question in the test" + res?.data?.questionData.length)
            }
            else {
                message.error(" Something went wrong")

            }

        })

    }

    const handleChange = (value) => {
        setTestLevel(value)
    };
    var item = {
        typeOfTestId: 2
    }

    return (
        <>
            <div className='test-instruction-container'>
                <div className='breadcrumb-details'>
                    <BreadCrumbDetails></BreadCrumbDetails>
                </div>
                <div className='test-instruction-header'>
                    <div className='test-instruction-header-container'>
                        <div className='instruction-details-container'>
                            <div className='instruction-details'>
                                <p className="exam-title"><span className={getTestById.typeOfTestId == 1 ? 'full-test-title' : (getTestById.typeOfTestId == 2 ? 'subject-title' : 'chapter-title')}>{getTestById.testTypeName}</span>
                                    <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider>
                                    <span>{getTestById.examName} </span></p>
                                <p className='instruction-name'>{getTestById?.testName}</p>
                            </div>
                            <div className='instruction-total'>
                                <span className='total'><Image preview={false} src={subtract}></Image> <span className='total-question'>{getTestById?.numberOfQuestions} Questions </span> <Image preview={false} src={marksImage}></Image><span className='total-marks'>{getTestById?.totalMark} marks </span><Image preview={false} src={minsImage}></Image><span className='total-mins'>{getTestById?.timeLimit} mins </span> </span>
                            </div>
                        </div>
                        <div className='instruction-purchase'>
                            <Button type="primary" className='primary-submit-button' onClick={() => openCloser()}>Begin test</Button>
                        </div>

                    </div>
                </div>
                <div className='test-instruction-Details'>
                    <h2 className='header-text'>Instructions</h2>
                    <div className='test-instruction-content'>
                        <div dangerouslySetInnerHTML={{ __html: getTestById?.instructions }} />
                    </div>


                </div>
                <Drawer title={currentTitle} onClose={() => setVisible(false)} width="518px" className='instructionDrawer' closable={false} closeIcon={false} placement="right" visible={visible} footer={(
                    <div className='footer'>
                        <div className='footer-button'>
                            <Button className='cancel-Button primary-cancel-button' type='primary' onClick={drawerCloser} >Cancel</Button>
                            <Button className='primary-submit-button' type="primary" disabled={testLevel == ""} onClick={() => startTest()}  >Start </Button>
                        </div>
                    </div>
                )}>
                    <div className='difficult-level-container'>
                        <p className='detail-text'>Based on your selected difficulty level, we will select an appropriate question for you to Answer</p>
                        <div className='content'>
                            {
                                difficultyLevel.map((data, index) => {
                                    if (data.id == 4) {
                                        return (
                                            <div className={testLevel == data.id ? 'active-list' : 'list'} key={data.id} onClick={() => handleChange(data.id)}>
                                                <p className='difficult-text'>{data.name}</p>
                                                <Image preview={false} src={random} />

                                            </div>

                                        )
                                    }


                                })
                            }

                        </div>
                        <Divider type='horizontal' plain> <span className='dividerOr'>OR</span> </Divider>

                        <div className='content'>
                            {
                                difficultyLevel.map((data, index) => {

                                    if (data.id != 4) {
                                        return (
                                            <div className={testLevel == data.id ? 'active-list' : 'list'} key={data.id} onClick={() => handleChange(data.id)}>
                                                <p className='difficult-text'>{data.name}</p>
                                                <Image preview={false} src={data.id == 1 ? easy : data.id == 2 ? medium : hard} />

                                            </div>

                                        )
                                    }

                                })
                            }

                        </div>
                    </div>

                </Drawer>
            </div>

            {
                isLoading || isLoadingTestPage ? <div className='loading-container'>

                    <Loading></Loading>

                </div> : null
            }

        </>
    )
}

export default TestInstruction